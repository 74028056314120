.icreon-sidebar{
    .sidebar {
        height: calc(100% - 60px);
        width: 0;
        position: fixed;
        z-index: 10000;
        top: 70px;
        left: 0;
        background: #000837;
        overflow-x: hidden; 
        transition: 0.8s;
        min-width: 60px;
        padding: 10px 5px 20px 12px;
        &.green-bg {
            background: #008001;
        }
        hr {
            background: #D2DAE3;            
            margin: 10px -5px;
            opacity: 0.6;
            border: none;
            height: 1px;
        }
        .mobileSidebarLinksGroup{
            padding-right: 15px;
            padding-left: 5px;
        }
    }   
    .sidebar {
        .leftMenuLink{  
            display: flex;
            align-items: center;
            padding: 6px 0px 6px 0px;
            cursor: pointer; 
            span.navIcon{
            height: 34px; 
            width: 34px; 
            min-width: 34px;
            min-height: 34px;         
            text-decoration: none;
            display: block;
            transition: 0.6s;
            text-align: center;
            background-repeat: no-repeat !important;
            background-size: 20px;
            background-position: center;
            cursor: pointer;
            margin-left: -5px;                   
          }
          .menu1{background-image: url('../../../Assets/Images/menu-icon1.svg');}
          .menu2{background-image: url('../../../Assets/Images/menu-icon2.svg'); background-size: 23px !important;}
          .menu3{background-image: url('../../../Assets/Images/menu-icon3.svg'); background-size: 19px !important;}
          .menu4{background-image: url('../../../Assets/Images/menu-icon4.svg');}
          .menu5{background-image: url('../../../Assets/Images/menu-icon5.svg'); background-size: 12px !important;}
          .menu6{background-image: url('../../../Assets/Images/menu-icon6.svg'); background-size: 22px !important;}
          .menu7{background-image: url('../../../Assets/Images/menu-icon7.svg'); background-size: 18px !important;}
          .menu8{background-image: url('../../../Assets/Images/menu-icon8.svg');}
          .menu9{background-image: url('../../../Assets/Images/menu-icon9.svg');}
          .menu10{background-image: url('../../../Assets/Images/menu-icon10.svg'); background-size: 18px !important;}
          .menu11{background-image: url('../../../Assets/Images/menu-icon11.svg'); background-size: 18px !important;}
          .menu12{background-image: url('../../../Assets/Images/menu-icon12.svg'); background-size: 18px !important;}
        
            &:hover, &:focus{
                span.navIcon{
                    background-color: #E3ECFF;
                    border-radius: 4px;
                }
                .menu1{background-image: url('../../../Assets/Images/menu-icon1_hv.svg');}
                .menu2{background-image: url('../../../Assets/Images/menu-icon2_hv.svg');}
                .menu3{background-image: url('../../../Assets/Images/menu-icon3_hv.svg');}
                .menu4{background-image: url('../../../Assets/Images/menu-icon4_hv.svg');}
                .menu5{background-image: url('../../../Assets/Images/menu-icon5_hv.svg');}
                .menu6{background-image: url('../../../Assets/Images/menu-icon6_hv.svg');}
                .menu7{background-image: url('../../../Assets/Images/menu-icon7_hv.svg');}
                .menu8{background-image: url('../../../Assets/Images/menu-icon8_hv.svg');}
                .menu9{background-image: url('../../../Assets/Images/menu-icon9_hv.svg');}
                .menu10{background-image: url('../../../Assets/Images/menu-icon10_hv.svg');}
                .menu11{background-image: url('../../../Assets/Images/menu-icon11_hv.svg');}
                .menu12{background-image: url('../../../Assets/Images/menu-icon12_hv.svg');}
                label{
                    opacity: .6;
                }
            } 
            .subDropdownMenu{
                width: 100%;
                background: transparent;
                border: none;
                .k-link{
                    width: 100%;
                    background: transparent;
                    padding: 0;
                    box-shadow: none;
                    cursor: pointer;
                    &:focus, &:hover{
                        box-shadow: none;
                        outline: none;
                        border: none;
                    }
                    .k-panelbar-item-text{
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }
                    .k-icon{color: #fff; font-size: 18px; visibility: hidden;}
                }
                .k-animation-container{
                    .k-content{
                        border: none;
                        background: transparent;
                        .leftSubMenu{
                            padding-left: 44px;                            
                            ul{
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                //border-bottom: 1px solid #151b3c;
                            }
                            li{
                                color: #fff;
                                //font-weight: 600;
                                font-weight: 500;
                                font-size: 14px;
                                opacity: 0.6;
                                letter-spacing: .3px;
                                line-height: 15px;
                                padding: 8px 0;
                                white-space: nowrap;
                                &:hover{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }                
            }
            .createBtn{
                background-repeat: no-repeat !important;
                background-size: 14px;
                background-position: center;
                background-image: url('../../../Assets/Images/pluse-white.svg');    
                background-color: rgba(193, 204, 228, 0.2);
                border-radius: 4.53846px;   
                width: 32px;
                height: 32px;
                border: none;
                padding: 0;   
                position: absolute;
                right: 0;   
                top: 7px; 
                z-index: 99;
                // visibility: hidden;
                &:focus{
                    box-shadow: none;
                }
            }
            position: relative;
            min-width: 200px;
        }
        label{
            margin: 0;
            padding-left: 15px;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            cursor: pointer;
            transition: 0.6s;
            visibility: hidden;
            letter-spacing: .3px;
            white-space: nowrap;
        }
        .mobileSidebarHeader{
            display: none;
        }
    }
    @media screen and (min-width: 768px) {    
        .sidebar:hover {
            min-width: 270px;
            label, .k-link .k-icon, .createBtn{visibility: visible !important;}
        }   
    } 
    .sidebar .closebtn{
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none;
    }
    #main {
        padding: 20px 15px 20px 80px;
    }
    
}


/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
    .icreon-sidebar{
     #main {padding: 0;}
    .sidebar {
        top: 0px;
        z-index: 1000;
        height: 100%;
        min-width: 0px;
        margin-left: -25px;
        .mobileSidebarLinksGroup {
            //padding-right: 30px;
            padding-right: 10px;
            padding-left: 0;
          }
        .mobileSidebarHeader{
            display: flex;
            background-color: #fff;
            width: calc(100% + 24px);
            min-width: 300px;
            margin: -10px -12px 12px -12px;
            justify-content: space-between;
            align-items: center;
            padding: 12px 15px 12px 20px;
            .sidebarLogoSec{
                width: 150px;
                img{
                    width: 100%;
                }
            }
            .headerCloseBtn {
                background: url("../../../Assets/Images/close-blue.svg");
                background-repeat: no-repeat;
                background-position: center;               
                border: none;
                background-size: 16px;
                width: 32px;
                height: 32px;
                display: inline-block;
                padding: 0;
                &:focus, &:hover, &:active{
                  box-shadow: none;
                  outline: none;
                }
              } 
        }
        .leftMenuLink{  
            display: flex;
            align-items: center;
            padding: 6px 0px 6px 0px;
            cursor: pointer; 
            span.navIcon{
                margin-left: 0;                     
          }
            &:hover, &:focus{
                label{
                    opacity: .6;
                }
            } 
            .subDropdownMenu{
                .k-link{
                    .k-icon{visibility: visible;}
                }                        
            }
            .subDropdownMenu .k-animation-container .k-content .leftSubMenu {
                padding-left: 48px;
              } 
        }
        label{
            visibility: visible;
        }
    } 
}

}