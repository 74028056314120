@import "../../Assets/Styles/Variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000C52;
    box-shadow: 0px 4px 30px #DFE1EB;
    border-radius: 6px;
    padding: 32px 40px;
    min-height: 130px;

    @media screen and (max-width:767px) {
        flex-direction: column;
        align-self: flex-start;
        align-items: flex-start;
        gap: 30px;
    }

    .leftWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        @media screen and (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .logoScetion {
            img {
                height: 50px;
                @media screen and (max-width:767px) {
                    height: 35px;
                }
            }
        }

        .nameSection {
            flex: 1;

            .nameTitle {
                margin-bottom: 8px;
                font-size: 16px;
                line-height: 15px;
                font-weight: 400;
                color: #FFFFFF;
            }

            .name {
                font-size: 44px;
                line-height: 40px;
                font-weight: 300;
                color: #9BCBEB;
                @media screen and (max-width:767px) {
                    font-size: 30px;
                    line-height: 32px;
                }
            }
        }
    }

    .rightWrapper {
        text-align: right;
        flex: 1;

        @media screen and (max-width:767px) {
            text-align: left;
        }

        .infos {
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            //  text-align: right;
            color: #FFFFFF;
        }
    }

    &.vertical {
        flex-direction: column;
        min-height: 250px;

        .topWrapper {}

        .bottomWrapper {
            h4 {
                font-size: 44px;
                line-height: 40px;
                text-align: center;
                font-weight: 300;
                color: #FFFFFF;
            }
        }

        .divideBorder {
            display: block;
            width: 100%;
            height: 1px;
            background: rgba(255, 255, 255, 0.4);
        }
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    min-height: 172px;
    background: #000C52;
    mix-blend-mode: normal;
    border-radius: 10px;
    margin-bottom: 50px;

    .backButton {
        border-color: #FFFFFF;
        height: 52px;

        .k-button-text {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: transparent;
            font-size: 20px;
            line-height: 18px;
            font-weight: 600;
            color: #FFFFFF;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .nextButton {
        border-color: #FFFFFF;
        height: 52px;

        .k-button-text {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 20px;
            line-height: 18px;
            font-weight: 600;
            color: #000C52;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    &.type2 {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        gap: 20px;

        .footerHeadline {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 7px;

            h4 {
                font-weight: 400;
                font-size: 30px;
                line-height: 28px;
                color: #FFFFFF;
            }
        }

        .footerText {
            font-size: 18px;
            line-height: 22px;
            font-weight: 300;
            color: #FFFFFF;
            margin-bottom: 0;
        }
    }
}

.bodyWrapper {
    margin: 40px 0;
    .invoiceHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        .invoiceHeading {
            .invoiceTitle {
                font-style: normal;
                font-weight: 400;
                font-size: 34px;
                line-height: 31px;
                color: #00041B;
            }

            .invoiceSubTitle {
                font-weight: 500;
                font-size: 18px;
                line-height: 17px;
                color: #00041B;
                margin-top: 10px;
            }
        }

        .pdfDownloadBtn {
            background-image: url('../../Assets/Images/download-icon-white.svg') !important;
            background-repeat: no-repeat;
            background-position: center left 20px;
            background-size: 26px;
            font-size: 20px;
            line-height: 18px;
            font-weight: 600;
            padding: 13px 20px 10px 54px;
            height: 52px;
        }
    }

    .progressBar {
        max-width: 570px;
        margin: 40px auto;

        @media screen and (max-width: 991px) {
            padding-top: 20px;
            max-width: 100%;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                color: #C7CDD5;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                &:first-child {
                    &::before {
                        left: 50%;
                    }
                }

                &:last-child,
                &:nth-child(2),
                &:nth-child(3) {
                    &::before {
                        right: 50%;
                    }
                }

                &::before {
                    background: #D1D5DB;
                    content: '';
                    height: 2px;
                    position: absolute;
                    top: 15px;
                    width: 100%;

                    @media screen and (max-width: 991px) {
                        top: 13px;
                    }
                }

                span {
                    border: 2px solid #D1D5DB;
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    background-color: #F3F3F3;
                    position: relative;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media screen and (max-width: 991px) {
                        width: 26px;
                        height: 26px;
                    }
                }

                label {
                    padding-top: 10px;
                }


                &.stepDone {
                    color: $primary-dark-blue ;

                    &::before {
                        background-color: $primary-dark-blue;
                    }

                    span {
                        border: 2px solid $primary-dark-blue;
                        background-color: $primary-dark-blue;
                        background-image: url('../../Assets/Images/check-white.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 14px;

                        @media screen and (max-width: 991px) {
                            background-size: 12px;
                        }
                    }
                }

                &.stepActive {
                    color: $primary-dark-blue ;

                    &::before {
                        background-color: $primary-dark-blue;
                    }

                    span {
                        border: 2px solid $primary-dark-blue;
                        background-color: $primary-dark-blue;
                        background-image: url('../../Assets/Images/flight-white.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 18px;
                        transform: rotate(180deg);

                        @media screen and (max-width: 991px) {
                            background-size: 14px;
                        }
                    }
                }
            }
        }
    }
}