.icreon-tab {
  .tabMain {
    border-bottom: 0.5px solid #E6E9F7;
    box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 10px;
    list-style: none;

    .tab-box {
      margin-bottom: -1px;

      .tab-title {
        display: block;
        color: #82869B;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        background: transparent;
        padding: 12px 16px;
        border: none;
        margin-right: 10px;
        cursor: pointer;
        position: relative;

        &:after {
          content: "";
          background: #00106E;
          height: 2px;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          -webkit-transition: all 250ms ease 0s;
          transition: all 250ms ease 0s;
          -webkit-transform: scale(0);
          transform: scale(0)
        }
      }
    }

    .tab-box.active {
      .tab-title.active {
        color: #00106E;
        //  border-bottom: 2px solid #00106E;
        font-weight: 700;

        &:after {
          content: "";
          -webkit-transform: scale(1);
          transform: scale(1)
        }
      }
    }
  }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
  .icreon-tab {
    .tabMain {
      -ms-flex-wrap: inherit;
      flex-wrap: inherit;
      margin: 0 -12px;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .tab-box {
        white-space: nowrap;
      }

      .tab-box.active .tab-title.active {
        &:after {
          height: 3px;
        }
      }
    }
  }

}