// variables
@import "../../Assets/Styles/Variables.scss";
//Variables end

// colors
@import "../../sass/_colors.scss";
//colrs end

body.externalScreens {
    background: #fff;
    padding: 0;
}

.jetcardAgreementWrapper {
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 30px;
    min-height: calc(100vh - 70px);
}

.validation--error {
    margin-top: 12px;
    color: $primary;
}

.agreementHeaderSec {
    background: url('../../Assets/Images/jets-banner-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 250px;
    border-radius: 10px;
    padding: 40px;

    .topLogoSec {
        border-bottom: 2px solid rgba(255, 255, 255, 0.4);

        .jetsLogo {
            max-width: 265px;
        }

        .headerRightData {
            color: #fff;
            text-align: right;
            position: relative;

            label {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
            }

            h1 {
                font-weight: 300;
                font-size: 44px;
                line-height: 40px;
                margin: 8px 0 25px 0;
            }

            .profileSortInfoToggleBtn {
                background: url('../../Assets/Images/down-angle-black.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 10px;
                background-color: #fff;
                border: none;
                width: 18px;
                height: 18px;
                display: inline-block;
                padding: 0;
                position: absolute;
                right: 0px;
                bottom: -1px;
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
                border-radius: 3px;

                &:focus {
                    box-shadow: none;
                }
            }

            .k-selected.profileSortInfoToggleBtn {
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
                border: none;
            }
        }
    }

    .headerContactDetails {
        padding-top: 35px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            li {
                width: fit-content;

                label {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 15px;
                    color: rgba(242, 244, 246, 0.8);
                    margin-bottom: 15px;
                }

                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 18px;
                    color: #FFFFFF;
                    margin: 0;
                }
            }
        }
    }

    .headerThanks {
        font-weight: 300;
        font-size: 44px;
        line-height: 40px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 30px;
    }
}

.membershipSec {
    .membershipTitleSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 24px 0;

        h1 {
            font-weight: 400;
            font-size: 34px;
            color: $dark-grey;
            margin: 0;
        }

        .pdfDownloadBtn {
            background-image: url('../../Assets/Images/download-icon-white.svg') !important;
            background-repeat: no-repeat;
            background-position: center left 20px;
            background-size: 26px;
            font-size: 20px;
            line-height: 18px;
            font-weight: 600;
            padding: 13px 20px 10px 54px;
            height: 52px;
        }
    }

    .sectionCard {
        background: #FFFFFF;
        border: 1px solid #E6E9F7;
        border-radius: 10px;
        padding: 50px;
        margin-bottom: 40px;

        .secTitle {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $dark-grey;
            margin-bottom: 20px;
        }

        .programDetails {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-weight: 400;
                font-size: 18px;
                line-height: 17px;
                color: $dark-grey;
                display: flex;
                align-items: flex-start;
                padding-bottom: 10px;
                padding-top: 10px;

                p {
                    width: 50%;
                    padding-right: 15px;
                    margin-bottom: 0;

                    .rate-row {
                        margin-bottom: 5px;
                    }
                }
            }

            li.text-primary {
                p {
                    color: $primary-dark-blue;
                    font-weight: 700;
                    padding-top: 5px;
                }
            }
        }

        .agreementBtn {
            padding-top: 30px;

            .k-button {
                font-weight: 600;
                font-size: 20px;
                line-height: 18px;
                height: 52px;
                padding: 10px 25px;
            }
        }
    }

    .membershipCard {
        text-align: center;
        background: rgba(238, 240, 248, 0.85);

        h4 {
            font-weight: 400;
            font-size: 26px;
            line-height: 24px;
            color: $dark-grey;
            margin-bottom: 40px;
        }

        .membershipOuter {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;

            .membershipSec {
                background: #FFFFFF;
                box-shadow: 0px 4px 30px #DFE1EB;
                border-radius: 6px;
                padding: 36px 24px;
                text-align: center;
                display: flex;
                flex: 1;
                align-items: center;
                // justify-content: center;
                gap: 16px;
                cursor: pointer;
                transition: .3s ease-in-out;

                .micons {
                    background: #000C52;
                    height: 40px;
                    width: 40px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        min-width: 24px;
                    }
                }

                p {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 18px;
                    color: $primary-dark-blue;
                    margin: 0;
                }

                &:hover {
                    box-shadow: 0px 4px 30px hsl(225, 16%, 80%);

                    p {
                        color: $link-hover;
                    }

                    .micons {
                        background: $primary-dark-blue;
                    }
                }
            }
        }
    }
}

.bodyText {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: $dark-grey;
}

.programCheckbox {
    @extend .bodyText;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;

    .k-checkbox {
        margin-top: 1px;
        width: 20px;
        height: 20px;
        margin-right: 7px;
        background-size: 15px;
    }
}

.agreementText {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: $dark-grey;
    text-align: center;
    padding: 0 40px 40px 40px;
    margin: 0;
}

.exTemplateFooter {
    background: #f4f7f8;
    padding: 20px 0;

    .footerContainer {
        max-width: 1250px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:767px) {
            max-width: 100%;
        }

        img {
            max-width: 146px;
        }

        p {
            color: $dark-grey;
            font-weight: 300;
            font-size: 16px;
            line-height: 15px;
            margin-bottom: 0;

            @media screen and (max-width:767px) {
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
}

.emergency-details {
    label {
        display: block;
        font-size: 16px;
        font-weight: 400;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #00106E;
    }
}

.acceptanceData {
    padding-top: 40px;

    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: $primary-dark-blue;
        display: block;
        margin-bottom: 10px;
    }

    p {
        @extend .bodyText;
    }

    p.signature-agreement {
        font-family: $AlluraFont;
        font-size: 23px;
    }
}

.successFormGroup {
    text-align: center;
    padding: 60px 0;
    max-width: 850px;
    margin: 0 auto;

    .success {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.2em;
        color: $dark-grey;
    }

    .circleIcon {
        padding: 10px 0;

        img {
            height: 86px;
            width: 86px;
        }
    }

    h4,
    h6 {
        font-weight: 300;
        line-height: 36px;
        color: $dark-grey;
        margin-bottom: 0;
    }

    h4 {
        font-size: 36px;
        padding: 20px 0;
    }

    h6 {
        font-size: 28px;
        padding-top: 20px;
    }

    .k-button-solid {
        width: 136px;
        height: 42px;
        margin-top: 30px;
    }
}

.customSmallModel {
    .k-dialog {
        width: 100%;
        max-width: 900px;
        border-radius: 0;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px -10px 30px rgba(145, 148, 165, 0.25);
        border-radius: 4px;

        .k-dialog-titlebar {
            border: none;
            padding: 25px 25px 0 30px;

            .k-dialog-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                letter-spacing: 0.2em;
                color: $text-dark-black-color;
            }
        }

        .k-dialog-titlebar-actions {
            margin: 0;

            .k-button {
                opacity: 1;
                padding: 0;

                .k-button-icon {
                    background: url('../../Assets/Images/close-black.svg') !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 16px !important;
                    opacity: 1;

                    &::before {
                        display: none;
                    }
                }

                &:hover {
                    background-color: transparent;

                    &::before,
                    &::after {
                        display: none !important;
                    }
                }
            }
        }

        .k-svg-icon.k-svg-i-x svg {
            visibility: hidden;
        }

        .k-window-content {
            padding: 20px 30px 30px 30px;
            overflow: hidden;
        }

        .modelFooterAction {
            text-align: right;
            display: flex;
            justify-content: flex-end;
            padding-top: 30px;
            gap: 20px;
            margin-right: 0px;

            .k-button-outline {
                height: 52.000003814697266px;
                min-width: 99px;
                border-radius: 6.182927131652832px;
                padding: 12px 19px 12px 19px;

                .k-button-text {
                    font-weight: 600 !important;
                    // font-size: 20px !important;
                    line-height: 18px !important;
                    margin-top: 4px;
                }
            }

            .k-button-solid {
                min-width: 140px;
                background-image: url('../../Assets/Images/download-icon-white.svg') !important;
                background-repeat: no-repeat;
                background-position: center left 15px;
                background-size: 20px;
                padding-left: 40px;
            }
        }
    }
}

.termsPopupInnerData {
    background: #FFFFFF;
    border: 1.355px solid #E6E9F7;
    border-radius: 8.13px;
    padding: 24px 5px 12px 24px;
    font-size: 16px;
    color: $dark-grey;
    line-height: 140%;

    p {
        margin-bottom: 25px;
    }

    .termsScroller {
        height: 300px !important;

        .termsRightSpace {
            padding-right: 25px;

            body {
                padding-top: 0;
                background-color: transparent;
            }

            .k-pdf-export {

                * {
                    font-family: Arial, Verdana, sans-serif;
                }

                .termsPdf td {
                    vertical-align: baseline;
                }

                strong {
                    margin-right: 2px;
                }
            }
        }
    }
}

.paymentDetailsWrapper {
    .paymentsInfo {
        background: rgba(238, 240, 248, 0.85);
        border-radius: 6px;
        padding: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: $dark-grey;
        min-height: 365px;

        h6 {
            font-weight: 500;
            font-size: 22px;
            line-height: 20px;
            margin-bottom: 25px;
        }

        p {
            line-height: 22px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 6px 0;
                display: flex;

                label {
                    font-weight: 500;
                    width: 50%;
                    padding-right: 10px;
                }

                p {
                    width: 50%;
                    margin: 0;
                    padding-left: 10px;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.peakTravelGroupSec {
    padding-bottom: 40px;

    &:last-child {
        padding-bottom: 0;
    }

    h4 {
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        color: $dark-grey;
        margin-bottom: 30px;

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            color: $label-color;
            padding-left: 5px;
        }
    }

    .travelHead,
    .holidayName {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            width: 33.3333%;
            margin-bottom: 20px;
        }
    }

    .travelHead {
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: #919AA9;

        p {
            margin-bottom: 10px;
        }
    }

    .holidayName {
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        color: $dark-grey;
    }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
    .jetcardAgreementWrapper {
        padding-top: 12px;
        min-height: calc(100vh - 60px);
    }

    .agreementHeaderSec {
        padding: 15px;
        background-color: #3c4058;
        min-height: auto;
        background-image: none;

        .topLogoSec {
            border: none;

            .row {
                align-items: center;
            }

            .jetsLogo {
                max-width: 265px;
                width: 100%;
            }

            .headerRightData {
                padding-right: 25px;

                label {
                    font-size: 13px;

                    span {
                        display: none;
                    }
                }

                h1 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 18px;
                    margin: 1px 0 5px 0;
                }
            }
        }

        .headerContactDetails {
            padding-top: 15px;
            border-top: 1px solid rgba(255, 255, 255, 0.4);
            margin-top: 10px;

            ul {
                flex-direction: column;

                li {
                    width: 100%;
                    padding-bottom: 6px;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    label {
                        font-size: 13px;
                        line-height: 15px;
                        margin-bottom: 5px;
                        letter-spacing: .3px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 18px;
                        letter-spacing: .3px;
                    }
                }
            }
        }
    }

    .thanksScreenHeader {
        padding-left: 30px;
        padding-right: 30px;
        background: url('../../Assets/Images/jets-banner-img.png');
        background-size: cover;

        .topLogoSec {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);

            .col-12.pb-4.mb-3 {
                margin-bottom: 0 !important;
                padding-bottom: 22px !important;
                padding-top: 5px;

                .jetsLogo {
                    max-width: 180px;
                }
            }
        }

        .headerThanks {
            font-size: 30px;
            line-height: 30px;
            padding-top: 20px;
        }
    }

    .membershipSec {
        .membershipTitleSec {
            padding: 20px 0 18px 0;

            h1 {
                font-weight: 600;
                font-size: 20px;

                span {
                    display: none;
                }
            }

            .pdfDownloadBtn {
                background-position: center left 10px;
                background-size: 14px;
                font-size: 14px;
                line-height: 14px;
                font-weight: 600;
                padding: 10px 10px 10px 30px;
                height: auto;
            }
        }

        .sectionCard {
            padding: 16px;
            margin-bottom: 15px;

            .secTitle {
                margin-bottom: 15px;
            }

            .programDetails {
                li {
                    font-size: 15px;
                    padding-bottom: 6px;
                    padding-top: 6px;
                }
            }

            .col-md-4.text-end {
                text-align: center !important;
                padding-top: 20px;

                .img-fluid {
                    max-width: 250px;
                }
            }

            .bodyText.pt-4 {
                padding-top: 1rem !important;
            }

            .agreementBtn {
                padding-top: 20px;
                text-align: center;

                .k-button {
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 600;
                    padding: 10px 20px;
                    height: auto;
                    width: 100%;
                }
            }

            .acceptanceData {
                .col-md-3.col-sm-2 {
                    &:last-child {
                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                padding-top: 15px;

                label {
                    font-size: 14px;
                    margin-bottom: 0px;
                }

                p,
                .img-fluid {
                    margin-bottom: 20px;
                }
            }
        }

        .membershipCard {
            padding: 15px;

            h4 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 20px;
            }

            .membershipOuter {
                gap: 15px;

                .membershipSec {
                    padding: 10px;
                    min-height: 130px;

                    p {
                        font-size: 14px;
                        margin: 10px 0 0 0;
                    }

                    .micons {
                        height: 50px;
                        width: 50px;
                        // img {
                        //     min-width: 15px;
                        //   }
                    }
                }
            }
        }

        .bodyText,
        .acceptanceData p,
        .programCheckbox {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .programCheckbox {
        padding-top: 0px;

        .k-checkbox {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            background-size: 12px;
        }
    }

    .agreementText {
        font-size: 15px;
        line-height: 20px;
        padding: 0 0px 20px 0px;
    }

    .exTemplateFooter {
        padding: 12px 15px;

        .footerContainer {
            @media screen and (max-width:767px) {
                max-width: 100%;
            }

            img {
                max-width: 120px;
            }

            p {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                padding-left: 20px;
                text-align: right;

                @media screen and (max-width:767px) {
                    font-size: 11px;
                    line-height: 13px;
                }
            }
        }
    }

    .customSmallModel {
        .k-dialog {
            margin: 0 auto;
            max-width: calc(100% - 25px);

            .k-window-content {
                padding: 15px;

                .termsPopupInnerData {
                    padding: 15px 5px 12px 15px;
                    font-size: 14px;

                    p {
                        margin-bottom: 15px;
                    }

                    .termsScroller {
                        height: 400px !important;

                        .termsRightSpace {
                            padding-right: 10px;

                            body {
                                padding-top: 0;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

            .k-dialog-titlebar {
                padding: 15px 15px 0 15px;
            }

            .modelFooterAction {
                padding-top: 20px;
                justify-content: center;

                .k-button-flat {
                    display: none;
                }

                .k-button-solid {
                    min-width: inherit;
                }
            }

            .profilePicUploadOuter .uploadImageHints {
                top: 20px;

                h4 {
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom: 8px !important;
                }

                p {
                    font-size: 12px;
                }
            }
        }
    }

    .paymentDetailsWrapper {
        .col-sm-6 {
            &:last-child {
                .paymentsInfo {
                    margin-bottom: 0px;
                }
            }
        }

        .paymentsInfo {
            padding: 20px;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 15px;
            min-height: inherit;

            h6 {
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 18px;
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }

            ul li {
                padding: 5px 0;

                label {
                    width: 40%;
                    padding-right: 0px;
                }

                p {
                    line-height: 18px;
                    width: 60%;
                }
            }
        }
    }

    .peakTravelGroupSec {
        padding-bottom: 30px;

        h4 {
            font-size: 18px;
            margin-bottom: 20px;
        }

        .travelHead {
            display: none;
        }

        .holidayName {
            font-size: 14px;
            line-height: 17px;
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid #f0f2fb;
            margin-bottom: 15px;

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }

            p {
                position: relative;
                margin-bottom: 15px !important;

                &::before {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #919AA9;
                    display: block;
                    padding-bottom: 3px;
                }
            }

            p:nth-child(1) {
                width: 100%;

                &::before {
                    content: 'Peak Days'
                }
            }

            p:nth-child(2) {
                width: 50%;
                display: inline-block;

                &::before {
                    content: 'Starts'
                }
            }

            p:nth-child(3) {
                width: 50%;
                display: inline-block;

                &::before {
                    content: 'Ends'
                }
            }
        }
    }

    .successFormGroup {
        padding: 30px 0;

        .circleIcon {
            max-width: 60px;
        }

        h4 {
            font-size: 22px;
            padding: 10px 0;
            line-height: 28px;
        }

        h6 {
            font-size: 18px;
            padding: 10px 0;
            line-height: 20px;
        }
    }
}

.hidden-br {
    display: none;
}

@media screen and (min-width: 992px) {
    .agreementHeaderSec .topLogoSec .headerRightData .profileSortInfoToggleBtn {
        display: none;
    }
}