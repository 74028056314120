@import "../../../Assets/Styles/Variables.scss";

.k-grid.tripListTable {
    .k-grid-header {
        table {
            tr {

                th:nth-child(1),
                th:last-child {
                    .k-link:after {
                        display: none !important;
                    }
                }
            }
        }
    }

    table {
        colgroup {
            col:nth-child(1) {
                width: 30px;
            }

            col:nth-child(2) {
                width: 36px;
            }

            col:nth-child(3) {
                width: 7%;
            }

            col:nth-child(4) {
                width: 12%;
            }

            col:nth-child(5) {
                width: 10%;
            }

            col:nth-child(6) {
                width: 16%;
            }

            col:nth-child(7) {
                width: 9%;
            }

            col:nth-child(8) {
                width: 9%;
            }

            col:nth-child(9) {
                width: 10%;
            }

            col:nth-child(10) {
                width: 7%;
            }

            col:last-child {
                width: 85px;
            }
        }
    }

    .k-grid-header .k-header:first-child,
    .k-master-row td:first-child {
        padding-right: 0;
    }

    .k-grid-header .k-header:last-child {
        .k-cell-inner .k-link {
            justify-content: center;

            .k-column-title {
                padding-right: 0;
            }
        }
    }

    .tableDetailsData {
        padding: 10px 20px 10px 8px;

        table {
            min-width: inherit;
        }

        tr {
            td {
                padding: 0 8px;

                &:nth-child(1) {
                    width: 24%;
                }

                // &:nth-child(2) {width: 8%;}
                // &:nth-child(3) {width: 12%;}
                // &:nth-child(4) {width: 11%;}
                // &:nth-child(5) {width: 17%;}
                // &:nth-child(6) {width: 10%;}
                // &:nth-child(7) {width: 9%;}
            }
        }

        tr+tr td {
            padding-top: 15px;
        }

        .tblColData-hidden-md {
            margin: 0 0px 15px 0px;
            border-bottom: 1px solid #E6E9F6;
            padding: 0 8px;
        }

        .tripDetailsInfo {
            list-style: none;
            padding: 0;
            margin: 0 -8px 20px -8px;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                display: inline-block;
                width: 12.6666%;
                padding: 0 8px;

                &:nth-child(1) {
                    width: 24%;
                }
            }
        }
    }
}

.cardTypeTabs .k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items {
    .k-item.k-first .k-link {
        background-image: url('../../../Assets/Images/charter-blue-icon-nr.svg');
    }

    .k-active.k-first .k-link {
        background-image: url('../../../Assets/Images/charter-blue-icon.svg');
    }

    .k-item.k-last .k-link {
        background-size: 20px;
        background-image: url('../../../Assets/Images/jet-card-icon-nr.svg');
    }

    .k-active.k-last .k-link {
        background-image: url('../../../Assets/Images/jet-card-icon-blue.svg');
    }
}

.createTripTabs {
    max-width: 100%;

    ul li {
        &:before {
            right: 50%;
        }
    }
}

.tripInquiryExpandedSec {
    .k-panelbar {
        border: none;
        background: transparent;

        .k-panelbar-header {
            margin-top: 20px;
            border: 1px solid #E6E9F7;
            background: #FFFFFF;
            border-radius: 6px;
            padding: 10px 15px 20px 20px;

            .k-link {
                padding: 0;
                color: $dark-grey;
                font-weight: 400;
                font-size: 12px;
                line-height: 11px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                cursor: pointer;
                padding: 10px 0;
                // margin-bottom: 8px;

                &:focus,
                &:hover {
                    box-shadow: none;
                    background-color: transparent;
                }

                .k-icon {
                    color: $label-color;
                }
            }

            .k-focus {
                box-shadow: none;
            }

            .k-selected {
                background-color: #fff !important;
                color: $dark-grey;
                box-shadow: none !important;
            }
        }
    }

    .addRoleBtn {
        border-top: 1px solid #E6E9F7;
        padding-top: 10px;

        .weatherTxt {
            font-weight: 300;
            font-size: 12px;
            line-height: 150%;
            color: $lighter-grey;

            a {
                color: $primary-light-blue;
                font-weight: 500;
            }
        }
    }
}

.specialRequestCheck {
    display: flex;
    gap: 15px;
    padding-top: 20px;
    padding-bottom: 5px;

    .greyTitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: $lighter-grey;
    }

    span {
        margin-right: 10px;
    }

    .k-checkbox-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: $dark-grey;
        margin-left: 8px;
    }
}

.additionalNotesList {
    background: rgba(238, 240, 248, 0.85);
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 15px;

    .notesList {
        background-image: url('../../../Assets/Images/pin-blue.svg');
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $dark-grey;
        background-repeat: no-repeat;
        padding: 0 0 0 24px;
        margin: 8px 0;
    }
}

.addNotesSec {
    padding: 10px 0;
    text-align: right;

    .addNotesBtn {
        background-image: url('../../../Assets/Images/pin-grey.svg');
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        border: 1.2px solid #B4B6C3;
        border-radius: 8px;
        background-color: #fff;
        overflow: hidden;
        background-position: center;
        padding: 0;
    }
}

.aircraftCheckboxes {
    margin: 0 -10px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            width: 25%;
            float: left;
            padding: 6px 10px;

            @media screen and (max-width:767px) {
                width: 50%;
            }

            .k-checkbox-label {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                color: $dark-grey;
                margin-left: 8px;
            }
        }
    }
}

// .legGroupSec {
//     padding-bottom: 5px;
//     padding-top: 10px;
// }

.legSec {
    display: flex;
    padding: 10px 0 0 0;
    justify-content: space-between;
    margin-bottom: -20px;
    position: relative;
    z-index: 99;

    h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $light-grey;
    }

    .tripSwitch {
        display: flex;
        align-items: center;
        position: relative;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            display: flex;
            align-items: flex-end;
            color: $grey-secondary-button-color;
            padding-left: 8px;
        }
    }
}

.prependInput {
    display: flex;
    align-items: flex-end;

    .customInput {
        border-radius: 4px 0 0 4px !important;
    }

    .input-group-prepend {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: -1px;
        background: #F1F2F9;
        border: 1px solid #CDD2E1;
        height: 52px;
        border-radius: 0 4px 4px 0;
        font-weight: 400;
        font-size: 16px;
        color: $dark-grey;
        padding: 0 6px;

        label {
            margin: 0 0 -3px 0;
        }

        .addUserBtn {
            background-image: url('../../../Assets/Images/add-user-icon.svg');
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
            padding: 0;
            background-color: transparent;
            position: initial;
            margin-left: 3px;
            background-size: 15px;
        }
    }
}

.originArrow {
    margin-top: 23px;
    text-align: center;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 18px;
    }
}

.nopricingScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 320px);
    justify-content: center;
    color: $dark-grey;

    img {
        width: 90px;
        margin-bottom: 20px;
    }

    h2 {
        font-weight: 300;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 30px;
    }

    .btn-link {
        margin-top: 15px;
    }
}


.tripScheduleWrapper {
    .maverickForm {
        max-width: inherit;
        padding: 10px;

        .stepperFormBtns {
            padding: 30px 0 0px 0;
        }
    }
}

.tripShortInfo {
    .tripInfoRightSec {
        p {
            color: $dark-blue;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 0;
            font-weight: 400;

            strong {
                font-weight: 600;
            }
        }
    }
}

.tripDetailsFormSec {
    .legsInfoGroup {
        margin-bottom: 10px;
    }

    h6.secTitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $dark-grey;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        .btn-link {
            background: url('../../../Assets/Images/pencil-blue.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 13px;
            padding-left: 20px;
            font-size: 14px;
            text-decoration: none;
        }

        .add-btn-link {
            background-image: url('../../../Assets/Images/pluse-circle-blue.svg');
            background-size: 18px;
        }
    }

    .k-panelbar.optionSelectionPanelBar {
        border: none;
        background: transparent;

        .k-panelbar-header {
            margin-top: 20px;
            // padding: 20px 15px 20px 20px;
            background: rgba(216, 219, 234, 0.15);
            border-radius: 4px;
            border: none;
            overflow: hidden;

            .k-link {
                padding: 0;
                color: $dark-grey;
                font-weight: 400;
                font-size: 12px;
                line-height: 11px;
                margin-bottom: 0;
                background-color: #eff1f8;
                padding: 10px 15px;
                cursor: pointer;
                .k-icon {
                    color: $deep-blue;
                }

                .clientDetailsBand {
                    margin: 0 -5px;

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            width: 12%;
                            float: left;
                            padding: 6px 5px;

                            &:first-child {
                                width: 22%;

                                .routesInfo {
                                    font-weight: 600;
                                }
                            }

                            &:nth-child(2) {
                                width: 15%;
                            }
                        }
                    }
                }

                .k-panelbar-item-text {
                    width: 100%;

                    .tripProposalTitle {
                        padding: 15px 0 15px 5px;

                        span {
                            .k-checkbox {
                                margin-top: -2px;
                            }

                            .k-checkbox-label {
                                font-weight: 500;
                                font-size: 18px;
                                color: $dark-grey;
                                margin-left: 10px;
                            }
                        }
                    }
                }

            }

            .k-focus {
                box-shadow: none;
            }
        }

        .k-panelbar-content {
            margin-bottom: -20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .clientDetailsBand {
        label {
            font-weight: 400;
            font-size: 12px;
            line-height: 11px;
            color: $label-color;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: $dark-blue;
            margin: 8px 0 0 0;
        }
    }
}

.tripDetailsBg {
    background: rgba(216, 219, 234, 0.15);
    border-radius: 4px;
    padding: 20px;

    h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 15px;
    }

    .k-panelbar-item-text {
        width: 100%;

        .overviewTitleGroup {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .otgLeft {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-right: 15px;

                label {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 11px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: $dark-grey;
                }
            }

            .otgRight {
                display: flex;
                align-items: center;
                gap: 15px;
                padding-right: 5px;

                label.time {
                    background-image: url('../../../Assets/Images/clock-black.svg');
                    background-size: 12px !important;
                    padding-right: 15px !important;
                    background-position: top -1px right !important;
                }

                label.noofPeoples {
                    background-image: url('../../../Assets/Images/user-black-ico.svg');
                }

                label {
                    font-weight: 500;
                    font-size: 14px;
                    text-align: right;
                    color: $dark-grey;
                    background-repeat: no-repeat;
                    background-position: top 0px right;
                    background-size: 8px;
                    padding-right: 10px;
                }

                .notAssigned {
                    background-image: url('../../../Assets/Images/warning-yellow.svg');
                    background-repeat: no-repeat;
                    background-position: center left;
                    background-size: 12px;
                    padding-left: 17px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: right;
                    text-transform: initial;
                    letter-spacing: normal;
                    color: $dark-grey;
                }

                .buttonsGroup {
                    display: flex;
                    gap: 24px;

                    .editBtn {
                        background: url('../../../Assets/Images/pencil-blue.svg') !important;
                    }

                    .deleteBtn {
                        background-image: url('../../../Assets/Images/delete-blue.svg') !important;
                    }

                    .k-button {
                        padding: 1px 0 1px 20px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 16px;
                        background-repeat: no-repeat !important;
                        background-position: top left !important;
                        background-size: 13px !important;
                    }

                }

            }
        }
    }
}

span.warningInfo {
    position: relative;

    &:before {
        background: #FFA722;
        width: 6px;
        height: 6px;
        display: inline-block;
        content: "";
        border-radius: 100%;
        margin: 0 5px 0 3px;
    }
}

.jetCardDetailsExpandedSec .clientDetailsBandExpandedData {
    border: none;
    border-radius: 0;
    padding: 0;

    h6 {
        letter-spacing: 0;
        text-transform: none;
    }
}

.clientDetailsBandExpandedData {
    border: 1px solid #E6E9F7;
    background: #FFFFFF;
    border-radius: 6px;
    // padding: 20px 15px 20px 20px;
    padding: 20px;

    h6 {
        padding: 0;
        color: $dark-grey;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    label {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: $label-color;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: $dark-blue;
        margin: 3px 0 0 0;
    }

    .scenariosAction {
        display: flex;
        margin-bottom: 15px;

        .k-radio {
            margin-top: 4px;
        }

        p,
        label {
            color: $dark-blue;
            font-size: 14px;
            line-height: 13px;

            strong {
                font-weight: 500 !important;
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    .font-500 {
        color: $dark-blue;
    }

    .row {
        &:last-child {
            .scenariosAction {
                margin-bottom: 0;
            }
        }
    }

    .row.disabledData {
        opacity: .5;
    }

    .flightRouteInfo {
        color: $dark-grey;

        .routesInfo {
            font-weight: 500;
            font-size: 16px;
            line-height: 15px;
            color: $dark-grey;
            margin: 0px 0 5px 0;

            span {
                background-size: 13px;
                margin: 0px 14px;
            }
        }

        .jetNameSec {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $dark-grey;

            strong {
                font-weight: 500;
            }

            span {
                padding-left: 10px;
                margin-left: 10px;
                border-left: 1px solid $dark-grey;
            }
        }
    }

    .flightDatePrice {
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            color: $dark-grey;
            margin: 0px 0px 8px 0;
        }

        .fPrice {
            color: $primary-dark-blue;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }
    }
}

.proposalFormsWrapper {

    .clientDetailsBandExpandedData {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .feesTypes {
            padding-bottom: 24px;

            .greyTitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                padding-bottom: 8px;
                color: $lighter-grey;
            }

            .radioGroupSec {
                padding-top: 8px;
                display: flex;
                gap: 10px;

                .k-radio {
                    margin-top: -4px;
                }

                .k-radio-label {
                    margin-left: 6px;
                }
            }

            .priceInput {
                .k-input{
                    text-align-last: right;
                }
                .k-disabled{
                    background: rgba(242, 244, 246, 0.8);
                }
            }
        }

        .plainInput {
            background: #FFFFFF;
            border: 1px solid #CDD2E1;
            border-radius: 4px;
            color: $dark-grey;
            font-weight: 400;
            font-size: 14px;
            min-height: 38px;
            padding: 6px 12px;
            font-family: $leagueSpartanFont;

            .k-button {
                background-size: 12px;
                right: 2px;
                top: 3px;
            }
        }
    }

    .bottomPriceSec {
        padding-top: 18px;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: $label-color;
        }

        p {
            font-weight: 400;
            font-size: 26px;
            line-height: 24px;
            color: $primary-dark-blue;
            margin: 10px 0 0 0;
        }
    }

    .jetsPicsecBtm {
        background: rgba(242, 244, 246, 0.5);
        border: 1px dashed #CDD2E1;
        border-radius: 4px;
        padding: 10px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        position: relative;

        img {
            border: 1px dashed #CDD2E1;
            border-radius: 4px;
            overflow: hidden;
            max-width: 100px;
            max-height: 64px;
        }

        .jetsPicDataSec {
            padding-left: 20px;
            width: calc(100% - 120px);

            p {
                // font-weight: 500;
                font-size: 18px;
                line-height: 17px;
                color: $dark-grey;
                margin: 0 0 8px 0;
            }

            .k-checkbox {
                margin: 0 6px 0 0;
            }

            .k-checkbox-label {
                font-weight: 400;
                font-size: 14px;
            }
        }

        .deleteImageBtn {
            background: url('../../../Assets/Images/close-blue.svg') center no-repeat;
            background-size: 13px;
            padding: 0;
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-left: 5px;
            border: none;

            &:hover,
            &:focus {

                &:after,
                &:before {
                    display: none !important;
                }
            }
        }
    }
}

.optionDisplayName {
    display: flex;
    padding: 15px 0 0 0;
    align-items: center;
    justify-content: flex-end;

    label {
        color: $label-color;
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        padding-right: 10px;
    }

    .form-control {
        background: #FFFFFF;
        border: 1px solid #CDD2E1;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: $dark-blue;
        min-height: 38px;
        max-width: 250px;
    }
}

.legTbl {
    padding-bottom: 10px;

    &:last-child {
        padding-bottom: 0px;
    }

    h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        color: $dark-grey;
        border-bottom: 1px solid #E6E9F6;
        padding-bottom: 10px;
        margin-bottom: 2px;
    }

    .k-grid {
        table {
            min-width: 100%;

            .gridActions {
                gap: 10px;
            }

            colgroup {
                col:last-child {
                    width: 110px;
                }
            }

            td:first-child {
                padding-left: 12px;
            }
        }

        .k-grid-header {
            background: transparent;

            table {
                tr {
                    th .k-link {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 11px;
                        color: #919AA9;
                        text-transform: initial;

                        &:after {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

    .addRoleBtn {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
    }
}

.unionBtn {
    background-image: url('../../../Assets/Images/union-icon-blue.svg') !important;
}

.inputBorderNone {
    border-color: transparent;
    color: $label-color;
    background-color: transparent;

    .k-input-inner {
        padding: 0;
        width: auto;
        flex: initial;

        .k-input-value-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            color: $label-color;
        }
    }

    .k-button {
        padding: 0;
        margin-left: 5px;

        .k-icon {
            background-image: url('../../../Assets/Images/down-angle-grey.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
            width: 16px;
            height: 16px;
            padding: 0;
            background-color: transparent;

            &::before {
                display: none;
            }
        }
    }

    &:hover,
    &:focus,
    &:focus-within {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
    }
}

.addScenarioSec {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #E6E9F7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $dark-grey;
        margin: 0;
    }

    .btnGroups {
        display: flex;
        gap: 24px;

        .splitScenario {
            background-image: url('../../../Assets/Images/split-scenario.svg') !important;
        }

        .combinedScenario {
            background-image: url('../../../Assets/Images/combined-scenario.svg') !important;
        }

        .k-button {
            padding: 1px 0 1px 22px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            background-repeat: no-repeat !important;
            background-position: center left !important;
            background-size: 16px !important;
        }

    }
}

.addSplitPopup {
    .k-dialog {
        max-width: 900px;

        .maverickForm {
            margin-top: -20px;
            max-width: inherit;

            .legTbl {
                padding-top: 20px;
                padding-bottom: 0;

                &:last-child {
                    padding-top: 0;
                }
            }
        }
    }
}

.routesInfo {
    font-weight: 600;
    color: $dark-blue;
    margin: 0;

    .roundTripIcon {
        background-image: url('../../../Assets/Images/arrowsLeftRight.svg');
    }

    .onewayTripIcon {
        background-image: url('../../../Assets/Images/arrowsRight.svg');
    }

    .roundTripIcon,
    .onewayTripIcon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 9px;
        width: 16px;
        height: 10px;
        display: inline-block;
        margin: 0px 3px;
    }

    // .divider {
    //     background-color: #82869B;
    //     width: 1px;
    //     height: 10px;
    //     display: inline-block;
    //     margin: 0 5px;
    // }
}

.jetCardDetailsExpandedSec .clientDetailsBandExpandedData .jetCardShortInfo {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 10px;
    }

    .shortDetailsWrapper {
        label {
            margin-bottom: 8px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-top: 0;
        }

        .routesInfo {
            font-weight: 500;
            font-size: 14px;

            span {
                background-size: 10px;
                width: 15px;
                margin: 0;
            }
        }
    }
}

.legHistory {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding-left: 50px;
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;

            .timelineSec {
                width: 40px;
                position: absolute;
                //height: calc(100% - 20px);
                height: 100%;
                left: 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin-top: -15px;

                &::after {
                    border-left: 1px dashed $dark-grey;
                    content: '';
                    position: absolute;
                    height: 100%;
                    left: 15px;
                    width: 1px;
                }

                &::before {
                    border: 1.5px solid $primary-dark-blue;
                    content: '';
                    position: absolute;
                    left: 9px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background-color: #fff;
                    z-index: 9;
                }
                &.done{
                    &::after {
                        border-left: 1px solid $dark-grey;
                    }

                    &::before {
                        border: 1.5px solid $primary-dark-blue;
                        background-color: $primary-dark-blue;
                    }
                }
                &.single{
                    &::after {
                        display: none;
                    }
                }
            }

            .userSecData {
                display: inline-block;
                width: 100%;
                padding-bottom: 0;
                opacity: .6;

                .titleSec {
                    display: flex;
                    justify-content: space-between;

                    .leftSecData {
                        .routesInfo {
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 18px;
                            color: $dark-blue;
                            margin-top: 10px;

                            span {
                                background-size: 15px;
                                width: 22px;
                                margin: 0px 10px;
                            }
                        }

                        label {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 13px;
                            color: rgba(0, 8, 55, 0.6);

                            span {
                                color: $deep-blue;
                                font-weight: 600;
                            }
                        }
                    }

                    .rightSecData {
                        .rightSecDataInner {
                            display: flex;
                            gap: 15px;
                            align-items: center;
                            justify-content: space-between;
                            padding-top: 5px;
                            margin-right: -10px;

                            label.legEtaTime {
                                background-image: url('../../../Assets/Images/clock-blue-sml.svg');
                                background-size: 14px;
                                background-position: top -1px left;
                            }

                            label.legUsers {
                                background-image: url('../../../Assets/Images/user-blue-ico.svg');
                                background-size: 9px;
                                background-position: center left;
                                padding-left: 15px;
                            }

                            label {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 11px;
                                color: $dark-grey;
                                background-repeat: no-repeat;
                                display: inline-block;
                                padding: 2px 0 0px 18px;
                            }

                            .legStatus {
                                border-radius: 30px;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 11px;
                                padding: 7px 12px 6px 12px;
                                position: relative;
                            }

                            .inprogress {
                                background: #E2F3E7;
                                color: $success-color;

                                &:before {
                                    width: 6px;
                                    height: 6px;
                                    background: $success-color;
                                    content: "";
                                    display: inline-block;
                                    margin-right: 6px;
                                    border-radius: 100%;
                                }
                            }

                            span.tripLeg {

                                &::before {
                                    width: 6px;
                                    height: 6px;
                                    content: "";
                                    display: inline-block;
                                    margin-right: 6px;
                                    border-radius: 100%;
                                }
                            }

                            // Trip Leg Status
                            .draft {
                                background: #EDEEF2;
                                color: #00041B;
                                &::before {
                                    background-color: #B4B6C3;
                                }
                            }

                            .inQueue {
                                background: #EDEFFF;
                                color: #00041B;
                                &::before {
                                    background-color: #949DD7;
                                }
                            }

                            .schedulerAssigned {
                                background: #FFF1DC;
                                color: #00041B;
                                &::before {
                                    background-color: #FFA722;
                                }
                            }

                            .quoteReview {
                                background: #FCF8D1;
                                color: #00041B;
                                &::before {
                                    background-color: #F5DE0D;
                                }
                            }

                            .clientReview {
                                background: #1E85FF2B;
                                color: #00041B;
                                &::before {
                                    background-color: #0656CE;
                                }
                            }

                            .accepted {
                                background: #E7FCED;
                                color: #00041B;
                                &::before {
                                    background-color: #1C9240;
                                }
                            }

                            .operatorAssigned {
                                background: #0066FF1F;
                                color: #00041B;
                                &::before {
                                    background-color: #001489;
                                }
                            }

                            .inTransit {
                                background: #EBF7FF;
                                color: #00041B;
                                &::before {
                                    background-color: #9BCBEB;
                                }
                            }

                            .completed {
                                background: #02106126;
                                color: #00041B;
                                &::before {
                                    background-color: #000C52;
                                }
                            }

                            .cancelled {
                                background: #FDEBEB;
                                color: #00041B;
                                &::before {
                                    background-color: #D14141;
                                }
                            }

                            .upcoming {
                                background: #E6E9F7;
                                color: $primary-dark-blue;
                            }

                        }
                    }
                }

                .shortDetailsWrapper {
                    p {
                        line-height: 18px;

                        .specialReqTooltip {
                            display: flex;
                            align-items: center;
                            margin-bottom: 5px;

                            div {
                                background: transparent;
                                border: none;
                                padding: 0;
                                margin: 0;

                                &::before {
                                    display: none;
                                }

                                img {
                                    width: 14px;
                                    height: 14px;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }

            &:first-child {
                .timelineSec.timelineSecInProgress {
                    &:after {
                        top: 50%;
                        height: 50%;
                    }

                    &:before {
                        top: 50%;
                    }
                }
            }

            &:last-child {
                .timelineSec.timelineSecInDone {
                    &:after {
                        bottom: 50%;
                        height: 50%;
                    }

                    &:before {
                        top: 50%;
                        background: $primary-light-blue;
                        border: transparent
                    }
                }
            }

            &:last-child {
                .timelineSec {
                    &:after {
                        bottom: 50%;
                        height: 50%;
                    }

                    &:before {
                        top: 50%;
                    //     background: $primary-light-blue;
                    //     border: transparent
                    }
                }
            }
        }

        li.activeLeg {
            .timelineSec {
                &:before {
                    background-color: #fff;
                    background-image: url('../../../Assets/Images/blue-circle.svg');
                    background-size: 12px;
                    background-position: center;
                    background-repeat: no-repeat;
                    padding: 5px;
                    width: 22px;
                    height: 22px;
                    box-shadow: 0px 0px 8px rgba(45, 63, 89, 0.35);
                    left: 4px;
                }
            }

            .userSecData {
                opacity: 1;
            }
        }
    }

}

.taskList {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li.openTask {
            .statusIcon {
                background-image: url('../../../Assets/Images/check-circle-grey.svg');
            }
        }

        li.completedTask {
            .statusIcon {
                background-image: url('../../../Assets/Images/check-fill-green.svg');
            }
        }

        li {
            padding-left: 50px;
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;

            .statusIcon {
                background-size: 22px;
                background-position: top 30px center;
                background-repeat: no-repeat;
                width: 40px;
                position: absolute;
                height: calc(100% - 20px);
                left: 0;
            }

            .taskSecData {
                background: #FFFFFF;
                border: 0.5px solid rgba(210, 218, 227, 0.75);
                border-radius: 5px;
                padding: 12px 20px 0 20px;
                overflow: hidden;

                .titleSec {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                }

                .leftSecData {
                    .routesInfo {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 18px;
                        color: $dark-blue;
                        margin-top: 10px;

                        span {
                            background-size: 15px;
                            width: 22px;
                            margin: 0px 10px;
                        }
                    }

                    label {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 13px;
                        color: rgba(0, 8, 55, 0.6);

                        span {
                            color: $deep-blue;
                            font-weight: 600;
                        }
                    }
                }

                .rightSecData {
                    .rightSecDataInner {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 5px;

                        .pastDate {
                            background-image: url('../../../Assets/Images/calendarRed.svg');
                            color: $error-color;
                        }

                        .dueDate {
                            background-image: url('../../../Assets/Images/calendarBlue.svg');
                            color: $primary-dark-blue;
                        }

                        .completedDate {
                            background-image: url('../../../Assets/Images/calendarGreen.svg');
                            color: $success-color;
                        }

                        .taskDate {
                            background-size: 16px;
                            background-position: top -1px left;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 13px;
                            background-repeat: no-repeat;
                            display: inline-block;
                            padding: 2px 0 0px 20px;

                            strong {
                                font-weight: 600;
                                padding-right: 5px;
                            }
                        }

                        .taskStatus {
                            border-radius: 30px;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 13px;
                            padding: 7px 12px 6px 12px;
                            position: relative;
                        }

                        .overdue {
                            background: #FDEBEB;
                            color: $error-color;
                        }

                        .upcoming {
                            background: #E6E9F7;
                            color: $primary-dark-blue;
                        }
                    }
                }

                .tripInquiryExpandedSec {
                    margin: 0 -20px;

                    .k-panelbar-header {
                        margin-top: 0;
                        border: none;
                        border-radius: 0;
                        padding: 0;

                        .k-link {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 15px;
                            color: $dark-blue;
                            text-transform: none;
                            letter-spacing: 0;
                            margin-bottom: 20px;
                            padding: 0 20px;
                            cursor: pointer;
                            .k-icon {
                                color: $primary-light-blue;
                            }
                        }
                    }
                }
            }

            .shortDetailsWrapper {
                background-color: rgb(216, 219, 234, .15);
                padding: 10px 20px;

                p {
                    font-size: 14px;
                    margin: 2px 0 5px 0;
                }
            }
        }
    }
}

// Trip Accepted Model start

.acceptTripModel {
    .k-dialog {
        max-width: 1100px !important;
        overflow: hidden !important;
        border-radius: 4px 4px 0 0 !important;

        .jetcardAgreementWrapper {
            max-width: 100%;
            padding-top: 0;

            .topLogoSec {
                .headerRightData {
                    label {
                        font-size: 14px;
                    }

                    h1 {
                        font-size: 30px;
                        line-height: 36px;
                        margin: 5px 0 20px 0;
                    }
                }
            }

            .headerContactDetails {
                padding-top: 30px;

                ul li {
                    label {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }

            .tripShortInfoCards {
                border: 1px solid #E6E9F7;
                border-radius: 6px;
                background: #FFFFFF;
                padding: 26px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                .jetImgLeft {
                    width: 280px;
                    border: 1px dashed #CDD2E1;
                    border-radius: 6px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .jetInfoRightSec {
                    width: 100%;
                    max-width: 600px;

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;

                        li {
                            padding: 15px 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            label {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 15px;
                                color: $dark-grey;

                                &:last-child {
                                    font-weight: 500;
                                }
                            }
                        }

                        .allInCash {
                            label {
                                color: $primary-dark-blue;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 18px;
                            }
                        }

                        .divider {
                            background: rgba(217, 217, 217, 0.65);
                            padding: 0;
                            height: 1px;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .journeyDate {
                    display: flex;
                    flex-direction: column;

                    .jDate {
                        background-image: url('../../../Assets/Images/calendar-blank.svg');
                    }

                    .jLegacy {
                        background-image: url('../../../Assets/Images/jet-black-icon.svg');
                    }

                    label {
                        color: $dark-grey;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 15px;
                        background-size: 16px;
                        background-position: center left;
                        background-repeat: no-repeat;
                        padding: 4px 0 0 28px;
                        margin: 5px 0;
                    }
                }

                .location {
                    display: flex;
                    align-items: center;

                    h3 {
                        font-weight: 100;
                        font-size: 64px;
                        line-height: 50px;
                        color: $deep-blue;
                        padding-right: 15px;
                        margin: 0;
                        padding-top: 9px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                    }

                    label {
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 18px;
                        color: $dark-grey;
                        padding: 5px 0;
                    }
                }

                .journeyTime {
                    background-size: 32px;
                    color: $deep-blue;
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-image: url('../../../Assets/Images/lrg-blue-arrow.svg');
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 15px;
                    padding-top: 25px;
                    text-align: center;
                }
            }

            .tripDetailsCard {
                background: #FFFFFF;
                border: 2px solid rgba(238, 240, 248, 0.85);
                border-radius: 8px;
                padding: 24px;
                margin-top: 30px;

                .detailsTitle {
                    background: rgba(238, 240, 248, 0.85);
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 18px;
                    color: $dark-grey;
                    margin: -24px -24px 20px -24px;
                    padding: 24px;
                }
            }

            .tripCardGrey {
                background: rgba(216, 219, 234, 0.15);
            }

            .downloadContractSec {
                background-image: url('../../../Assets/Images/download-contact-bg.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 140px;
                overflow: hidden;
                border-radius: 10px;
                margin-top: 30px;

                .downloadContractBtn {
                    background-image: url('../../../Assets/Images/jet-blue-icon.svg');
                    background-size: 18px;
                    background-position: center right 20px;
                    background-repeat: no-repeat;
                    color: #000C52;
                    background-color: #fff;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 18px;
                    border-radius: 6.18293px;
                    padding: 15px 55px 15px 20px;
                }

            }
        }

        .tripModelWrapper {
            height: calc(100vh - 165px) !important;
        }
    }

    .exTemplateFooter {
        padding: 20px 24px;
        margin: 30px -30px -30px -30px;
    }
}


// Hide Show columns on mobile view
@media screen and (max-width: 768px) {

    // JC Promotions Table
    .k-grid.tripListTable {
        table {
            min-width: 100% !important;
            col:nth-child(3),
            th:nth-child(3),
            td:nth-child(3),
            col:nth-child(4),
            th:nth-child(4),
            td:nth-child(4),
            col:nth-child(6),
            th:nth-child(6),
            td:nth-child(6),
            col:nth-child(8),
            th:nth-child(8),
            td:nth-child(8),
            col:nth-child(9),
            th:nth-child(9),
            td:nth-child(9),
            col:nth-child(10),
            th:nth-child(10),
            td:nth-child(10),
            col:nth-child(11),
            th:nth-child(11),
            td:nth-child(11) {
                display: none !important;
            }

            col:nth-child(11),
            th:nth-child(11),
            td:nth-child(11) {
                visibility: hidden !important;
            }

            colgroup {
                col:nth-child(5) {
                    width: 40% !important;
                }

                col:nth-child(7) {
                    width: 45% !important;
                }

                col:last-child {
                    width: 100px !important;
                }
            }

            .k-master-row {
                td:first-child {
                    padding-left: 10px !important;
                }
            }

            .k-detail-row {

                .k-hierarchy-cell,
                .k-hierarchy-cell .k-link:after {
                    display: none;
                }

                .k-detail-cell {
                    .tableDetailsData {
                        display: flex;
                        flex-direction: column;

                        .tripDetailsInfo {
                            margin: 0 0px 15px 0px;
                            border-bottom: 1px solid #E6E9F6;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            li {
                                width: 50%;
                                padding: 3px 8px;
                            }

                            &:last-child {
                                border: none;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .jetCardDetailsExpandedSec .clientDetailsBandExpandedData .jetCardShortInfo:last-child {
        margin-bottom: 15px;
    }

    .userDetailsWrapper .userDetailSec .userTitleSec.pt-2 {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .legHistory ul li {
        padding-left: 35px;

        .userSecData .titleSec {
            flex-direction: column-reverse;

            .leftSecData {
                padding-top: 10px;

                .routesInfo {
                    font-size: 16px;
                    margin-top: 5px;
                }
            }
        }
    }

    .userDetailsWrapper .userDetailSec .userTitleSec {
        margin: 0px;
    }

    .taskList ul li .statusIcon {
        background-size: 20px;
        background-position: top 50px center;
        width: 24px;
    }

    .taskList ul li {
        padding-left: 30px;
    }

    .taskList ul li .taskSecData .titleSec {
        flex-direction: column-reverse;

        .leftSecData {
            padding-top: 5px;
        }
    }

    .taskList ul li .taskSecData .titleSec {
        margin-bottom: 10px;
    }

    .taskList ul li .taskSecData .tripInquiryExpandedSec .k-panelbar-header .k-link {
        line-height: 20px;
        margin-bottom: 10px;
    }

    .taskList ul li .shortDetailsWrapper {
        position: relative;

        .row {
            padding-top: 30px;

            .col-6.col-lg-2.text-end {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }
    }
}

.cardAuthorization {
    padding-top: 15px;

    .k-checkbox {
        margin: -3px 5px 0 0;
    }

    .k-checkbox-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: $dark-grey;
    }
}

.multipleEmailInfo {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    background-image: url('../../../Assets/Images/info-cicle-dark-blue.svg');
    background-size: 16px;
    background-position: center left;
    background-repeat: no-repeat;
    padding: 0 0 0 22px;
    margin-top: 15px;
}

.tripOverview {
    background: rgba(238, 240, 248, 0.85);
    border-radius: 4px;
    padding: 16px;

    .tripOverviewDetails {
        @media screen and (max-width:768px) {
            min-width: 767px;
            overflow-x: auto;
        }

        .tableHeaderRow {
            display: flex;


            >div {
                font-size: 12px;
                line-height: 11px;
                font-weight: 400;
                color: #82869B;

                @media screen and (max-width:768px) {
                    width: 100%;
                    padding: 4px 0;
                }

                &:nth-child(1) {
                    width: 5%;
                }

                &:nth-child(2) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 10%;
                }

                &:nth-child(6) {
                    width: 10%;
                }

                &:nth-child(7) {
                    width: 15%;
                }

                &:nth-child(8) {
                    width: 15%;
                }

                &:nth-child(9) {
                    width: 5%;
                }
            }

        }

        .tableDataRow {
            display: flex;
            margin-top: 16px;


            >div {
                font-size: 14px;
                line-height: 13px;
                font-weight: 400;
                color: #000837;

                @media screen and (max-width:768px) {
                    width: 100%;
                    padding: 4px 0;
                }

                &:nth-child(1) {
                    width: 5%;
                }

                &:nth-child(2) {
                    width: 20%;
                }

                &:nth-child(3) {
                    width: 15%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 10%;
                }

                &:nth-child(6) {
                    width: 10%;
                }

                &:nth-child(7) {
                    width: 15%;
                }

                &:nth-child(8) {
                    width: 15%;
                }

                &:nth-child(9) {
                    width: 5%;
                }
            }

        }

    }
}

.createOptionsWrap {
    background: #FFFFFF;
    border: 1px solid #E6E9F7;
    border-radius: 6px;
    padding: 20px;
    margin-top: 20px;

    @media screen and (max-width:767px) {
        padding: 10px;
    }

    .legsInfoGroup {
        margin-bottom: 0;
    }

    h6.secTitle {
        padding-top: 0;
        letter-spacing: 0.2em;
        font-weight: 400;
    }

    .k-link {
        background: rgba(216, 219, 234, 0.05) !important;
        border-bottom: 1px solid #E6E9F6;
    }

    .optionHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftPane {
            position: relative;
            display: flex;
            align-items: center;

            input,
            input::placeholder {
                background-color: transparent;
                padding: 2px;
                border: none;
                border-bottom: 1.5px solid #001489;
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                color: #82869B;
                outline: none;
            }
            .createOptionTitleText{
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                color: #82869B;
            }
            .editBlueIcon {
                margin-left: -12px;
                background-size: 10px;
            }

            .priceCumult {
                margin-left: 12px;
                font-size: 14px;
                line-height: 13px;
                font-weight: 600;
                color: #000837;
            }
        }

        .rightPane {
            position: relative;
            display: flex;
            align-items: center;
            gap: 4px;

            .checkGreyIcon {
                background-size: 12px;
            }

            font-weight: 600;
            font-size: 12px;
            line-height: 11px;
            color: #B4B6C3;
        }
    }

    .createOptionsDetails {
        background: rgba(216, 219, 234, 0.15);
        padding-inline: 16px;

        .headerRow {
            padding: 16px 0;

            >div {
                font-size: 12px;
                line-height: 11px;
                font-weight: 400;
                color: #82869B;

                @media screen and (max-width:768px) {
                    padding: 4px 0;
                }

                &:nth-child(1) {
                    width: 20%;
                }

                &:nth-child(2) {
                    width: 40%;
                }

                &:nth-child(3) {
                    width: 40%;
                }

                &.flex-end {
                    @media screen and (max-width:768px) {
                        justify-content: flex-start;
                    }
                }
            }
        }

        .dataRow {
            margin-top: 16px;

            &:last-child {
                padding-bottom: 16px;
            }

            >div {
                font-size: 14px;
                line-height: 13px;
                font-weight: 400;
                color: #000837;

                @media screen and (max-width:768px) {
                    padding: 4px 0;
                }

                &:nth-child(1) {
                    width: 20%;
                }

                &:nth-child(2) {
                    width: 40%;
                    word-break: break-all;
                }

                &:nth-child(3) {
                    width: 40%;
                    word-break: break-all;
                }

                &.flex-end {
                    @media screen and (max-width:768px) {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

.splitScenarioWrap {
    .clientDetailsBandExpandedData {
        @media screen and (max-width: 767px) {
            >.row {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;

                    .scenariosAction {
                        margin-bottom: 8px;
                    }
                }
            }

            h6,
            .scenariosAction {
                margin-bottom: 8px;
            }
        }
    }

    .text-end {
        @media screen and (max-width: 767px) {
            text-align: left !important;
        }

    }

    .flex-end {
        @media screen and (max-width: 767px) {
            justify-content: flex-start;
        }
    }
}

.optionEditableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 0 13px 5px;

    .leftPane {
        position: relative;
        display: flex;
        align-items: center;

        input,
        input::placeholder {
            background-color: transparent;
            padding: 2px;
            border: none;
            border-bottom: 1.5px solid #001489;
            font-weight: 400;
            font-size: 20px;
            line-height: 18px;
            color: #82869B;
            outline: none;
            width: 300px;
        }

        .editBlueIcon {
            margin-left: -12px;
            background-size: 12px;
        }
    }

}

.hourlyRateTable {
    th {
        padding: 12px 2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: #00041B;
        border-bottom: 1px solid rgba(217, 217, 217, 0.65);

        &:nth-child(2) {
            width: 25%;
            text-align: right;

            @media screen and (max-width:767px) {
                width: 40%;
            }
        }
    }

    td {
        padding: 4px 2px;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: #00041B;
    }

    .total {
        font-size: 16px;
        line-height: 15px;
        font-weight: 500;
        text-align: right;
    }

    .borderRow {
        border-top: 1px solid rgba(217, 217, 217, 0.65);

        td {
            padding-top: 16px;
        }
    }

    .pb16 {
        td {
            padding-bottom: 16px;
        }
    }
}

.finalTotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(238, 240, 248, 0.85);
    border-radius: 4px;
    padding: 16px 12px;

    .left {
        font-size: 18px;
        line-height: 17px;
        font-weight: 600;
        color: #00041B;

        span {
            font-size: 12px;
            line-height: 11px;
            font-weight: 400;
            color: #00041B;
        }
    }

    .right {
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
        text-align: right;
        color: #00106E;
    }
}

.specialFeeOption {
    background: none !important;
    text-align-last: left !important;
}

.tripStatusIndicator {
    background-color: #E1E4F3;
    border: 0.74359px solid #00106E;
    border-radius: 4.46154px;
    font-weight: 400;
    font-size: 11.8974px;
    line-height: 11px;
    color: #2D3F59;
    padding: 5px 8px;
    display: inline-block;
    position: relative;
}

.tripPassengerAssigneeTable{
    &.k-grid table{
        min-width: auto;
    }
    .theadAlignCenter{
        .k-cell-inner{
            justify-content: center;
            .k-link{
                padding: 0 0.1rem;
                &::after{
                    display: none;
                }
            }
        }
    }
 .k-grid-header .k-header:last-child .k-cell-inner .k-link .k-column-title{
    padding-right:10px !important;
 }
 .k-master-row td:first-child{
    text-align: left;
 }
}

.trip-cancel-info-icon {
    height: 14px;
    position: relative;
    bottom: 1px;
    margin-right: 8px;
}
