// variables
$leagueSpartanFont: 'League Spartan', sans-serif;
$AlluraFont: 'Allura', cursive;
$light-grey: #2D3F59;
$dark-grey: #00041B;
$text-dark-black-color: #00041B;
$primary-dark-blue: #00106E;
$primary-light-blue: #001489;
$deep-blue: #000C52;
$grey-secondary-button-color: #667080;
$link-hover: #061C9E;
$dark-blue: #000837;
$label-color: #82869B;
$lighter-grey: #6F748E;
$white-color: #ffffff;
$white90: #cccccc;
$grey-light-border: rgba(210, 218, 227, 0.75);
//Variables end

// status color
$error-color: #D14141;
$warning-color: #FFA722;
$info-color: #0656CE;
$success-color: #1C9240;
