/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
  body {
    padding-top: 60px;
  }

  .modal-open {
    padding-right: 0px;
  }

  .k-button-solid-primary,
  .k-button-outline-primary {
    min-height: initial !important;
    padding: 8px;
  }

  .topTitleSec,
  .contactTopTitleSec {
    padding: 15px 0 !important;

    .title_backBtn,
    .titleSec {
      h1 {
        font-size: 22px;
        // span{display: none;}
      }

      .backBtn {
        font-size: 14px;
        background-size: 6px;
        padding-left: 15px;

        span {
          display: none;
        }
      }
    }

    .rightBtnGroup {
      gap: 10px;
      display: flex;
      justify-content: flex-end;

      .k-button-outline-primary {
        margin-right: 0;
      }

      .k-button-solid-primary,
      .k-button-outline-primary {
        padding: 8px 12px;

        .k-button-text {
          span {
            display: none;
          }
        }
      }

      .whiteEditIconBtn,
      .whiteAddIconBtn {
        padding-left: 38px;
      }

      .moreActionLinks {
        padding-right: 0px !important;

        .k-button-outline,
        .k-button-outline:hover {
          background-image: url('../../Assets/Images/moreDots.svg') !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 24px !important;
          width: 24px;
          height: 30px;
          padding: 5px 0px 5px 0;

          &:before {
            display: none;
          }

          .k-button-text {
            display: none;
          }
        }

        &:hover,
        &:focus {
          .k-button-outline {
            background-image: url('../../Assets/Images/moreDots.svg') !important;
          }
        }
      }

      .delete-btn-link {
        min-width: 32px;
        min-height: 32px;
        background-position: center;
        background-size: 18px;
        padding: 0;

        .k-button-text {
          display: none;
        }
      }
    }
  }

  .title_mobSpaceNone {
    padding-top: 0 !important;
  }

  .formWrapper {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .maverickFormCard {
    padding: 20px;

    .formTitle {
      font-size: 20px;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 5px;
    }

    .formTitle.mt-5 {
      margin-top: 2.2rem !important;
    }

    .addMemberGroup {
      .actionBtnSpace {
        .form-group {
          max-width: calc(100% - 60px);
        }
      }

      .rowActionBtn {
        left: calc(100% - 52px);
      }
    }
  }

  .maverickForm {
    .addRoleBtn {
      // padding-right: 60px;
    }

    .removeTeamBtn {
      margin-bottom: 0;
      // padding-right: 60px;
    }
  }

  .userDlImg .deleteDoc {
    min-height: 30px !important;
  }

  .commonAlertMsg .alert {
    font-size: 14px;
    line-height: 16px;
    padding: 12px 12px 12px 40px;
    background-position: top 12px left 15px;
    background-size: 16px !important;
    z-index: 999;
  }

  //Tabs UI
  .customTab {
    .k-tabstrip-items-wrapper {
      margin: 0 -12px 20px -12px;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .k-tabstrip-items .k-item .k-link::after {
        height: 3px;
      }
    }

    .k-tabstrip-items {
      display: flex;
      flex-flow: initial;
    }
  }



  /*  Accordion UI */
  .permissionsList {
    padding-top: 5px;

    .k-expander {
      .k-expander-header {
        min-height: 50px;
      }
    }

    .k-expander-content {
      padding-right: 17px;

      .permissionsCatSec {
        .permissionCatLists {
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }

          .catCol {
            height: 54px;
            margin: 10px 0;

            .moreIconDropdownBtn .k-button {
              background-size: 20px !important;
              padding: 0;
            }

            .k-checkbox-md {
              margin-top: 0px;
            }
          }

          .row .col-sm-6:last-child {
            .catCol {
              margin-bottom: 0;
            }
          }
        }
      }

      .permissionSubLevelSec {
        padding-bottom: 35px;

        .selectPermissionGroup {
          padding-left: 0;

          .row .col-lg-6 {
            padding-left: 12px;
          }

          .fieldLabelGrey {
            margin: 25px 0 8px 0;
          }
        }
      }
    }
  }

  .formBottomBtns {
    .k-button-md {
      width: 100%;
    }
  }

  .k-grid {
    table {
      min-width: 1300px;
      border-spacing: 0 0.5em;

      .k-master-row td {
        padding: 10px;
      }
    }
  }

  .dataListViewWrapper .dataGridFilterSec {
    padding: 15px;
    margin: 5px 0 2px 0;

    .maverickForm {
      margin-top: 0;
      padding-bottom: 0;
    }

    .filterSelectedData {
      padding-left: 0;
    }
  }

  .k-grid-pager {
    padding: 10px 0 15px 0;
    flex-direction: column;
    gap: 15px;

    select.k-dropdown-list {
      display: none !important;
    }

    .k-pager-numbers {
      display: flex;
    }
  }

  //Calender css
  .k-calendar .k-calendar-navigation {
    display: none;
  }

  // Dialog css
  .deleteDialog .k-dialog {
    max-width: 350px;

    .k-dialog-titlebar {
      padding: 18px 15px 0 20px;
    }

    .k-button .k-button-icon {
      background-size: 16px;
    }

    .k-window-content {
      padding: 20px;

      .alertMsg {
        font-size: 18px;
        line-height: 22px;
      }

      .modelFooterAction {
        justify-content: center;
        padding-top: 20px;

        .k-button {
          min-width: inherit;
          min-height: 38px;
          padding: 9px 15px;
        }
      }
    }
  }

  .profilePicUploadOuter .documentPdfView {
    min-width: 100%;
    height: calc(100vh - 150px);
  }

  .mobileReverse {
    flex-direction: row;
  }

  .flexDirColumn {
    flex-direction: column;
  }

  .alignLeftMobile {
    text-align: left !important;
  }

  .width45Mobile {
    width: 45%;
  }
  .stepperFormBtnsMobile{
    display: block !important;
    .rightBtnGroup{
      width: 100%;
    gap: 8px !important;
    margin-top: 20px;
    }
  }
}