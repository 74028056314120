.pdfViewerModal {
  .k-dialog {
    width: 100%;
    max-width: 980px;
  }

  .pdfViewerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-right: 2px;
  }

  .pdfViewerBody {
    .scrollerView {
      min-height: 80vh;

      .bodyWrapper .invoiceHeader {
        display: none;
      }

      .header {
        padding: 20px;
        min-height: 130px;

        .leftWrapper {
          .logoScetion img {
            height: 40px;
          }

          .nameSection .name {
            font-size: 30px;
            line-height: 28px;
            margin-top: 11px;
          }
        }
      }
    }
  }
}

.rowPadding .row {
  padding: 6px 0;

  .k-checkbox {
    margin-top: -3px;
  }

  .k-checkbox.k-invalid {
    border-color: #dc3545;
  }
}

.operatorPaymentPanel {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #dde1f6;
  background: rgba(238, 240, 248, 0.5);

  .k-panelbar {
    background-color: transparent;

    .k-panelbar-item-text {
      width: 100%;
    }

    .k-panelbar-item .k-link {
      background-color: transparent !important;
      padding-bottom: 0 !important;
    }
    .k-content {
      background-color: transparent !important;
    }
  }

  .operatorPayPanelHead {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .leftTitle {
      color: #00041b;
      font-size: 16px;
      font-weight: 500;
    }

    .rightTitle {
      text-align: right;
      font-size: 14px;
      color: #82869b;
      font-weight: 600;
      &.green {
        color: #1c9240;
      }
      &.red {
        color: #d14141;
      }
    }
  }
}

.assignPassengerModal {
  .flexCenterGap {
    @media screen and (max-width: 767px) {
      font-size: 13px;
      > div:first-child {
        width: 50%;
      }
      > div:last-child {
        width: 24%;
      }
    }
  }
}

.centerModelLarge {
  .k-dialog {
    max-width: 600px;
  }
}

.associated-trip-slider-Modal {
    .carousalContainer{
        @media screen and (max-width: 767px) {
            height: 156px !important;
        }
    }
  .viewProfileSubHeader {
    gap: 24px;
    width: 100%;
  }
  .associated-trip-slider {
    position: relative;
    width: 189px;
    height: 150px;
    border: none;
    @media screen and (max-width: 767px) {
        width: 120px;
        height: 130px;
    }
    .k-scrollview-wrap {
      z-index: 1;

      .k-scrollview-view {
        //  height: 126px;
        border-radius: 4px;

        .image-with-text {
          img {
            width: 100%;
            max-height: 126px !important;
            min-height: 126px !important;
            border-radius: 4px;
          }
        }
      }
    }
    .k-scrollview-elements {
      width: 100%;
      height: 100%;
      position: relative;
      .k-scrollview-next,
      .k-scrollview-prev {
        height: auto;
        top: 100%;
        margin-top: -18px;
        z-index: 100;
        svg {
          color: #001489;
        }
      }
    }
    .k-scrollview-nav-wrap {
      bottom: -6px;
      height: auto;
      .k-link {
        width: 3.6px;
        height: 3.6px;
        margin: 0 4px;
        &.k-primary {
          width: 6px;
          height: 6px;
          background-color: #001489;
          &::before {
          }
        }
      }
      .k-scrollview-pageable,
      .k-scrollview-nav {
        padding: 0;
        overflow: hidden;
      }
    }
  }

  .aircraft-detail {
    flex: 1;
    max-width: calc(100% - 190px);
    .aircraft-name {
      h3 {
        font-size: 26px;
        line-height: 24px;
        color: #00106e;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 19px;
        }
      }
      .tail {
        font-size: 18px;
        line-height: 17px;
        font-weight: 300;
        color: #00041b;
        overflow: hidden;
        text-overflow: ellipsis;
        .operator-name{
            font-weight: 400;
        }
        @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 15px;
        }
      }
    }
    .aircraft-type {
      margin-bottom: 20px;
      p {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        color: #00041b;
        margin-bottom: 0;
        .pax-capacity {
          font-weight: 400;
        }
      }
    }
  }
  .contactOverviewPanel {
    .k-panelbar .k-panelbar-item.k-expanded {
      padding-bottom: 0 !important;
    }
    .contactPanelTitleSec {
      .date-data {
        color: #00041b;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }
      .trip-data {
        p {
          color: #000837;
          font-size: 12px;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 0;
          span {
            color: #001489;
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
    .overviewDetailsCard {
      height: auto !important;
      min-height: 70px;
      .associated-card-table {
        label {
          display: block;
          color: #82869b;
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 6px;
          & + span,
          & + p {
            color: #000837;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0;
          }
        }
        .routesInfo {
          font-size: 14px;
          margin-top: -4px;
        }
        .legs{
            p{
                min-width: 36px;
            }
        }
      }
    }
  }
}


.phone-margin-mobile {
    @media screen and (max-width: 767px) {
        .phone-number-masked-latest .react-tel-input {
            margin-top: -8px;
        }
    }
}