.dashboard-iframe {
    display: block;
    margin: 16px auto;
    width: 100%;
    max-width: 1440px;
    border: none;
}

.dashboardWrapper {
    position: relative;

    .rightTabbedItems {
        width: 28%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        margin-top: calc(-1.5 * 1rem);
    }

    .k-tabstrip>.k-content {
        border: none;
        margin-top: 16px !important;
        background-color: transparent;
        padding: 0;

        .k-animation-container {
            width: 100%;
        }
    }

    .k-tabstrip-items-wrapper {
        border-bottom: 0.5px solid #E6E9F6;
        // background: #F8F8F8;
        box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
        // width: 70%;

        .k-tabstrip-items {
            gap: 24px;
        }

    }

    .k-tabstrip-items-wrapper .k-item {
        color: #82869B;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        height: 44px;
        background-color: transparent;
    }

    .k-tabstrip-items-wrapper .k-item:active,
    .k-tabstrip-items-wrapper .k-item.k-active,
    .k-tabstrip-items-wrapper .k-item.k-selected {
        border-color: transparent;
        border-bottom-width: 2px;
        border-bottom-color: #001489;
        color: #001489;
        font-weight: 700;
    }

    .commonDashboardBox {
        // flex: 1;
        padding: 30px 32px;
        background-color: #fff;
        filter: drop-shadow(0px 4px 30px rgba(127, 135, 146, 0.15));
        border-radius: 4px;

        .boxHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            min-height: 38px;

            h5 {
                color: #00041B;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 2.8px;
                text-transform: uppercase;
            }

            .k-picker {
                width: auto;
            }

            .k-picker-solid {
                background-color: transparent;
            }
        }
    }

    .statusBx {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 30px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        line-height: normal;
    }

    .actionDueBx {
        @extend .statusBx;
        background: #E6E9F6;
        color: #00106E;
    }

    .actionOverdueBx {
        @extend .statusBx;
        background: #FDEBEB;
        color: #D14141;
    }

    .sectionFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        .totalofCount {
            display: inline-flex;
            padding: 6px 8px 5px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 3.437px;
            background: #E5E7F1;
            color: #001489;
            font-size: 11px;
            font-weight: 600;
            line-height: normal;
        }

        .viewAllBtn {
            color: #001489;
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            border: none;
            background: transparent;
        }
    }

    .dahboardRow1 {
        display: flex;
        gap: 40px;

        .userDashboard {
            flex: 1;

            .userDetails {
                display: flex;
                align-items: center;
                gap: 24px;
                margin-bottom: 30px;

                .profileImage {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 1px solid #001489;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .welcomeHeading {
                    display: block;
                    color: #00041B;
                    font-size: 36px;
                    font-weight: 300;
                    line-height: normal;
                }

                .memberSince {
                    display: block;
                    color: #82869B;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: 4px;
                }
            }

            .totalSummaryBox {
                display: flex;
                justify-content: space-evenly;
                gap: 20px;

                .boxDv {
                    display: flex;
                    flex-direction: column;
                    gap: 42px;
                    flex: 1;
                    padding: 20px;
                    border-radius: 16px;
                    background: #E6E9F6;

                    &.warning {
                        background: #FDE9CB;

                        .iconWrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }

                    .circleDv {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        background-color: #00041B;
                        border-radius: 50%;
                        font-size: 20px;
                        color: #fff;
                    }

                    .boxInfo1 {
                        color: #151D48;
                        font-size: 26px;
                        font-weight: 600;
                        line-height: 32px;
                    }

                    .boxInfo2 {
                        color: #00041B;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 24px;
                        margin-top: 6px;
                    }
                }
            }
        }

        .calendarDashboard {
            width: 409px;

            .k-calendar {
                border: none;
                box-shadow: none;
                width: 100%;
            }
        }
    }

    .dahboardRow2 {
        display: flex;
        gap: 40px;
        margin-top: 40px;

        .financeDashboard {
            flex: 1;

            .leaderBoard {
                .k-tabstrip-items-wrapper {
                    width: 70%;

                    .k-item {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .overdueDashboard {
            flex: 1;

            .paymentItems {
                border-radius: 5px;
                border: 0.5px solid rgba(210, 218, 227, 0.75);
                background: #FFF;
                padding: 12px;
                margin-bottom: 10px;

                .paymentInfo1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .operatorNameTitle {
                        color: #001489;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 14px;
                    }

                    .operatorCost {
                        color: #001489;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 14px;
                    }
                }

                .paymentInfo2 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 6px;

                    .operatorNameValue {
                        color: #00041B;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    .operatorDueDate {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: #D14141;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;

                        .calendarIcon {
                            background-image: url('../../Assets/Images/calendarRed.svg');
                        }
                    }
                }
            }
        }

        .openTaskrDashboard {
            width: 409px;

            .taskItems {
                border-radius: 5px;
                border: 0.5px solid rgba(210, 218, 227, 0.75);
                background: #FFF;
                padding: 12px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                .leadText {
                    color: #00041B;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                }

                .taskStatus {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 12px;

                    .leftInfo {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .calendarIcon {
                            width: 13px;
                            height: 13px;
                            background-position: 0 -1px;
                            background-size: 12px;
                        }

                        .dueDate {
                            margin-bottom: 0;
                            color: #00041B;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }

                    .actionDue {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }

                .checkCircleGreyIcon {
                    width: 20px;
                    height: 20px;
                }

            }
        }

        .leaderBoardDashboard {
            flex: 1;
            position: relative;

            .rightTabbedItems {
                width: 20%;
                position: absolute;
                right: 0;
                top: 24px;
                z-index: 1;
                margin-top: calc(-1.5 * 1rem);
            }

            .k-tabstrip-items-wrapper {
                width: 380px;
            }

            .leaderBoard {
                position: relative;

                .leaderBoardSummary {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background-color: #E6E9F6;
                    border-radius: 8px;
                    padding: 20px 16px 16px 16px;
                    margin-bottom: 16px;

                    .dataCol {
                        text-align: center;

                        .dataTitle {
                            font-size: 10px;
                            line-height: 10px;
                            color: #82869B;
                            font-weight: 500;
                        }

                        .dataCount {
                            font-size: 24px;
                            line-height: 32px;
                            color: #151D48;
                            font-weight: 300;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}