// variables
@import "../../Assets/Styles/Variables.scss";
//Variables end

.userDetailsWrapper{
  .tab-content{
    padding: 20px 0;
      .row{
        margin-right: -10px;
        margin-left: -10px;
        .col-md-4{
          padding-left: 10px;
          padding-right: 10px;
        }
      }
  }
}
.userDetailsCardsGroup{
  margin: 0 -10px;
}
.userDetailsCard{
  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(127, 135, 146, 0.15);
  border-radius: 8px;
  padding: 20px;
  border: none;
  margin: 10px;
  display: inline-block;
  width: calc(100% / 3 - 20px);
  vertical-align: top;
  min-height: 180px;
  &:hover {
    background: $white90;
  }
.teamTileSec{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  h4{
    margin: 0;
    display: flex;
    align-items: center;
    // max-width: calc(100% - 66px);
    max-width: calc(100% - 88px);
    strong{
      font-weight: 300;
      font-size: 28px;
      line-height: 28px;
      color: $light-grey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .badge{
      background-color: $primary-dark-blue !important;
      font-weight: 600;
      font-size: 13px;
      padding-top: 5px;
      color: #fff;
      margin-left: 12px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 22px;
    }
  }
  .cardAction{
    display: flex;
    gap: 8px;
    align-items: center;
    .editDetils{
      background-image: url('../../Assets/Images/pencil-blue.svg');
      background-repeat: no-repeat;
      background-position: center left;
      padding-left: 20px;
      background-size: 13px;
    }
  }
}
.aboutUser{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $dark-grey;
  min-height: 44px;
  div{
    display: inline;
    span{
      cursor: pointer;
    }
  }
}
.createdModifiedDate{
  border-top: 1px solid rgba(93, 122, 141, 0.3);
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  label{
    margin: 0;   
    background-image: url('../../Assets/Images/calendar-blank.svg');
    background-repeat: no-repeat;
    background-position: top left;
    padding-left: 20px;
    background-size: 13px;
    font-size: 12px;
    color: #242424;
    font-weight: 500; 
  }
 }
}

.usersCategoryNav{
  // background: #F8F8F8;
  border-bottom: 0.5px solid #E6E9F7;
  box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
  .nav-item{
    .nav-link{
      color: $label-color;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      background: transparent;
      padding: 8px 20px;
      border: none;
      letter-spacing: .3px;
      margin-right: 10px;
      &:focus{
        outline: none;
      }
    }
    .nav-link.active{
      color: $primary-dark-blue;
      border-bottom: 2px solid $primary-dark-blue;
      font-weight: 700;
    }
  }
}
.createNewTeam{
  border: 1px dashed $dark-grey;
  .createTeam{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 137px;
    .btn-link{
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      padding-top: 35px;
      background-image: url('../../Assets/Images/pluse-circle-blue.svg');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 22px;
      &:hover, &:focus{
        text-decoration: none;
        color: $link-hover;
      }
      svg{
        font-size: 26px;
        margin: 0 0 20px 0;
      }
    }
  }
}

.card {
    box-sizing: border-box;
    // height: 180px;
    background: #FFFFFF;
    border: 0.5px solid #D9D9D9;
    padding: 30px;
}
.editIcon {
  background: url("../../Assets/Images/pencil-blue.svg");
}
.deleteIcon {
  background: url("../../Assets/Images/delete-red.svg");
}
.editIcon, .deleteIcon {
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    border: none;
    height: 20px;
    width: 20px;
    padding: 0;
    cursor: pointer;
    font-size: 0;
    text-indent: -999px;
    overflow: hidden;
    &:focus,
    &:hover {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      &::before,
      &::after {
        display: none !important;
      }
    }
}


.editTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 5px;
    gap: 4px;
    height: 18px;
    color: #FFFFFF;
    /* Primary Blue - New */
    background: $primary-light-blue;
    border-radius: 3px;
    span{
      font-size: 11.8px !important;
      font-weight: 600;
      margin-right: 2px;
      line-height: 95.5%;
      padding-top: 2px;
    }
    img{
      width: 7px;
      height: 8px;
    }
}

.title {
    // width: 158px;
    // height: 28px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    /* identical to box height, or 100% */


    color: #00041B;

}

.section {
    width: 352px;
    height: 48px;
    left: 109px;
    // top: 220px;
    margin-top: 18px;
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */


    /* Black */

    color: #00041B;

}

.footer {
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 10px;
    /* identical to box height */
    color: #00041B;

}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 30px;
    margin-top: 20px;
}

.footer-icon {
    left: 33.92%;
    right: 65.73%;
    top: 30.89%;
    bottom: 68.59%;

    border: 2px solid rgba(102, 112, 128, 0.47);
}

.back-role {
    width: 105px;
    height: 20px;
    left: 12px;
    top: 0px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */


    color: #2D3F59;
}

.type-role {
    width: 154px;
    height: 28px;
    left: 643px;
    top: 106px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    /* identical to box height, or 100% */


    /* common/text/default */

    color: #667080;
}

.create-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    height: 180px;


    /* common/surface/navigation/default */

    background: #FFFFFF;
    /* Black */

    border: 1px dashed #00041B;
    border-radius: 8px;
    

    .create-add-icon {
        font-family: 'League Spartan';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        margin-top: 20;
        display: flex;
        align-items: center;
        text-align: center;

        /* Primary Blue - Dark */

        color: #00106E;
    }
}

.perpendicular-line {
    width: 100%;
    margin: auto;
    margin-top: 4%;
    margin-bottom: 0;
    transform: rotate(0deg);
    border-color: rgba(93, 122, 141, 0.3);
    background: rgba(93, 122, 141, 0.3);
}
.showMob{
  display: none !important;
}

// All Users Table
.dataListViewWrapper {
  .allUserDataTable{
      table {
        colgroup{
          col:nth-child(2){width: 17%;}
          col:nth-child(3){width: 21%;}
          col:nth-child(4){width: 24%;}
          col:nth-child(5){width: 13%;}
          col:nth-child(6){width: 80px;}
          col:nth-child(7){width: 55px;}
          col:last-child{width: 55px;}
        }
        tr {
          th:nth-child(3),
          th:nth-child(4),
          th:nth-child(5),
          th:nth-child(8) {
            .k-link{
              cursor: default;
              &:after {
                display: none !important;
              }
            }
          }
        }
    }
  }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
  .userDetailsCardsGroup {
    margin: 10px 0;
    .userDetailsCard {
      width: 100%;
      min-height: inherit;
      margin: 10px 0;
      .teamTileSec{
        padding-bottom: 15px;
        h4 strong {
          font-size: 24px;
        }
      }
      .aboutUser{
        display: none;
      }
      .createdModifiedDate{
        margin-top: 0;
        label{
          span{
            display: none;
          }
        }
      }
    }
    .createNewTeam{
      .createTeam{
        .btn-link {
          font-size: 16px;
          background-position: center left;
          background-size: 22px;
          padding-top: 0;
          padding-left: 30px;
        }  
      }                             
    }    
  }
  .hideMob{
    display: none !important;
  }
  .showMob{
    display: flex !important;
  }

// All UserData Table
  .k-grid.allUserDataTable {
    table {     
        min-width: 100% !important;
        col:nth-child(3), th:nth-child(3), td:nth-child(3),
        col:nth-child(5), th:nth-child(5), td:nth-child(5),
        col:nth-child(6), th:nth-child(6), td:nth-child(6),
        col:nth-child(7), th:nth-child(7), td:nth-child(7) 
        {display: none !important;} 

    colgroup {
        col:nth-child(1) {width: 35px !important;}
        col:nth-child(2) {width: 40% !important;}
        col:nth-child(4) {width: 60% !important;}
        col:last-child {width: 70px !important;}
    }
    .tableDetailsData{
      .statusColSec{
        display: flex;
        align-items: flex-start;
        .statusIcon {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          display: inline-block;
          margin-top: 4px;
          margin-right: 8px;
        }
      }
    }
  }       
}


}

@media screen and (max-width: 650px) {
  .createNewTeam{
    .createTeam {
      min-height: 40px;
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 991px){ 
  .userDetailsCardsGroup {
      margin: 10px -10px;
      padding: 0 5px;
    .userDetailsCard {
      min-height: inherit;
      margin: 8px;
      width: calc(50% - 16px);
    }
    .createNewTeam {
      .createTeam {
        min-height: 75px;
      }
    }
    
  }
  .hideMob{
    display: none !important;
  }
  .showMob{
    display: flex !important;
  }
}



