@charset "UTF-8";
// variables
@import "../Styles/Variables.scss";
//Variables end
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  padding: 0;
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

body {
  font-family: 'League Spartan', sans-serif;
  padding-top: 70px;
  background: #F3F3F3;
}

// body {
//   scrollbar-width: thin !important;
//   scrollbar-color: #65676a #1e1f22 !important;
// }
a {
  text-decoration: none !important;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.k-icon {
  font-family: "WebComponentsIcons";
}

.fontLeagueSpartan {
  font-family: $leagueSpartanFont !important;
}

.text-danger {
  color: $error-color;
}

.text-primary {
  color: $primary-light-blue !important;
}

.modal-open {
  overflow: hidden;
  padding-right: 17px;
}

.maverickFormCard {
  background: $white-color;
  box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);
  border-radius: 10px;
  padding: 30px;

  .maverickForm {
    .formInfoView {
      padding-top: 30px;

      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: $label-color;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $dark-grey;
      }
    }
  }
}

.dropdown-item {
  cursor: pointer;
}

.k-button-solid-primary,
.k-button-outline-primary {
  font-weight: 700;
  font-size: 14px;
  //line-height: 16px;
  border: 2px solid $primary-dark-blue;
  padding: 10px 15px;
  border-radius: 4px;
  letter-spacing: .5px;
  transition: 300ms ease all;
  // min-height: 40px;
}

.k-button-solid-primary {
  background-color: $primary-dark-blue;
  border-width: 1px;

  &:hover,
  &:focus,
  &:active {
    background-color: $primary-light-blue;
    border-color: $primary-light-blue;
    color: $white-color;
    outline: none;
  }

  &:focus,
  &:active {
    border-color: $white-color;
    box-shadow: 0px 0px 3px 2px rgba(0, 20, 137, 0.7);
  }
}

.k-button-solid-error:focus,
.k-button-solid-error.k-focus {
  box-shadow: 0px 0px 3px 2px rgba(220, 53, 69, 0.7);
  border: 1px solid $white-color;
}

.k-button-outline-primary {
  color: $primary-dark-blue;

  &:hover,
  &:focus,
  &:active {
    background-color: #DDE1F6;
    border-color: $primary-dark-blue;
    color: $primary-dark-blue;
    outline: none;
  }

  &:focus,
  &:active {
    box-shadow: 0px 0px 3px 2px rgba(0, 20, 137, 0.7);
  }

}

.k-button-flat-primary {
  color: $primary-dark-blue;
  border: none;

  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    color: $link-hover;

    &::after,
    &::before {
      display: none !important;
    }
  }
}

.btn-outline-secondary {
  border: 2px solid #919AA9;
  border-radius: 4px;
  color: $grey-secondary-button-color;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: $grey-secondary-button-color;
    border: 2px solid $grey-secondary-button-color;
    color: $white-color;
    box-shadow: none;
    outline: none;
  }
}

.btn-secondary {
  color: $grey-secondary-button-color;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.whiteEditIconBtn {
  background-image: url('../../Assets/Images/pencil-white.svg');
}

.whiteAddIconBtn {
  background-image: url('../../Assets/Images/pluse-white.svg');
}

.whiteEditIconBtn,
.whiteAddIconBtn {
  background-repeat: no-repeat;
  background-position: center left 15px;
  background-size: 14px;
  padding-left: 38px;
}

.editIconBtn {
  background: url('../../Assets/Images/pencil-blue.svg') !important;
  background-size: 18px !important;
}

.editIconBtn.k-disabled {
  background: url('../../Assets/Images/pencil-grey.svg') !important;
  background-size: 18px !important;
  opacity: 1;
}

.deleteIconBtn {
  background: url('../../Assets/Images/delete-blue.svg') !important;
  background-size: 18px !important;
}

.deleteIconBtn.k-disabled {
  background: url('../../Assets/Images/delete-grey.svg') !important;
  opacity: 1;
}

.clockRotateIconBtn {
  background: url('../../Assets/Images/clock-rotate-icon.svg') !important;
  background-size: 18px !important;
}

.uilFileIconBtn{
  background: url('../../Assets/Images/uil-file-contract.svg') !important;
  background-size: 20px !important;
  background-repeat: no-repeat;
}

.FlightAwareURL{
  background: url('../../Assets/Images/FlightAwareURL.svg') !important;
  background-size: 23px !important;
  background-repeat: no-repeat;
  height: 24px;
  background-position: 9px 5px;
}
.viewIcon{
  background: url('../../Assets/Images/view.svg') !important;
  background-size: 23px !important;
  background-repeat: no-repeat;
  height: 24px;
  background-position: 9px 5px;
}
.associatedTripIconBtn {
  background: url('../../Assets/Images/associated-trip.svg') !important;
  background-size: 24px !important;
}

.doNotUse{
  background-image: url('../../Assets/Images/do-not-use.svg') !important;
  background-repeat: no-repeat !important;
  background-image: 15px !important;
  background-position: center;
}

.approved{
  background-image: url('../../Assets/Images/approved.svg') !important;
  background-repeat: no-repeat !important;
  background-image: 15px !important;
  background-position: center;
}

.editIconBtn,
.editIconBtn.k-disabled,
.deleteIconBtn,
.deleteIconBtn.k-disabled,
.clockRotateIconBtn,
.uilFileIconBtn,
.FlightAwareURL,
.viewIcon,
.associatedTripIconBtn {
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 32px;
  height: 32px;
  display: inline-block;
  padding: 0;
}

.moreIconDropdownBtn {

  //display: flex;
  .k-button {
    background: url('../../Assets/Images/moreDots.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 24px !important;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 0;

    &:focus,
    &:hover {
      background-color: transparent !important;
      border: 1px solid $primary-light-blue !important;
    }
  }
}

.btn-link {
  color: $primary-dark-blue;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color: $link-hover;
  }
}

.add-btn-link {
  background-image: url('../../Assets/Images/pluse-circle-blue.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 18px;
  padding-left: 25px;
}

.edit-btn-link {
  background-image: url('../../Assets/Images/pencil-blue.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 15px;
  padding-left: 20px;
}

.delete-btn-link {
  background-image: url('../../Assets/Images/delete-red.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  padding-left: 24px;
  background-color: transparent;
  border: none;
  @extend .text-danger;

  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;

    &::after,
    &::before {
      display: none !important;
    }
  }
}

.customBadge {
  background: #E1E4F3;
  border-radius: 100px;
  font-weight: 600;
  font-size: 10px;
  line-height: 9px;
  color: $primary-dark-blue;
  padding: 5px 10px 4px 10px;
  margin-left: 5px;
}

.loader {
  width: 24px;
  height: 24px;
  border: 2px solid #d9dff6;
  border-bottom-color: $primary-dark-blue;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.topTitleSec {
  padding: 10px 0 15px 0;

  .row {
    align-items: center;
  }

  .title_backBtn {
    .backBtn {
      color: $dark-grey;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      //display: flex;
      display: inline;
      align-items: center;
      margin-bottom: 5px;
      background-image: url('../../Assets/Images/right-angle-grey.svg');
      background-repeat: no-repeat;
      background-position: top 1px left;
      background-size: 7px;
      padding: 0 0 0 17px;
      cursor: pointer;

      span {
        // padding-left: 5px;
        padding-left: 2px;
      }

      &:before,
      &:after {
        display: none;
        background-color: none;
        opacity: 0;
      }
    }

    h1 {
      font-weight: 300;
      font-size: 32px;
      color: $primary-dark-blue;
      margin-bottom: 0;
    }
  }

  .rightBtnGroup {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    // .k-button-outline-primary {
    //   margin-right: 15px;
    // }
    .k-button-solid-primary,
    .k-button-outline-primary {
      min-height: 40px;
    }
  }
}

.formWrapper {
  max-width: 900px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.pin-note {
  display: flex;
  align-items: center;
  height: 25px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #00041B;
    margin-top: 18px;
    margin-left: 13px;
  }

  .pin-note-icon {
    width: 25px;
    height: 100%;
    cursor: pointer;
    background: #FFFFFF;
    border: 1.2px solid #B4B6C3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.maverickFormM{

  max-width: 730px !important;
}
.maverickForm {
  max-width: 678px;
  margin: 0 auto;

  .formTitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 22px;
    color: $dark-grey;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .form-group {
    margin-bottom: 0;

    .private-note-text {
      font-weight: 400;
      font-size: 16px;
      color: #00041B;
      margin-left: 10px;
    }

    .k-floating-label-container {
      width: 100%;
      display: flex;
    }

    .k-text-disabled {
      cursor: not-allowed;

      .customInput {
        background: rgba(242, 244, 246, 0.8);
      }
    }

    .k-floating-label-container.k-empty>.k-label {
      color: $label-color;
      font-weight: 400;
      font-size: 16px;
      position: absolute;
      pointer-events: none;
      left: 14px !important;
      right: 14px;
      top: 38px;
      transition: 300ms ease all;
      margin: 0;
      letter-spacing: 0.4px;
      width: calc(100% - 28px);
      display: flex;
      align-items: center;
      max-width: inherit;
    }

    .k-floating-label-container>.k-label,
    .k-floating-label-container.k-focus>.k-label {
      letter-spacing: 0.4px;
      left: 14px;
      top: 30px;
      font-size: 12px;
      color: $lighter-grey;
      display: flex;
      align-items: center;
      right: 14px;
      max-width: inherit;
    }

    .k-floating-label-container .k-textarea:valid~.k-label {
      top: 30px;
    }

    .k-floating-label-container .k-multiselect {
      z-index: 2;
      background: transparent;
      height: auto;
      min-height: 52px;
    }

    .k-floating-label-container .k-multiselect.k-empty~.k-label {
      color: $label-color;
      font-size: 16px;
      top: 38px;
    }

    .k-floating-label-container .k-multiselecttree.k-empty~.k-label {
      color: $label-color;
      font-size: 16px;
      top: 38px;
    }

    .k-floating-label-container>.k-multiselect:has(.k-chip-list:empty)~.k-label {
      // background-color: $deep-blue !important;
      top: 40px !important;
      z-index: 0;
      font-size: 16px;
    }

    .customInput {
      background: $white-color;
      border-radius: 4px;
      color: $dark-grey;
      //padding: 18px 14px 4px 14px;
      padding: 18px 14px 0px 14px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.3px;
      height: 52px;
      font-family: $leagueSpartanFont;

      .k-input-value-text {
        max-width: calc(100% - 15px);
      }

      &:focus,
      &:focus-within {
        box-shadow: none;
        border-color: $primary-light-blue;
      }
    }

    .customInput.k-invalid-error {
      background: #FFF7F7;
      border-color: $error-color;
    }

    .optionalField+label,
    .optionalField+.k-label {
      &:after {
        content: 'Optional';
        font-weight: 400;
        font-size: 10px;
        line-height: 9px;
        color: rgba(111, 116, 142, 0.8);
        position: absolute;
        right: 0;
      }
    }

    .k-dropdownlist.optionalField+label,
    .k-dropdownlist.optionalField+.k-label,
    .k-datepicker.optionalField+.k-label,
    .k-floating-label-container.optionalField input[type="number"]+.k-label {
      &:after {
        content: "(optional)";
        position: initial;
        margin-left: 5px;
      }
    }

    .k-floating-label-container input[type="number"]:after,
    .k-floating-label-container input[type="number"]:before {
      position: absolute;
      right: 5px;
      top: 20px;
    }


    .customInput.k-dropdownlist+.k-label {
      max-width: calc(100% - 50px);
    }

    .k-input-md .k-input-inner,
    .k-picker-md .k-input-inner {
      padding: 0;
    }

    .k-button,
    .k-clear-value {
      background-image: url('../../Assets/Images/down-angle-grey.svg');
      background-repeat: no-repeat;
      transform: rotate(360deg);
      background-position: center;
      background-size: 14px;
      position: absolute;
      right: 4px;
      top: 10px;
      height: 30px;
      width: 30px;
      background-color: inherit;
      z-index: 9;
      border: none;

      .k-svg-icon,
      .k-i-caret-alt-down,
      .k-icon {
        display: none;
      }
    }

    .k-clear-value {
      background-image: url('../../Assets/Images/close-blue.svg');
      right: 28px;
      z-index: 99;
      background-size: 9px;
      opacity: .7;
    }

    span[aria-expanded="true"] .k-button {
      transform: rotate(180deg);
    }

    .k-focus .k-clear-value {
      transform: rotate(180deg);
    }

    .k-textarea.customInput.customSign {
      textarea.k-input-inner {
        min-height: 90px;
        margin: 0px -14px -4px -14px;
        padding: 3px 10px 10px 14px;
        border-radius: 4px;
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        font-family: $AlluraFont;
        font-size: 26px;
      }
    }

    .k-textarea.customInput {
      height: auto;

      textarea.k-input-inner {
        min-height: 90px;
        margin: 0px -14px -4px -14px;
        padding: 5px 10px 10px 14px;
        border-radius: 4px;
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        font-family: $leagueSpartanFont;
        scrollbar-color: #cdcdcd #fff;
        font-size: 16px;
      }

      textarea.k-input-inner::-webkit-scrollbar {
        width: 6px;
      }

      textarea.k-input-inner::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
        border-radius: 20px;
      }

      textarea#note-id {
        min-height: 90px;
        margin: 0px -14px -4px -14px;
        padding: 3px 10px 10px 14px;
        border-radius: 4px;
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        scrollbar-color: #cdcdcd #fff;
        font-family: "League Spartan";
        font-size: 16px;
      }

      textarea#note-id::-webkit-scrollbar {
        width: 6px;
      }

      textarea#note-id::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
        border-radius: 20px;
      }
    }

    .k-textarea.customInput~.k-label {
      background: $white-color;
      z-index: 9;
      //width: 100%;
    }

    .k-floating-label-container.k-empty {
      .k-textarea.customInput {
        textarea.k-input-inner {
          margin-top: -18px;
          padding-top: 18px;
          min-height: 108px;
        }
      }
    }

    .text-danger {
      padding-top: 5px;
      display: block;
      font-weight: 400;
      font-size: 12px;
    }

    .datePicker.customInput .k-button,
    .timePicker.customInput .k-button {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      position: absolute;
      right: 8px;
      top: 10px;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: none;
      z-index: 99;
      padding: 0;

      .k-svg-icon{
        display: none !important;
      }

      .k-icon:before {
        display: none;
      }
    }

    .datePicker.customInput .k-button {
      background-image: url('../../Assets/Images/calender-grey.svg');
      transform: none;
    }

    .timePicker.customInput .k-button {
      background-image: url('../../Assets/Images/clock-grey.svg');
      transform: none;
    }

  }

  .loadedActive {
    position: relative;

    .loader {
      position: absolute;
      right: 15px;
      top: 38px;
    }
  }

  .k-dateinput {
    .k-input-inner {
      font-family: $leagueSpartanFont;
      font-weight: 400;
      font-size: 16px;
      color: $dark-grey;
      // text-transform: uppercase;
    }
  }

  .k-multiselect[aria-expanded="true"] {
    background-image: url('../../Assets/Images/up-angle-grey.svg') !important;
  }

  // Multiselect box
  .k-multiselect,
  .k-multiselecttree {
    background-image: url('../../Assets/Images/down-angle-grey.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center right 12px !important;
    background-size: 14px !important;

    .k-selection-multiple,
    .k-chip-list {
      padding: 0;
      gap: 4px;
      padding: 5px 0 5px 0;
      max-width: calc(100% - 30px);

      .k-chip-solid-base {
        border: none;
        color: $deep-blue;
        background-color: #E6E9F7;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        border-radius: 100px;
        letter-spacing: 0;
        padding: 7px 9px;

        .k-chip-content {
          padding: 0;
          margin-left: 0;
        }

        .k-chip-actions {
          margin: 0;

          .k-chip-remove-action {
            .k-i-x-circle {
              background-image: url('../../Assets/Images/close-blue.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              height: 6.3px;
              width: 6.3px;
              margin-left: 2px;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }

    .k-clear-value {
      display: none;
    }
  }

  .hideRemoveIcon {
    .k-selection-multiple {
      .k-chip-solid-base {
        .k-chip-actions {
          display: none;
        }
      }
    }
  }

  .rowActionBtn {
    position: absolute;
    left: calc(100% + 10px);
    top: 25px;
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0;
    width: auto;

    .permissionBtn {
      background-image: url('../../Assets/Images/permission-blue.svg');
    }

    .deleteBtn {
      background-image: url('../../Assets/Images/delete-blue.svg');
    }

    .iconBtn {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      font-size: 0px;
      width: 30px;
      height: 30px;
      padding: 0;

      &:focus,
      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .addRoleBtn {
    display: flex;
    margin-top: 10px;
    margin-bottom: -5px;
    justify-content: flex-end;
    gap: 16px;

    .btn-link {
      background-image: url('../../Assets/Images/pluse-circle-blue.svg');
      background-repeat: no-repeat;
      background-position: left;
      // background-size: 18px;
      // padding-left: 25px;
      // padding-top: 5px;
      background-size: 16px;
      padding-left: 20px;
      padding-top: 3px;
    }

    .btn-copy {
      background-image: url('../../Assets/Images/copy.svg');
      background-repeat: no-repeat;
      background-position: left;
      background-size: 14px;
      padding-left: 20px;
      padding-top: 3px;
    }
  }

  .removeTeamBtn {
    margin-bottom: -5px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;

    .btn-link {
      background-image: url('../../Assets/Images/delete-blue.svg');
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 14px;
      padding-left: 22px;
    }
  }

  .addMemberGroup {
    border-bottom: 1px solid #E6E9F7;
    padding-bottom: 25px;

    .actionBtnSpace {
      position: relative;
    }
  }

  .formBottomBtns {
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    .k-button-outline-primary {
      display: flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;

      // &:hover, &:focus{
      //   .k-button-text{
      //   background-image: url('../../Assets/Images/pluse-circle-white.svg');
      //   }
      // }
      .k-button-text {
        display: flex;
        align-items: center;
        background-image: url('../../Assets/Images/pluse-circle-blue.svg');
        background-repeat: no-repeat;
        // background-position: center left;
        background-position: top 1px left;
        background-size: 18px;
        padding-left: 25px;
      }

      svg {
        margin-right: 7px;
        font-size: 18px;
      }
    }
  }
}

.checkboxList {
  span {
    margin-right: 20px;

    .k-checkbox-label {
      margin-left: 10px;
    }
  }
}

.k-checkbox {
  border: 2px solid $primary-light-blue;
  border-radius: 3px;
  width: 16px;
  height: 16px;

  &:checked {
    background-color: $primary-light-blue;
    box-shadow: none;
    border-color: $primary-light-blue;

    &:focus {
      border-color: $primary-light-blue;
      box-shadow: none;
    }
  }

  &:focus {
    border-color: $primary-light-blue;
    box-shadow: none;
  }

  &:checked {

    &:disabled {
      background-color: $light-grey ;
      box-shadow: none;
      border-color: $light-grey;
    }
  }
  &:indeterminate, &.k-indeterminate{
    border-color: #001489;
    background-color: #001489;
  }
  &:disabled {
    box-shadow: none;
    background-color: rgba(180, 182, 195, 0.40) ;
    border-color: $light-grey;
  }
  & + .k-checkbox-label{
    padding-top: 3px;
    display: inline-block !important;
  }
}

.k-radio {
  width: 16px;
  height: 16px;
  background: $white-color;
  border: 2px solid $primary-light-blue;

  &:focus {
    border-color: $primary-light-blue;
    box-shadow: none;
  }

  &:disabled,
  &.k-disabled {
    border: 2px solid #B4B6C3;
  }

  &:checked {
    border-color: $primary-light-blue;
    background-color: $white-color;
    background-image: url('../../Assets/Images/blue-circle.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px;

    &:focus {
      border-color: $primary-light-blue;
      box-shadow: none;
    }

    &:disabled {
      border-color: #B4B6C3;
      background-color: $white-color;
      background-image: url('../../Assets/Images/grey-circle.svg') !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8px;

      &:focus {
        border-color: #B4B6C3;
        box-shadow: none;
      }
    }
  }

}

.k-radio+.k-radio-label {
  font-weight: 400;
  font-size: 16px;
  color: $dark-grey;
  margin-left: 5px;
  display: inline-flex;
  margin-top: 4px;
}

.dataListViewWrapper {

  //Filter css
  .dataGridFilterSec {
    background: $white-color;
    box-shadow: 0px 4px 30px rgba(211, 211, 211, 0.25);
    border-radius: 5px;
    padding: 17px 25px;
    margin: 5px 0 10px 0;
    position: relative;
    z-index: 9;
    transition: 300ms ease all;

    .filteredBtnData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .filterLeftSec {
        display: flex;
        align-items: center;
        max-width: calc(100% - 150px);

        .filtersBtn {
          background: url('../../Assets/Images/filter-icon.svg') center left no-repeat !important;
          background-size: 20px !important;
          color: $primary-dark-blue;
          font-weight: 600;
          font-size: 14px;
          padding-left: 26px;
          letter-spacing: .3px;

          &:hover,
          &:focus {

            &:after,
            &:before {
              display: none !important;
            }
          }

          .k-button-text {
            img {
              max-width: 9px;
              margin-left: 3px;
            }
          }
        }
      }

      .filterRightBtnSec {
        display: flex;
        gap: 5px;

        .btn-outline-secondary,
        .btn-secondary {
          font-size: 12px;
          padding: 4px 15px;
          min-width: 75px;
          @media screen and (max-width: 767px) {
            min-width: 50px;
            padding: 4px 6px;
          }
        }
      }

      .k-button-flat {
        color: $grey-secondary-button-color;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
      }
    }

    .filterSelectedData {
      padding-left: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 100%;
      overflow: auto;
      -ms-overflow-style: thin;
      scrollbar-width: thin;

      .selectedFilter {
        display: flex;
        align-items: center;
        background: #E6E9F7;
        border-radius: 100px;
        padding: 6px 12px;
        gap: 4px;
        color: $deep-blue;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;

        span {
          font-weight: 400;
        }

        strong {
          font-weight: 600;
        }

        .deleteFilterData {
          background: url('../../Assets/Images/close-blue.svg') center no-repeat !important;
          background-size: cover !important;
          padding: 0;
          width: 11px;
          height: 11px;
          display: inline-block;
          margin-left: 5px;

          &:hover,
          &:focus {

            &:after,
            &:before {
              display: none !important;
            }
          }
        }
      }
    }
    .checkBoxRow{
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 16px;
      .checkBoxTitle{
        line-height: 28px;
      }
    }
  }

  .k-button-outline-primary {
    padding: 8px 20px;
  }

  .maverickForm {
    max-width: 100%;
    margin-top: -10px;
    padding-bottom: 5px;
  }

}

//Table css
.k-grid {
  border: none;
  background-color: transparent;

  .k-table-thead,
  .k-table-header,
  .k-table-group-sticky-header {
    border-color: transparent;
    background-color: transparent;
  }

  .k-grid-content {
    background-color: transparent;
    overflow-y: hidden;

    .k-loading-mask {
      .k-loading-image::before {
        width: 0.8em;
        height: 0.8em;
        margin-top: -0.38em;
      }
    }

    .tableDetailsData {
      padding: 20px 20px 20px 8px;
    }

    .k-detail-row {
      background: rgba(216, 219, 234, 0.15);
      box-shadow: 0px 4px 10px rgba(169, 170, 181, 0.25);
      border-radius: 5px;
    }

    .k-master-row {
      .k-hierarchy-cell {
        padding-left: 0 !important;
        padding-right: 0;

        &:empty {
          display: none;
        }

        .k-i-plus {
          background-image: url('../../Assets/Images/down-angle-blue.svg');
        }

        .k-i-minus {
          background-image: url('../../Assets/Images/up-angle-blue.svg');
        }

        .k-icon {
          background-repeat: no-repeat;
          background-position: center;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  table {
    border-spacing: 0 .6em;
    min-width: 1200px;
    background-color: transparent;
  }

  colgroup col.k-sorted {
    background-color: transparent;
  }
}

.contactListTbl {
  table {
    colgroup {
      col:nth-child(1) {
        width: 40px;
      }

      col:nth-child(2) {
        width: 8%;
      }

      col:nth-child(3) {
        width: 14%;
      }

      col:nth-child(4) {
        width: 17%;
      }

      col:nth-child(5) {
        width: 12%;
      }

      col:nth-child(6) {
        width: 14%;
      }

      col:nth-child(7) {
        width: 90px;
      }

      col:nth-child(8) {
        width: 90px;
      }

      col:nth-child(9) {
        width: 100px;
      }

      col:last-child {
        width: 70px;
      }
    }

    tr {

      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(5),
      th:nth-child(6),
      th:nth-child(10) {
        .k-link {
          cursor: default;

          &:after {
            display: none !important;
          }
        }
      }
    }

    .k-master-row td:nth-child(2) {
      padding-left: 8px !important;
    }
  }
}

@media screen and (max-width: 767px) {

  // All UserData Table
  .k-grid.contactListTbl {
    table {
      min-width: 100% !important;

      //col:nth-child(1), th:nth-child(1), .k-master-row td:nth-child(1),
      col:nth-child(2),
      th:nth-child(2),
      .k-master-row td:nth-child(2),
      //col:nth-child(3), th:nth-child(3), .k-master-row td:nth-child(3),
      col:nth-child(5),
      th:nth-child(5),
      .k-master-row td:nth-child(5),
      col:nth-child(6),
      th:nth-child(6),
      .k-master-row td:nth-child(6),
      col:nth-child(7),
      th:nth-child(7),
      .k-master-row td:nth-child(7),
      col:nth-child(8),
      th:nth-child(8),
      .k-master-row td:nth-child(8),
      col:nth-child(9),
      th:nth-child(9),
      .k-master-row td:nth-child(9) {
        display: none !important;
      }

      colgroup {
        col:nth-child(1) {
          width: 35px !important;
        }

        col:nth-child(3) {
          width: 35% !important;
        }

        col:nth-child(4) {
          width: 50% !important;
        }

        col:last-child {
          width: 80px !important;
        }
      }
    }
  }
}

.k-grid-header {
  background: #f3f3f3;
  border: none;
  margin-bottom: -8px;
  padding-right: 0;

  .k-grid-header-wrap {
    border: none;
  }

  .k-header {
    border: none !important;
    padding-top: 0;
    padding-bottom: 0;
    overflow: inherit;

    .text-center .k-checkbox {
      margin-bottom: -8px;
    }

    .k-cell-inner {
      margin-top: 0;
      margin-bottom: 0;
    }

    .k-link {
      padding-top: 0;
      padding-bottom: 0;

      &:after {
        background-image: url('../../Assets/Images/shortIcon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
        margin: 0 0 0 6px;
        width: 1em;
        height: 1em;
        content: '';
      }

      .k-column-title {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .k-column-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      color: #919AA9;
      letter-spacing: .3px;
      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }

    &:last-child {
      .k-column-title {
        // margin: 0 auto;
        padding-right: 10px;
      }
    }

    &:last-child {
      .k-cell-inner {
        .k-link {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .k-header.k-sorted,
  .k-header.k-sorted[aria-sort="ascending"] {
    .k-link:after {
      background-image: url('../../Assets/Images/shortAscendingIcon.svg');
    }
  }

  .k-header.k-sorted[aria-sort="descending"] {
    .k-link:after {
      background-image: url('../../Assets/Images/shortDescendingIcon.svg');
    }
  }

  th.k-sorted {
    background-color: transparent;

    .k-icon {
      display: none;
    }

    .k-i-sort-asc-small {
      color: $primary-light-blue;
    }
  }
}

.k-master-row {

  //background-color: $white-color;
  td {
    color: $dark-grey;
    font-weight: 400;
    //padding: 12px;
    //padding: 12px 8px;
    padding: 12px 8px 10px 8px;
    font-size: 14px;
    line-height: 18px;
    border-left: none;
    border-right: none;
    border-top: 1px solid $grey-light-border;
    border-bottom: 1px solid $grey-light-border;
    background-color: $white-color;

    &:first-child {
      border-left: 1px solid $grey-light-border;
      border-radius: 5px 0 0 5px;
      text-align: center;
    }

    &:last-child {
      border-right: 1px solid $grey-light-border;
      border-radius: 0 5px 5px 0;
      text-align: right;

      .gridActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .editIconBtn,
    .deleteIconBtn,
    .clockRotateIconBtn,
    .uilFileIconBtn,
    .FlightAwareURL,
    .viewIcon,
.associatedTripIconBtn {

      &:focus,
      &:hover {
        background-color: transparent !important;
        border: 1px solid $primary-light-blue !important;
      }
    }

    .statusColSec {
      display: flex;
      align-items: center;

      .statusIcon {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        display: inline-block;
        margin-top: -2px;
      }

      label {
        padding-left: 5px;
      }
    }

    .k-checkbox {
      margin-top: -4px;
    }
  }

  td.nameCol,
  td.linkCol {
    color: $primary-light-blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  &:hover {
    td {
      background: #F7F8F9 !important;
    }
  }

  &:last-child td {
    border-bottom-width: 1px !important;
  }
}

.k-grid .k-alt,
.k-grid tbody>tr:not(.k-detail-row):hover,
.k-grid tbody>tr:not(.k-detail-row).k-hover {
  background-color: transparent;
}

.k-grid .k-alt td,
.k-grid tbody>tr:not(.k-detail-row):hover td,
.k-grid tbody>tr:not(.k-detail-row).k-hover td {
  background-color: transparent;
}

.disableRow td {
  background: #F7F8F9 !important;
}

tr.k-alt {
  background-color: transparent;
}
.k-pager,
.k-grid-pager {
  border: none;
  background: transparent;
  //padding: 10px 0;
  padding: 5px 0;
  flex-direction: row-reverse;
.k-pager-sizes{
   .k-dropdownlist{
    width: 4.2em;
  }
  .k-picker-md .k-input-inner{
    padding: 0 6px;
    .k-input-value-text{
      font-size: 14px;
    }
  }
}
  .k-pager-info {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #7D8A9A;
    display: inline-block;
    text-align: left;

    &:before {
      content: 'Showing';
      padding-right: 4px;
    }
  }

  .k-link,
  .k-button {
    border-radius: 2.66176px;
    border: 1px solid #DFE3E8 !important;
    color: $primary-light-blue;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    background: $white-color !important;
    margin-left: 5px;
    width: 28px;
    height: 28px;
    min-width: inherit;
    padding: 0;

    // padding-top: 2px;
    &:focus {
      box-shadow: none;
    }
  }

  .k-selected {
    background: $white-color !important;
    border-color: #3C4456 !important;
    color: #3C4456 !important;

    &:before,
    &::after {
      display: none !important;
    }
  }

  .k-pager-numbers {
    .k-link {
      padding-top: 7px;
    }
  }

  .k-pager-first,
  .k-pager-last {
    //display: none;
    color: #C4CDD5;
  }

  .k-pager-nav {
    &:focus {
      box-shadow: none;
    }

    .k-i-caret-alt-left:before {
      background: url('../../Assets/Images/pageArrowLeft.svg');
    }

    .k-i-caret-alt-right:before {
      background: url('../../Assets/Images/pageArrowRight.svg') center no-repeat;
    }

    .k-i-caret-alt-right:before,
    .k-i-caret-alt-left:before {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 7px;
      content: "";
      width: 12px;
      height: 12px;
    }
  }

  .k-pager-nav.k-disabled {
    background: #919EAB !important;
    border-color: #919EAB !important;

    &:focus {
      box-shadow: none;
    }
  }
}

// Accordion UI
.permissionsList {
  padding-top: 5px;

  .k-expander {
    border-color: transparent;
    background: #f9fafc;
    border-radius: 4px;
    margin-top: 15px;

    .k-expander-header {
      background: rgba(238, 240, 248, 0.85);
      border-radius: 4px;
      padding: 10px 15px 10px 20px;
      min-height: 64px;

      .k-expander-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $dark-blue;
        text-transform: inherit;

        .permissionsListHead {
          display: flex;
          align-items: center;

          label {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .k-expander-indicator {
        .k-icon {
          color: $deep-blue;
        }
      }
    }

    .k-checkbox-md {
      width: 16px;
      height: 16px;
      border: 2px solid $primary-light-blue;
      border-radius: 2px;
      margin-top: -3px;
      margin-right: 10px;

      &:checked {
        background-color: $primary-light-blue;
      }
    }

    .k-checkbox.k-indeterminate {
      border-color: $primary-light-blue;
      color: $white-color;
      background-color: $primary-light-blue;
    }
  }

  .k-expander.k-disabled {

    .k-checkbox-md:checked,
    .k-checkbox.k-indeterminate {
      background-color: #B4B6C3;
    }

    .k-checkbox-md {
      border: 2px solid #B4B6C3;
    }
  }

  .k-expander.k-focus {
    box-shadow: none;
  }

  .k-expander-content {
    padding: 20px;

    .permissionsCatSec {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
        line-height: 17px;
        color: #000000;
      }

      .permissionCatLists {
        .catCol {
          background: $white-color;
          border: 0.5px solid $grey-light-border;
          border-radius: 5px;
          height: 62px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          position: relative;
          margin: 11px 0;

          label {
            color: $primary-light-blue;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding-right: 20px;
          }

          .catActionSec {
            position: absolute;
            right: 8px;

            .moreIconDropdownBtn {
              .k-button {

                &:focus,
                &:hover {
                  background-color: transparent !important;
                  border: none !important;

                  &::before,
                  &::after {
                    display: none !important;
                  }
                }
              }
            }

            .actionsList {
              background: $white-color;
              border: 1px solid $grey-light-border;
              box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
              border-radius: 5px;
              padding: 20px;
              display: none;
            }
          }
        }
      }
    }

    .permissionSubLevelSec {
      padding-bottom: 30px;

      &:last-child {
        padding-bottom: 5px;
      }

      .subLevelHead {
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        color: $dark-blue;

        .k-checkbox-label {
          margin-inline-start: 0;
        }
      }

      .selectPermissionGroup {
        padding-left: 15px;

        .row .col-lg-6 {
          padding-left: 25px;
        }

        .fieldLabelGrey {
          font-weight: 500;
          font-size: 12px;
          line-height: 11px;
          color: $label-color;
          margin: 30px 0 8px 0;
        }

        .k-checkbox-label {
          margin-inline-start: 0;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: $dark-blue;
        }

        .form-group.d-flex {
          justify-content: flex-end;
        }

        .customInput.inputBorderNone {
          border: none;
          padding: 0;
          height: 24px;
          display: flex;
          align-items: center;
          width: auto;
          color: $label-color;
          font-weight: 500;
          background: transparent;
           min-width: 60px;

          .k-input-inner {
            .k-input-value-text {
              max-width: 100%;
              font-size: 14px;
              line-height: 13px;
              font-weight: 400;
              text-align: right;
            }
          }

          .k-button {
            position: initial;
            padding: 0;
            height: 14px;
            width: 14px;
            background-size: 10px;
            background-color: transparent;
            margin-left: 3px;
          }
        }

        .permissionLeftCol {
         span {
            .k-checkbox-md {
              margin-top: -2px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.checkbox-label-align {
  .k-checkbox {
    margin-top: 0 !important;
  }
}

//End

//Custom dropdown UI
.k-animation-container {
  .k-popup {
    // max-height: 250px;
    background: $white-color;
    border: 1px solid $grey-light-border;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    padding: 0px;
    overflow: hidden;

    .k-list-content {
      -ms-overflow-style: thin;
      scrollbar-width: thin;
      scrollbar-color: #d3d5dd #f1f1f1;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d3d5dd;
        border-radius: 20px;
      }

      .k-list-ul {
        margin: 0;

        .k-list-item {
          padding: 7px 15px;

          .k-list-item-text {
            font-weight: 600;
            font-size: 14px;
            color: $primary-dark-blue;
          }

          &:hover {
            background-color: #f0f2f9;
          }
        }

        .k-selected {
          background-color: #f0f2f9;
        }
      }

      .k-focus.k-list-item {
        box-shadow: none;
      }
    }
  }
}

//End

//Custom dropdown popup UI change
.k-animation-container {
  .permissionsDropDown {
    border: 1px solid $primary-light-blue;
    border-radius: 4px;
    background-color: $white-color;
    box-shadow: none;

    //min-width: 140px;
    .k-list-content {
      .k-list-ul {
        padding: 10px 0;

        .k-list-item {
          padding: 8px 15px;

          .k-list-item-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            color: $lighter-grey;
          }

          &:hover {
            background-color: transparent;

            .k-list-item-text {
              color: $primary-dark-blue;
            }
          }
        }

        .k-selected {
          background-color: transparent;
        }
      }
    }
  }
}

// DropDownButton Popup css
.k-menu-group {
  border-color: $grey-light-border;
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 0 !important;

  .k-item {
    .k-link {
      color: $primary-dark-blue;
      font-weight: 600;
      font-size: 12px;
      padding: 5px 16px;

      &:hover {
        background: rgba(238, 240, 248, 0.85);
      }
    }
  }

  .k-focus {
    .k-link {
      box-shadow: none !important;
    }
  }
}

//End

//Custom Tooltip UI
.k-tooltip {
  background: $white-color;
  border: 1px solid $grey-light-border;
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;
  padding: 0;

  .k-tooltip-content {
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    color: $dark-grey;
    z-index: 99;
    position: relative;
    padding: 10px 15px;
  }

  .k-callout {
    color: $grey-light-border;
    border-width: 8px;
    bottom: -16px;
    display: none;
  }
}

//Tooltip End

.categoryActionNav {
  display: flex;
  align-items: center;
  color: $primary-light-blue;
  font-weight: 600;

  .k-checkbox-md {
    width: 16px;
    height: 16px;
    border: 2px solid $primary-light-blue;
    border-radius: 2px;
    margin-top: -3px;
    margin-right: 12px;

    &:disabled {
      box-shadow: none;
      border-color: $light-grey;
    }
  }

  .k-checkbox-md:checked {
    background-color: $primary-light-blue;

    &:disabled {
      background-color: $light-grey ;
      box-shadow: none;
      border-color: $light-grey;
    }
  }

  .k-group {
    padding: 10px 0;
  }

  .k-link {
    box-shadow: none !important;
    border: none !important;
  }

  .k-selected {
    background-color: #f0f2f9;
  }
}

//Permission css
.userPermissionsList {
  margin-top: 22px;
  border: none;

  .k-panelbar-header {
    .k-link {
      background: rgba(238, 240, 248, 0.85) !important;
      border-radius: 4px;
      padding: 10px 15px;
      min-height: 46px;
      cursor: pointer;
      .k-panelbar-item-text {
        width: 100%;

        .panelTitleSec {
          display: flex;
          align-items: center;

          img {
            width: 16px;
          }

          strong {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $primary-dark-blue;
            padding-left: 12px;
          }
        }
      }

      .k-icon {
        color: $deep-blue !important;
      }
    }

    .k-link.k-focus {
      box-shadow: none;
    }
  }

  .permissionsCatSec {
    border-color: transparent;
    background: #f9fafc;
    border-radius: 4px;
    padding: 20px;

    h4 {
      font-weight: 400;
      font-size: 18px;
      line-height: 17px;
      color: #000000;
    }

    .permissionCatLists {
      .catCol {
        background: $white-color;
        border: 0.5px solid $grey-light-border;
        border-radius: 5px;
        height: 62px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        position: relative;
        margin: 11px 0;

        .label {
          color: $primary-light-blue;
          font-weight: 600;
          font-size: 14px;
          padding-top: 3px;
          margin-bottom: 0px;
          margin-left: 5px;
        }

        .catActionSec {
          position: absolute;
          right: 8px;

          .moreIconDropdownBtn {
            .k-button {

              &:focus,
              &:hover {
                background-color: transparent !important;
                border: none !important;

                &::before,
                &::after {
                  display: none !important;
                }
              }
            }
          }

          .actionsList {
            background: $white-color;
            border: 1px solid $grey-light-border;
            box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
            border-radius: 5px;
            padding: 20px;
            display: none;
          }
        }
      }
    }
  }
}


// Upload File Component css
.profilePicUploadOuter {
  position: relative;

  .uploadedProfileImg {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;

    img {
      height: 60px;
      width: 60px;
      position: absolute;
      object-fit: cover;
      display: none;

      &:last-child {
        display: block;
      }
    }

    &:empty {
      background: url('../../Assets/Images/profile-default-img.svg') !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 100% !important;
      height: 60px;
      width: 60px;
    }
  }

  .uploadImageHints {
    position: absolute;
    top: 30px;
    left: 95px;
    pointer-events: none;

    h4 {
      font-weight: 400 !important;
      font-size: 18px;
      line-height: 17px;
      margin-bottom: 10px !important;
      color: $text-dark-black-color;

      strong {
        color: $primary-light-blue;
        font-weight: 600;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 13px;
      color: $label-color;
      margin-bottom: 0;
    }
  }

  .uploadProfileImg {
    background: #F9FAFC;
    border: 1px dashed rgba(130, 134, 155, 0.7);
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .k-dropzone {
      position: relative;
      height: 100px;

      .k-upload-button,
      input[type=file] {
        background-color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: none;
        padding: 0;
        box-shadow: none;
        width: 100%;

        &:focus {
          box-shadow: none;
        }

        span {
          display: none;
        }
      }

      input[type=file] {
        width: 100%;
        opacity: 0;
        z-index: 99;
        cursor: pointer;
      }

      .k-dropzone-hint,
      .k-upload-status {
        display: none;
      }
    }

    .k-upload-files {
      border: none;
      overflow: initial;

      .k-file {
        border: none;
        background: none;
        padding: 0;
      }

      .k-file-group-wrapper,
      .k-file-icon-wrapper,
      .k-file-name-size-wrapper,
      .k-file-info,
      .k-progressbar,
      .k-upload-pct {
        display: none;
      }

      .k-upload-status,
      .k-upload-actions {
        .k-button {
          position: absolute;
          top: -81px;
          z-index: 999;
          left: 64px;
          background: #D14141 !important;
          color: #fff;
          border-radius: 100%;
          width: 18px;
          height: 18px;

          .k-icon {
            font-size: 12px;

            &:before {
              content: "\e11b";
            }
          }
        }
      }
    }

    .k-actions {
      border-top: 1px dashed rgba(130, 134, 155, 0.7);
      justify-content: center;

      .k-button {
        font-family: $leagueSpartanFont;
        padding: 8px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;
      }
    }
  }

  .uploadDocComponent {
    .k-upload-files {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f8f9fa;
      z-index: 10;
      padding: 0;
      display: flex;
      align-items: center;

      .k-file {
        padding: 0;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;

        .k-file-single {
          padding-right: 20px;

          .k-progressbar {
            width: 100%;
            background: #E8E9EC;
            height: 6px;
            bottom: 0;

            .k-progress {
              background: linear-gradient(218.8deg, #021BAE 3.54%, #031164 66.51%);
            }
          }

          .k-file-group-wrapper {
            display: none;
          }

          .k-file-name-size-wrapper {
            padding-right: 20px;
            padding-left: 80px;

            .k-file-name {
              font-weight: 400;
              font-size: 18px;
              line-height: 17px;
              color: $dark-grey;
            }

            .k-file-validation-message {
              margin-top: 5px;
            }
          }

          .k-upload-status {
            .k-button-icon {
              background: #F4D9D9;
              color: $error-color;
              width: 28px;
              height: 28px;
              overflow: hidden;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: 1px;

              &:hover {
                background: $error-color;
                color: #F4D9D9;
              }
            }
          }
        }
      }
    }
  }

  .documentPdfView {
    width: 100%;
    min-width: 1000px;
    height: calc(100vh - 170px);
  }
}

.fileUploadView{
  margin-bottom: 88px;
}

// End

//Custom Tooltip UI
.gridsTooltip {
  .k-tooltip {
    max-width: 300px;

    .k-tooltip-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $dark-grey;
      text-align: left;
    }
  }
}

.k-tooltip {
  background: $white-color;
  border: 1px solid $grey-light-border;
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;
  padding: 0;

  .k-tooltip-content {
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    color: $dark-grey;
    z-index: 99;
    position: relative;
    padding: 10px 15px;
    // max-height: 200px;
    // overflow: hidden;
  }

  .k-callout {
    color: $grey-light-border;
  }

  .k-callout-n {
    top: -16px;
  }

}

//Tooltip End

// Alert message Css

.wrapper {
  position: relative;
}

.commonAlertMsg {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 20px;
  margin: 5px 0;
  z-index: 999;

  .alert-success {
    background: #D4E6DA;
    color: #1C9240;
    background-image: url('../../Assets/Images/check-circle-green.svg');
  }

  .alert-danger {
    background: #F4D9D9;
    color: $error-color;
    background-image: url('../../Assets/Images/close-circle-red.svg');
  }

  .alert-warning {
    background: #FFEED3;
    color: #E28F10;
    background-image: url('../../Assets/Images/warning-orange.svg');
    background-size: 24px !important;
  }

  .alert-primary {
    background: #E7EAFE;
    color: #3248CB;
    background-image: url('../../Assets/Images/info-cicle-blue.svg');
  }

  .alert {
    box-shadow: 0px 4px 20px rgba(127, 135, 146, 0.15);
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 20px 24px 20px 58px;
    margin-bottom: 0;
    background-repeat: no-repeat;
    background-position: center left 24px;
    background-size: 22px;
    text-align: left;
  }
}

.alertsSec {
  text-align: center;
  padding: 30px;

  .commonAlertMsg {
    position: inherit;
    padding-right: 0;
  }
}

// End

//  Confirmation Msg PopUp

.confirmationMsgPopUp {
  .k-dialog {
    width: 100%;
    max-width: 350px;
    border-radius: 0;
    border: none;
    background: $white-color;
    box-shadow: 0px -10px 30px rgba(145, 148, 165, 0.25);

    .k-window-content {
      padding: 30px;
      text-align: center;

      .alertMsg {
        font-weight: 400;
        color: $dark-grey;
        font-size: 20px;
        letter-spacing: .5px;
      }
    }

    .modelFooterAction {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      gap: 12px;

      .k-button {
        min-width: 70px;
        font-weight: 600;
        padding: 9px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;
      }
    }
  }
}

.restartConfirmationMsgPopUp {
  .k-dialog {
    width: 100%;
    max-width: 350px;
    border-radius: 0;
    border: none;
    background: $white-color;
    box-shadow: 0px -10px 30px rgba(145, 148, 165, 0.25);

    .k-window-content {
      padding: 10px 30px 30px 30px;
      text-align: center;

      .alertMsg {
        font-weight: 400;
        color: $dark-grey;
        font-size: 20px;
        letter-spacing: .5px;
      }
    }

    .modelFooterAction {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      gap: 15px;

      .k-button {
        min-width: 70px;
        font-weight: 600;
        padding: 9px;
        font-size: 14px;
        line-height: 16px;
        border-radius: 4px;
      }
    }

    .k-button {
      padding: 0;

      .k-button-icon {
        background-image: url('../../Assets/Images/close-black.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;

        &::before {
          display: none;
        }
      }

    }
  }
  .k-window-titlebar {
    border: none;
    padding: 10px 15px 0 30px;
  }
}

// Delete dialog
.deleteDialog {
  .k-dialog {
    max-width: 500px;
    border-radius: 5px;

    .k-dialog-titlebar {
      border: none;
      padding: 25px 25px 0 30px;

      .k-dialog-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        background: url('../../Assets/Images/warning-red-icon.svg');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 20px;
        padding: 3px 0 0 30px;
      }

      .k-button {
        padding: 0;

        .k-button-icon {
          background-image: url('../../Assets/Images/close-black.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px;

          &::before {
            display: none;
          }
        }

        &:hover,
        &:focus {
          background-color: transparent;

          &::before,
          &:after {
            display: none !important;
          }
        }
      }
    }

    .k-window-content {
      text-align: left;

      .alertMsg {
        font-weight: 400;
        font-size: 24px;
        line-height: 26px;
      }
    }

    .modelFooterAction {
      justify-content: flex-end;
      padding-top: 30px;
      gap: 15px;

      .k-button {
        min-width: 144px;
        min-height: 42px;
      }
    }
  }
}

//Switch Button

.k-switch {
  width: 36px;

  .k-switch-track {
    width: 100%;
    height: 15.56px;
    box-shadow: 4px 8px 24px #F2F2F5;
    background: rgba(60, 60, 67, 0.3);
    border: none;
  }

  .k-switch-thumb-wrap {
    .k-switch-thumb {
      width: 20px;
      height: 20px;
      box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
      margin-top: -0.5px;
    }
  }
}

.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 26px) !important;
}

.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 25px - 3px) !important;
}

.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(14px + 18px) !important;
}

.k-switch-on {
  .k-switch-thumb-wrap {
    left: 26px !important;
  }

  .k-switch-thumb {
    background-color: $primary-light-blue;
  }

  .k-switch-track {
    background: $primary-light-blue;
    opacity: 0.5;
  }
}

.k-switch-on.k-focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
  border: none;
  outline: none;
}

.k-notification,
.k-notification-success,
.k-notification-closable {
  display: flex !important;
}

.k-notification-warning {
  border-color: #fff3cd;
  color: #E28F10;
  background-color: #FFEED3;
  border-radius: 8px;
}

//End

.k-editor {
  background: $white-color;
  border: 1px solid #E6E9F7;
  border-radius: 8px;
  overflow: hidden;
  //margin: 20px 0;
  margin-top: 20px;

  .k-toolbar {
    background-color: $white-color;
    border-color: #E6E9F7;

    .k-button-group {
      padding: 0 2px;
      display: flex;
      align-items: center;

      .k-button {
        background-color: transparent;
        border: none;
        padding: 0 5px;
        opacity: 1;

        .k-button-icon {
          font-size: 24px;
          color: $label-color;
        }

        .k-i-bold {
          background: url('../../Assets/Images/bold-icon.svg');
          background-size: 12px;
        }

        .k-i-italic {
          background: url('../../Assets/Images/italic-icon.svg');
          background-size: 14px;
        }

        .k-i-underline {
          background: url('../../Assets/Images/undeline-icon.svg');
          background-size: 15px;
        }

        .k-i-link {
          background: url('../../Assets/Images/link-icon.svg');
          background-size: 22px;
        }

        .k-i-bold,
        .k-i-italic,
        .k-i-underline,
        .k-i-link {
          background-repeat: no-repeat;
          background-position: center;
          height: 20px;
          width: 20px;

          &:before {
            display: none;
          }
        }
      }
    }

    .k-dropdownlist {
      margin: 0 8px 0 10px;

      .k-input-inner {
        padding: 5px 10px;
      }

      .k-button {
        .k-icon {
          background: url('../../Assets/Images/down-angle-grey.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 11px;

          &::before {
            display: none;
          }
        }
      }
    }

    .k-picker-solid {
      border-color: #e4e7eb;
      color: $label-color;
      background-color: transparent;
      width: 100px;
      font-size: 14px;
      border-radius: 4px;

      .k-input-inner {
        padding-right: 0;
      }
    }

    .k-colorpicker {
      border: none;
      position: relative;
      width: 30px;

      .k-input-inner {
        padding: 0;

        // width: 30px;
        .k-icon-color-preview .k-icon {
          background: url('../../Assets/Images/bgcolor-icon.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          height: 22px;
          width: 22px;

          &:before {
            display: none;
          }
        }

        .k-color-preview-mask {
          height: 2px;
          position: absolute;
          bottom: 2px;
          width: 17px;

          &:before {
            display: none;
          }
        }
      }

      .k-button {
        position: absolute;
        z-index: 99;
        width: 100%;
        height: 100%;

        .k-icon {
          display: none;
        }
      }
    }

    .k-picker-solid.k-focus {
      box-shadow: none;
    }
  }
}

//calendar UI
.k-calendar {
  color: $dark-grey;
  background: $white-color;
  border: 1px solid $grey-light-border;
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;

  //width: 350px;
  .k-calendar-header {
    margin-left: 0;
    margin-right: 0;
    padding: 15px 22px 5px 22px;

    .k-calendar-title {
      font-weight: 600;
      padding: 0;

      &:hover,
      &:focus {
        background: transparent;

        &::after,
        &::before {
          display: none !important;
        }
      }
    }

    .k-calendar-nav {

      .k-button,
      .k-calendar-nav-today {
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 14px;

        &:hover,
        &:focus {
          color: $link-hover;

          &::after,
          &::before {
            display: none !important;
          }
        }
      }
    }
  }

  .k-calendar-weekdays {
    width: 100%;
    padding: 0 10px;

    colgroup col {
      width: auto;
    }

    .k-calendar-thead {
      .k-calendar-th {
        font-weight: 400;
        font-size: 14px;
        color: #B4B6C3;
      }
    }
  }

  .k-calendar-content {
    .k-calendar-table {
      width: 100%;
      padding: 0 10px;

      colgroup col {
        width: auto;
      }

      .k-calendar-tbody {
        .k-calendar-td {
          .k-link {
            color: $dark-grey;
            font-weight: 500;
            font-size: 15px;

            &:hover {
              color: $primary-dark-blue !important;
              border: 1px solid $primary-dark-blue;
              box-shadow: none;
              background-color: transparent;
            }
          }
        }
      }

      .k-today {
        .k-link {
          color: $primary-dark-blue !important;
          border: 1px solid $primary-dark-blue;
          box-shadow: none;
        }
      }

      .k-selected {
        .k-link {
          background-color: $primary-dark-blue;
          border-color: transparent;
          font-weight: 500;
          box-shadow: none;
          color: $white-color !important;
        }
      }
    }
  }

  .k-calendar-navigation {
    color: $dark-grey;
    background: rgba(238, 240, 248, 0.85);

    ul li {
      font-size: 15px;
      font-weight: 500;

      &:hover {
        color: $primary-dark-blue;
      }
    }

    .k-calendar-navigation-marker {
      color: $primary-dark-blue;
    }
  }

  .k-calendar-view {
    padding: 0px;
    // height: 300px;
  }
}

//calendar UI End

//Tabs UI
.customTab {
  .k-tabstrip-items-wrapper {
    border-bottom: 0.5px solid #E6E9F7;
    box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 15px;
    list-style: none;

    .k-tabstrip-items {
      gap: 10px;

      .k-item {
        background: none;
        border: none;

        .k-link {
          color: $label-color;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          background: transparent;
          padding: 12px 16px;
          border: none;
          cursor: pointer;
          position: relative;

          &:after {
            content: "";
            background: $primary-light-blue;
            height: 2px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: all 250ms ease 0s;
            transition: all 250ms ease 0s;
            -webkit-transform: scale(0);
            transform: scale(0)
          }

          .k-badge {
            transform: inherit;
            position: inherit;
            margin-left: 4px;
            background-color: $primary-light-blue;
            font-size: 9px;
            border-radius: 3px;
            padding: 1px 0 0 0;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15px;
            min-width: 16px;
            font-weight: 600;
            z-index: 9;
          }
        }
      }

      .k-active {
        .k-link {
          color: $primary-light-blue;
          font-weight: 700;

          &:after {
            content: "";
            -webkit-transform: scale(1);
            transform: scale(1)
          }
        }
      }
    }
  }

  .k-content {
    background: transparent;
    border: none;
    padding: 0;

    .k-animation-container {
      width: 100%;
    }
  }
}

//Tabs End

//multiselect tree popup UI
.k-multiselecttree-popup {
  .k-list-filter {
    padding: 14px 14px 6px 14px;
    .k-searchbox{
      .k-svg-i-search{
        width: 18px;
        height: 18px;
        margin-left: 2px;
      }
    }
  }

  .k-treeview-item {
    padding: 5px 10px 5px 6px;
  }

  .k-treeview {
    -ms-overflow-style: thin;
    scrollbar-width: thin;
    max-height: 200px;
  }

  .k-treeview::-webkit-scrollbar {
    scrollbar-width: thin;
  }

  .k-treeview-leaf {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: $lighter-grey;
    padding: 0 0 0 3px;
    box-shadow: none;
    top: 2px;

    &:hover,
    &:focus {
      border: none;
      background: none;
      color: $primary-light-blue;
    }
  }
}


//Loader start
.k-loading-mask {
  z-index: 1050;
}

.whitespace-auto {
  white-space: inherit;
}


// Kendo filter css
.k-filter-group-main {
  .k-filter-toolbar {
    display: none;
  }

  .k-filter-lines {
    .k-filter-toolbar {
      display: block;
    }
  }
}

.k-notification-container {
  margin: 0px;
}

.fixedFullPopupRight {

  &.large{
    .k-dialog{
      max-width: 900px;
    }
  }
  .innerScroll {
    padding-top: 10px;
    padding-bottom: 15px;
    height: calc(100% - 75px) !important;
  }

  .maverickForm {
    padding-right: 15px;
  }

  .k-dialog {
    position: fixed;
    right: 0;
    width: 100%;
    max-width: 550px;
    top: 0;
    bottom: 0;
    border-radius: 0;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);

    .modelCustomTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.2em;
        color: $text-dark-black-color;
        margin: 0;
        text-transform: uppercase;
      }
    }

    .k-window-actions,
    .k-dialog-titlebar-actions {
      margin: 0;

      .k-button {
        opacity: 1;
        padding: 0;

        &:focus,
        &:hover {
          box-shadow: none !important;
          background: none !important;

          &:before,
          &:after {
            display: none !important;
          }
        }

        .k-button-icon {
          background: url('../../Assets/Images/close-black.svg') !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 16px !important;


          &::before {
            display: none;
          }
        }
      }
    }

    .modelFooterAction {
      text-align: right;
      padding: 24px;
      box-shadow: 0px 0px 20px rgba(145, 148, 165, 0.25);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background: #fff;
      gap: 10px;
      display: flex;
      justify-content: flex-end;

      .k-button {
        font-weight: 600;
      }
    }

    .k-window-content {
      padding: 0px 9px 24px 24px;
    }

    .k-dialog-titlebar {
      border: none;
      padding: 15px 20px 15px 24px;

      .k-window-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $text-dark-black-color;
      }

      .k-button {
        opacity: 1;
        padding: 0;

        .k-button-icon {
          background: url('../../Assets/Images/close-black.svg') !important;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: 16px !important;
          opacity: 1;

          &::before {
            display: none;
          }
        }

        &:hover {
          background-color: transparent;

          &::before,
          &::after {
            display: none !important;
          }
        }
      }

    }
  }
}

.pr-0 {
  padding-right: 0 !important;
}

.firstClientPopupScroll {
  padding-top: 10px;
  padding-bottom: 15px;
  height: calc(100% - 75px) !important;

  .maverickForm {
    padding-right: 15px;
    padding-bottom: 20px;
    margin-top: -20px;
  }
}

.promotion-list-dropdown-item {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: $primary-dark-blue;
  font-family: $leagueSpartanFont;
  // pointer-events: none;
}

.contact-list-delete-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #B4B6C3;
  // pointer-events: none;
}

.contact-list-delete-button:hover {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.contactDeleteConfirmation .k-dialog {
  max-width: 450px !important;
}

.toast-notification {
  .k-icon {
    margin-right: 5px;
    margin-top: 1.1px;
  }

  .k-notification-actions {
    position: relative;
    bottom: 1px;
    left: 5px;
  }

}

#editIconBtnDummy {
  background: none !important;
}

#editIconBtnDummy:hover {
  border: none !important;
}

.editPassenger .k-panelbar-item-text {
  width: 100%;
}


// Collapse | Expand Data Table
@media screen and (min-width: 768px) {
  .k-grid.detailRowsTbl {
    table {

      .k-hierarchy-cell .k-link:after,
      col:nth-child(1),
      th:nth-child(1),
      td:nth-child(1) {
        display: none;
      }

      .k-master-row td:nth-child(2) {
        text-align: left;
        border-left: 1px solid rgba(210, 218, 227, 0.75);
        border-radius: 5px 0 0 5px;
        padding-left: 15px;
      }
    }
  }

  .tableDetailsData {
    .tblColData-hidden-md {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .k-grid.detailRowsTbl {
    table {

      .k-detail-row .k-hierarchy-cell,
      .k-hierarchy-cell .k-link:after {
        display: none;
      }
    }

    .k-detail-cell {
      padding: 0;
      white-space: normal;

      .tableDetailsData {
        padding: 15px 20px 5px 20px;

        label {
          margin-bottom: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: $label-color;
          display: block;
          word-wrap: break-word;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $dark-grey;
          margin-bottom: 20px;
          word-wrap: break-word;
        }

        .linkCol {
          color: $primary-light-blue;
          font-weight: 600;
        }
      }
    }
  }
}

span.linkCol {
  cursor: pointer;
  color: $primary-light-blue;
  font-weight: 600;
}

// Collapse | Expand Data Table End


.swc {
  .swc-checkbox {
    input {
      margin-right: 7px;
      position: relative;
      bottom: 2px;
    }
  }
}

@media screen and (max-width: 767px) {
  .k-grid.detailRowsTbl {
    table {

      .k-detail-row .k-hierarchy-cell,
      .k-hierarchy-cell .k-link:after {
        display: none;
      }
    }

    .k-detail-cell {
      padding: 0;
      white-space: normal;

      .tableDetailsData {
        padding: 15px 20px 5px 20px;

        label {
          margin-bottom: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: $label-color;
          display: block;
          word-wrap: break-word;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: $dark-grey;
          margin-bottom: 20px;
          word-wrap: break-word;
        }

        .linkCol {
          color: $primary-light-blue;
          font-weight: 600;
        }
      }
    }
  }
}

.swc-cross-button {
  right: 32px;
  top: 42px;
  z-index: 100;
  color: #999cad;
  cursor: pointer;
  font-size: 14px;
}

.swc-ms-cross-button {
  position: absolute;
  right: 32px;
  top: 55%;
  z-index: 111;
  color: #999cad;
  font-size: 14px;
  cursor: pointer;
}

.swc-cross-button {
  right: 32px;
  top: 42px;
  z-index: 111;
  color: #999cad;
  cursor: pointer;
  font-size: 14px;
}

.role-trash-button {
  color: #D14141;
  cursor: pointer;
}

.delete-role-dialog {
  .k-dialog {
    // max-width: calc(30%);
  }

  .k-dialog-titlebar {
    padding: 1em !important;
  }

  .k-dialog-content {
    padding: 1em !important;
  }

  .message {
    text-align: start;
  }

  .modelFooterAction {
    justify-content: end !important;
    padding-top: 10px !important;
  }

  .delete-role-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Black */

    color: #00041B;
  }
}

.role-view-label {
  /* Dark Purple Grey */
  font-weight: 400;
  font-size: 12px;
  color: #82869B;
}

.role-view-value {
  /* Black */
  color: #00041B;
  font-weight: 400;
  font-size: 16px;
}

.deleteRoleBtn {
  color: #D14141;

  svg {
    margin-right: 5px;
    top: -2px;
    position: relative;
  }

  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}


.maverickForm .form-group .customInput .k-input-value-text {
  max-width: calc(100% - 35px);
}

.viewIDocBtn {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  width: 17px;
  height: 14px;
  display: inline-block;
  padding: 0;
  background-image: url('../../Assets/Images/eye-blue.svg');
  margin-left: 1px;
  background-color: transparent;
  border: none;
  margin-right: 6px;
}

.k-dialog-wrapper {
  .k-dialog-content {
    -ms-overflow-style: thin;
    scrollbar-width: thin;
    scrollbar-color: #d3d5dd #f1f1f1;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d3d5dd;
      border-radius: 20px;
    }
  }
}

.googleAddressList {
  background: #FFFFFF;
  border: 1px solid rgba(210, 218, 227, 0.75);
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;
  padding: 20px;

  &:empty {
    display: none;
  }

  div {
    padding: 5px 0;
  }
}

.brokerValueEmail {
  display: inline-block !important;
  word-wrap: break-word;
  white-space: initial;
  width: 100%;
}

.inititialsImg {
  width: 40px;
  height: 40px;
  background: #E6E9F7;
  color: $primary-dark-blue;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.transform-360 {
  transform-origin: center;
  transform: rotate(180deg);
}

.tableWrapHeader .k-column-title,
.tableWrap {
  line-height: 14px !important;
  word-wrap: break-word;
  white-space: initial;
}

.multi-select .optionalField+label:after,
.maverickForm .form-group .optionalField+.k-label:after {
  right: 15px !important;
}

// Role Details Organization text overlapping with permission (Form width increased)
.rolePermissionsForm {
  max-width: 700px;
}

.k-no-data,
.k-nodata {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: $primary-dark-blue !important;
  color: #00106E !important;
}

.noCrossBtn {

  button[title="Remove"],
  button[title="Cancel"] {
    display: none !important;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.routesInfo {
  font-weight: 600;
  color: $dark-blue;
  margin: 0;

  .roundTripIcon {
    background-image: url('../../Assets/Images/arrowsLeftRight.svg');
  }

  .onewayTripIcon {
    background-image: url('../../Assets/Images/arrowsRight.svg');
  }

  .roundTripIcon,
  .onewayTripIcon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px;
    width: 16px;
    height: 10px;
    display: inline-block;
    margin: 0px 3px;
  }

  .divider {
    background-color: #82869B !important;
    width: 1px !important;
    height: 10px !important;
    display: inline-block !important;
    margin: 0 5px !important;
  }

  &.unstyled{
    font-weight: 400;
    color: #000837;
  }
}

.specialFeeOption {
  background: none !important;
  text-align-last: left !important;
}

.hide-sort-icon .k-link::after {
  background-image: none !important;
}

.show-sort-icon .k-cell-inner .k-link::after {
  background-image: url('../../Assets/Images/shortIcon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
  margin: 0 0 0 6px;
  width: 1em;
  height: 1em;
  content: '';
}

.overviewDetailsCard > :first-child {
  padding: 5px 15px 5px 5px;
}

.text-right {
  text-align: right !important;
}

.k-sort-icon,
.k-sort-order {
  display: none !important;
}

.customLoader .k-input-loading-icon {
  position: absolute !important;
  right: 7px !important;
  background: white !important;
  display: block !important;
  bottom: 7px !important;
  z-index: 111 !important;
}

.transparent-button {
  border: 0;
  background: transparent;
}

span.dot-icon::before {
  background-color: #000C52;
  content: "";
  margin-right: 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
}
td.routeCol {
  .tripStatusView {
      span.tripStatus {
          position: relative;
          border: none;
          padding-right: 0;
          margin-right: 0;
          margin-left: 5px;

          &::before {
              content: '';
              margin-right: 8px;
              width: 8px;
              height: 8px;
              display: inline-block;
              border-radius: 100%;
          }
      }

      // Trip Status
      span.Accepted::before {
          background-color: $success-color;
      }

      span.AssignmentMade::before {
          background-color: $primary-light-blue;
      }

      span.Finalized::before {
          background-color: $deep-blue;
      }

      span.Cancelled::before {
          background-color: $error-color;
      }

      span.Draft::before {
          background-color: #B4B6C3;
      }

      span.SchedulingPricingProposal::before {
          background-color: $warning-color;
      }

      span.QuoteReview::before {
          background-color: #FDE50E;
      }

      span.ClientReview::before {
          background-color: $info-color;
      }

      span.PartiallyAssigned::before {
          background-color: #FFFFFF;
          border: 1px solid $primary-light-blue;
      }

      span.OngoingTrip::before {
          background-color: #9BCBEB;
      }

      span.Scheduling::before {
          background-color: #FFA722;
      }

      span.FinanceReview::before {
          background-color: #949DD7;
      }

      span.TripReview::before {
          background-color: #FCCF8C;
      }
  }
}

.tableSmall table{
  min-width: 900px;
  colgroup {
    col:nth-child(2) {
      width: 160px;
    }
    col:nth-child(3) {
      width: 90px;
    }
    col:nth-child(8) {
      width: 100px;
    }
    col:last-child {
      width: 85px;
  }
  }
  tr {
      th:first-child {
          .k-link {
              cursor: default;

              &:after {
                  display: none !important;
              }
          }
      }
  }

}

.actionCompleted {
  color: #1C9240 !important;
  background-color: #E5F5EA !important;
}

.actiondueBx {
  color: #00106E !important;
  background-color: #E6E9F6 !important;
}

.errorBorder {
  border: 1px solid #D14141 !important;
}

.k-calendar-container, .datePicker-popup {
  z-index: 11111 !important;
}
.customMultiSelect .k-animation-container.k-animation-container-relative {
  top: auto !important;
  left: auto !important;
  z-index: 10000000 !important;
}

.staticMultiSelect .customMultiSelect .k-animation-container.k-animation-container-relative{
  position: static !important;
}

.contactStatus {
  span.status {
    position: relative;
    border: none;
    padding-right: 0;
    margin-right: 0;
    margin-left: 5px;

    &::before {
        content: '';
        margin-right: 8px;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
    }
  }


    span.NewContact::before {
      background-color: #9bcbeb;
    }

    span.PotentialClient::before {
      background-color: #1c9240;
    }

    span.Initiated::before {
      background-color: #ffa722;
    }

    span.Client::before {
      background-color: #001489;
    }

    span.Reject::before {
      background-color: #d14141;
    }

    span.Qualified::before {
      background-color: #1c9240;
    }

    span.NotInterested::before{
      background-color: #d14141;
    }

    span.NoAnswer::before{
      background-color: #d14141;
    }
}

span.copyLinkIcon {
  background-image: url('../../Assets/Images/copyIcon-blue.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  width: 16px;
  height: 16px;
  display: inline-block;
 cursor: pointer;
 z-index: 2;
}

.mt-40 {
  margin-top: 40px !important;
}

.resize-none, .resize-none textarea {
 resize: none !important;
}

.helper-text-email {
  color: #666;
  font-size: 0.75em;
}

.whiteSpaceBreakLine {
  white-space: pre-line;
}

.disable-mode {
  opacity: 0.5;
}

.editLegErrorAlertMessage {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.k-grid.airportTbl,
.k-grid.aircraftTblNew,
.k-grid.airportFBO,
.k-grid.operatorTbl {
  .k-grid-header .k-header:last-child {
    .k-cell-inner .k-link {
      justify-content: center;
    }
  }
}

body:has(>div.k-dialog-wrapper) {
  overflow: hidden!important;
}

.popupFormHidden{
  overflow-x: hidden;
  padding-right: 12px;
}

.customAddContactDetailsModel{
  .k-dialog-titlebar-action{
    .k-svg-icon > svg{
      display: none;
    }
  }
}

.profilePicUploadOuter {
  .uploadImage {
    max-height: calc(100vh - 150px);
    width: 100%;
    height: auto;
  }
}

.closeEye {
  height: 20px;
  width: 20px;
  margin-top: -2px;
  margin-left: 2px;
  margin-right: 2px;
}

@media screen and (max-width:767px) {
  .filterLeftSecOpen {
    max-width: calc(100% - 157px) !important;
  }
}

@media screen and (min-width:767px) {
  .filterLeftSecOpen {
    max-width: calc(100% - 221px) !important;
  }
}
