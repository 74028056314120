// variables
@import "../../Assets/Styles/Variables.scss";
//Variables end

.headerDropDownPopup {
  background: #FFFFFF;
  border: 1px solid rgba(210, 218, 227, 0.75);
  box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
  border-radius: 5px;
  padding: 10px 0;
  list-style: none;
  margin-top: 0px;
  overflow: hidden;
  min-width: inherit;
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 100% top;
  transform-origin: 100% top;
  -webkit-transition: opacity .12s linear .12s, visibility .12s linear .12s, -webkit-transform .12s linear .12s;
  transition: opacity .12s linear .12s, visibility .12s linear .12s, transform .12s linear .12s, -webkit-transform .12s linear .12s;
  visibility: hidden;
}

.headerDropDownPopupHover {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible,
}

.categoryListPopup {
  margin-left: -21px;
  min-width: 180px;
  padding: 15px 0 0 0;

  .k-popup {
    padding: 6px 0 10px 5px;
    box-shadow: 0px 20px 30px rgba(145, 148, 165, 0.25);
    border-radius: 0 0 6px 6px;
    border: none;
  }
}

.searchAutoCompleteList {

  .k-popup {
    box-shadow: 0px 20px 30px rgba(145, 148, 165, 0.25);
    border-radius: 0 0 6px 6px;
    border: none;
  }
}

.suggestionsList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 5px;
}

p.suggestion-left,
p.suggestion-right {
  font-size: 16px;
  cursor: pointer;
  margin: 0;
}

p.suggestion-left {
  color: $text-dark-black-color;
  font-weight: 500;
}

p.suggestion-right {
  color: $label-color;
  padding-right: 5px;
}

p.suggestion-left:hover {
  color: #00106E;
  font-weight: 900;
}

.mainHeader {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(97, 112, 136, 0.12);

  .headerWrapper {
    display: flex;
    justify-content: space-between;

    .logoSection {
      width: 210px;
      padding: 0px 25px 10px 10px;
      background: #FFFFFF;
      display: block;
      height: 70px;

      background: none;
      border: none;
      color: #FFF;
      font-family: Verdana, Geneva, sans-serif;
      cursor: pointer;

      a {
        border: none;
        text-decoration: none;

        img {
          width: 100%;
        }
      }

      a.jetLogo {
        width: 174px;
        float: left;
        margin: -3px 0 0 -20px;
        cursor: pointer;
      }

      a.maverickLogo {
        width: 104px;
        float: right;
        margin-top: -15px;
      }

    }

    .middleSection {
      display: flex;
      align-content: center;
      justify-content: space-between;
      border-right: 1px solid #EEF0F4;
      border-left: 1px solid #EEF0F4;
      padding: 0 5px 0 5px;
      width: calc(100% - 390px);

      .searchForm {
        width: calc(100% - 60px);
        padding-left: 15px;
        align-items: center;

        .k-input {
          height: 100%;
          padding: 0 10px 0 20px;
          border: none;
          font-size: 16px;
          font-weight: 300;
          color: #667080BF;
          font-family: "League Spartan", sans-serif;
          box-shadow: none;

          &:focus,
          &:hover,
          &:active,
          .k-focus {
            box-shadow: none;
            outline: none;
          }

          .k-input-inner {
            padding: 0;
            color: $dark-grey;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;

            &:focus,
            &:hover,
            &:active,
            .k-focus {
              box-shadow: none;
              outline: none;
            }
          }
        }

        .selectCategoryOptions {
          border-right: 1px solid #E1E4EA;
          min-width: 160px;
          padding-right: 15px;
          height: 40px;

          &:focus,
          &:hover,
          &:active {
            box-shadow: none;
            outline: none;
          }
        }
      }

      .notificationBtn {
        background: url("../../Assets/Images/bell-blue.svg");
      }

      .headerSearchBtn,
      .searchText {
        //background: url("../../Assets/Images/search-blue.svg"); display: none;
        color: $primary-dark-blue;
        font-size: 16px;
        font-weight: 600;
        margin-top: 0px;
        background: none;

        &:focus {
          box-shadow: none;
        }
      }

      .menuIconBtn {
        background: url("../../Assets/Images/burgerMenuIcon.svg");
        display: none;
      }

      .headerCloseBtn {
        background: url("../../Assets/Images/close-blue.svg");
        display: none;
      }

      .notificationBtn,
      .headerSearchBtn,
      .menuIconBtn,
      .headerCloseBtn {
        background-repeat: no-repeat;
        height: 100%;
        background-position: center;
        border: none;
        background-size: 22px;
        width: 60px;
        padding: 0;

        &:focus,
        &:hover,
        &:active {
          box-shadow: none;
          outline: none;
        }
      }

      .notificationBtn {
        position: relative;
        min-height: 70px;

        .k-badge {
          background: $error-color;
          border: 2px solid #FFFFFF;
          width: 18px;
          height: 18px;
          min-width: inherit;
          font-size: 10px;
          border-radius: 10px;
          left: 25px;
          top: 27px;
          padding: 0;
        }
      }

      .k-multiselect,
      .k-multiselecttree {
        background: url("../../Assets/Images/search-blue.svg"), url("../../Assets/Images/down-angle-blue.svg");
        background-position: center left, center right;
        background-repeat: no-repeat !important;
        background-size: 15.75px, 10px;
        padding: 0 15px 0 22px !important;
      }

      .k-focus .k-multiselect,
      .k-focus .k-multiselecttree {
        background: url("../../Assets/Images/search-blue.svg"), url("../../Assets/Images/up-angle-grey.svg");
        background-position: center left, center right;
        background-repeat: no-repeat !important;
        background-size: 15.75px, 10px;
      }
    }
  }

  .userSec {
    width: 180px;
    padding-left: 20px;
    padding-right: 10px;

    .dropdown {
      padding: 0;

      .k-button-outline {
        color: $primary-dark-blue;
        padding: 15px 18px 15px 0;
        box-shadow: none !important;
        cursor: pointer;
        font-weight: 500;
        background-image: url('../../Assets/Images/down-angle-blue.svg');
        background-repeat: no-repeat;
        background-position: bottom 32px right;
        border: none;
        transition: 300ms ease all;
        max-width: 100%;

        &::after,
        &::before {
          box-shadow: none;
          outline: none;
          background-color: transparent;
        }

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          background-color: transparent;

          &::after,
          &::before {
            box-shadow: none;
            outline: none;
            background-color: transparent;
          }
        }

        .k-button-text {
          display: flex;
          max-width: 100%;

          .userPic {
            span {
              width: 40px;
              height: 40px;
              background: #E6E9F7;
              color: $primary-dark-blue;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
            }

            img {
              width: 40px;
              height: 40px;
              overflow: hidden;
              border-radius: 100%;
              object-fit: cover;
            }
          }

          .userNameSec {
            padding-left: 12px;
            display: flex;
            flex-direction: column;
            text-align: left;
            letter-spacing: .5px;

            span {
              font-weight: 500;
              font-size: 13px;
              color: #95A1AF;
              text-overflow: ellipsis;
              width: 86px;
              overflow: hidden;
            }

            strong {
              color: $primary-dark-blue;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              text-overflow: ellipsis;
              width: 86px;
              overflow: hidden;
            }
          }
        }
      }

      .dropdown-menu {
        @extend .headerDropDownPopup;

        li {
          a {
            color: $primary-dark-blue;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.3px;
            padding: 8px 25px;
            display: inline-block;
            width: 100%;

            &:hover {
              background-color: #F7F8F9;
            }
          }
        }
      }

      &:hover {
        .k-button-outline {
          background-image: url('../../Assets/Images/up-angle-blue.svg');
        }

        .dropdown-menu {
          @extend .headerDropDownPopupHover;
        }
      }
    }
  }
}

.notificationSec {
  .dropdown {
    padding: 0;

    .dropdown-menu {
      @extend .headerDropDownPopup;
      box-shadow: 0px 3.28182px 24.6136px rgba(127, 135, 146, 0.15);
      border-radius: 3.28182px;
      border: none;
      width: 360px;
      right: -60px;
      overflow: inherit;

      &:after {
        bottom: 100%;
        right: 80px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }

      .notificationTitleSec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 16px 8px 16px;
        background: #fff;

        //border-bottom: 1px solid #E4E8EE;
        h5 {
          font-weight: 500;
          font-size: 14.7682px;
          line-height: 16px;
          color: #1A1F36;
          margin: 0;
        }

        .readUnreadSwitch {
          display: flex;
          align-items: center;

          label {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $dark-grey;
            padding-left: 5px;
          }
        }

        .btn-link {
          color: $primary-light-blue;
          font-weight: 600;
          text-decoration: none;
          background-image: url('../../Assets/Images/check-circle-blue.svg');
          background-repeat: no-repeat;
          background-position: center right;
          background-size: 12px;
          padding-right: 17px;
        }
      }

      .notificationScrollSec {
        height: 450px !important;
      }

      .allNotification {
        padding: 15px 10px 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-link {
          text-decoration: none;
        }
      }
    }

    &:hover {
      .dropdown-menu {
        @extend .headerDropDownPopupHover;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:hover {
        background: #F3F5FF;
      }

      border-bottom: 1px solid #E4E8EE;
      padding: 15px;
      display: flex;
      align-items: flex-start;
      gap: 15px;
      cursor: pointer;

      .userPicSec {
        position: relative;
        min-width: 30px;

        .k-badge {
          background: $primary-light-blue;
          border: none;
          width: 7px;
          height: 7px;
          left: -10px;
          top: 0px;
          padding: 0;
          z-index: 9;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 100%;
        }
      }

      .userDataSec {
        width: calc(100% - 45px);

        h6 {
          font-weight: 500;
          font-size: 14.1267px;
          line-height: 18px;
          color: $dark-grey;
          margin-bottom: 8px;
        }

        .notificationDes {
          position: relative;
          padding-left: 12px;
          margin-bottom: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #000837;

          &::before {
            content: "";
            width: 4px;
            background: #DDDEE1;
            border-radius: 10px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
          }
        }

        .dateTime {
          font-weight: 500;
          font-size: 12.3608px;
          line-height: 18px;
          color: #B4B6C3;
          display: block;
        }
      }
    }
  }
}

.notificationListWrapper {
  max-width: 880px;
  margin: 0 auto;
  padding-top: 10px;

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #122A46;
  }

  .notificationSec {
    ul {

      // padding-right: 0;
      li {
        background: #FFFFFF;
        border: 0.5px solid rgba(210, 218, 227, 0.75);
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
    }
  }
}

// Multiselect box
.k-multiselect,
.k-multiselecttree {
  .k-selection-multiple {
    padding: 0;
    gap: 4px;
    padding: 5px 0 5px 0;
    max-width: calc(100% - 15px);

    .k-chip-solid-base {
      border: none;
      color: $deep-blue;
      background-color: #E6E9F7;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      border-radius: 100px;
      letter-spacing: 0;
      padding: 7px 9px;

      .k-chip-content {
        padding: 0;
        margin-left: 0;
      }

      .k-chip-actions {
        margin: 0;

        .k-chip-remove-action {
          .k-i-x-circle {
            background-image: url('../../Assets/Images/close-blue.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            height: 6.3px;
            width: 6.3px;
            margin-left: 2px;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .k-clear-value {
    display: none;
  }
}

.hideRemoveIcon {
  .k-selection-multiple {
    .k-chip-solid-base {
      .k-chip-actions {
        display: none;
      }
    }
  }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {

  .mainHeader {
    z-index: 999;

    .headerWrapper {
      position: relative;

      .logoSection {
        width: 155px;
        padding: 1px 0 0 0;
        height: 60px;

        a.jetLogo {
          width: 140px;
          margin: 0px 0 0 -15px;
        }

        a.maverickLogo {
          margin-right: 0;
          width: 83.42px;
        }
      }

      .userSec {
        display: none;
      }

      .middleSection {
        border: none;
        padding: 0;
        width: auto;

        .searchForm {
          position: absolute;
          left: -12px;
          background: #fff;
          bottom: 0;
          top: 0;
          width: calc(100% + 12px);
          z-index: 99999;
          padding-left: 0px;
          right: 0;

          .k-input {
            background-position: center left, center right;
            background-size: 12.5px, 10px;
            padding: 0 10px 0 15px !important;
          }

          .seachOverlay {
            background: $dark-blue;
            opacity: 0.3;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 60px;
            z-index: 99;
          }

          .selectCategoryOptions {
            min-width: 100px;
            padding-right: 10px;
            height: 30px;
            display: none;
          }

          .k-clear-value {
            display: none;
          }

          .customInput {
            width: calc(100% - 80px);
            background: url("../../Assets/Images/search-blue.svg");
            background-repeat: no-repeat;
            background-position: left 15px center;
            padding-left: 40px !important;
            background-size: 15px;
          }
        }

        .notificationBtn,
        .headerSearchBtn,
        .menuIconBtn,
        .headerCloseBtn {
          width: 38px;
          display: inline-block;
          background-size: 20px;
        }

        .notificationBtn {
          min-height: 60px;

          .k-badge {
            left: 13px;
            top: 24px;
          }
        }

        .headerCloseBtn {
          background-size: 14px;
          position: absolute;
          right: 4px;
          top: 0;
          width: 30px;
        }
      }
    }
  }

  .notificationListWrapper {
    padding-top: 15px;

    .notificationHeading {
      .col-3 {
        width: 40%;
      }

      .col-2 {
        width: 30%;
        padding-left: 0;
        padding-right: 0;
      }

      .btn-link {
        line-height: 26px;
      }

      .col-5 {
        display: none;
      }
    }

    h1 {
      font-size: 18px;
      margin-bottom: 0;
    }

    .notificationSec ul li {
      margin-top: 10px;
      margin-bottom: 12px;
    }
  }

  .notificationSec .dropdown .dropdown-menu {
    right: -40px;
    max-width: 340px;

    &:after {
      right: 50px;
    }
  }

  .searchAutoCompleteList {
    min-width: 100%;
    left: 0 !important;
    z-index: 999 !important;
    border-top: 1px solid #E6E9F7;

    .k-popup {
      padding-top: 5px;

      .suggestionsList {
        padding: 0px;

        p.suggestion-left,
        p.suggestion-right {
          font-size: 14px;
        }
      }
    }
  }

  .mainHeader .headerWrapper .middleSection .headerSearchBtn {
    background: url("../../Assets/Images/search-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0;

  }

  .mainHeader .headerWrapper .middleSection .searchText {
    font-size: 14px;
    margin-top: 0px;
    position: fixed;
    z-index: 99999;
    right: 40px;
    top: 12px;
    padding-left: 5px;
    padding-right: 10px;

    &:focus {
      box-shadow: none;
    }
  }

}

.broker-name {
  font-size: 0.85em;
  color: gray;
  margin-left: 10px;
}