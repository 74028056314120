@import "../../Assets/Styles/Variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body.externalScreens {
  background: #fff;
  padding: 0;
}

.externalScreensWrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 30px;
  min-height: calc(100vh - 70px);
}

.bg-preloader {
  position: fixed;
  content: "";
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5019607843);
  left: 0;
  top: 0;
  z-index: 9999999;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("../Images/AirplaneLoadingSymbol_Transparent.svg");
  background-repeat: no-repeat;
  width: 187px;
  z-index: 99999991;
}

.k-loader-canvas {
  display: none;
}

.notesGreyIcon {
  display: inline-block;
  background: url("../../Assets/Images/pin-blue.svg") no-repeat center center;
  width: 13px;
  height: 13px;

  &.disabled {
    background: url("../../Assets/Images/pin-grey.svg") no-repeat center center;
  }
}

.linkIcon {
  display: inline-block;
  background: #fff url("../../Assets/Images/link-icon.svg") no-repeat center
    center;
  width: 16px;
  height: 16px;
}

.copyIcon {
  display: inline-block;
  background: #fff url("../../Assets/Images/copy.svg") no-repeat center center;
  width: 12px;
  height: 14px;
}

.closeRedIcon {
  display: inline-block;
  background: url("../../Assets/Images/close-red.svg") no-repeat center center;
  width: 14px;
  height: 14px;
}

.checkGreyIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-grey.svg") no-repeat center center;
  width: 14px;
  height: 14px;
}

.checkBlueIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-blue.svg") no-repeat center center;
  width: 12px;
  height: 12px;
}

.checkGoldIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-gold.svg") no-repeat center center;
  width: 12px;
  height: 12px;
}

.checkGreenIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-green.svg") no-repeat center center;
  width: 14px;
  height: 14px;
}

.checkCircleGreyIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-circle-grey.svg") no-repeat center
    center;
  width: 14px;
  height: 14px;
}

.checkCircleGreenIcon {
  display: inline-block;
  background: url("../../Assets/Images/check-fill-green.svg") no-repeat center
    center;
  width: 14px;
  height: 14px;
}

.editBlueIcon {
  display: inline-block;
  background: url("../../Assets/Images/pencil-blue.svg") no-repeat center center;
  width: 14px;
  height: 14px;

  &.disabled {
    background: url("../../Assets/Images/pencil-grey.svg") no-repeat center
      center;
  }
}

.addBlueIcon {
  display: inline-block;
  background: url("../../Assets/Images/add.png") no-repeat center center;
  width: 14px;
  height: 14px;
  background-size: 14px;
}

.removeBlueIcon {
  display: inline-block;
  background: url("../../Assets/Images/delete-blue.svg") no-repeat center center;
  width: 14px;
  height: 14px;
  background-size: 14px;
}

.flightWhiteIcon {
  display: inline-block;
  background: url("../../Assets/Images/flight-white.svg") no-repeat center
    center;
  width: 23px;
  height: 23px;
}

.flightBlueIcon {
  display: inline-block;
  background: url("../../Assets/Images/flight-blue.svg") no-repeat center center;
  width: 23px;
  height: 23px;
}

.flightBlackIcon {
  display: inline-block;
  background: url("../../Assets/Images/jet-black-icon.svg") no-repeat center top;
  width: 23px;
  height: 20px;
}

.calendarIcon {
  display: inline-block;
  background: url("../../Assets/Images/calendar-blank.svg") no-repeat center top;
  width: 14px;
  height: 20px;
}

.remove-k-text-pointer .k-button-text {
  pointer-events: none;
}

.calendarIconRed {
  display: inline-block;
  background: url("../../Assets/Images/calendarRed.svg") no-repeat center top;
  width: 14px;
  height: 20px;
}

.calendarIconGreen {
  display: inline-block;
  background: url("../../Assets/Images/calendarGreen.svg") no-repeat center top;
  width: 14px;
  height: 20px;
}

.blueArrowLarge {
  display: inline-block;
  background: url("../../Assets/Images/lrg-blue-arrow.svg") no-repeat center
    center;
  background-size: 32px;
  width: 32px;
  height: 32px;
}

.addNotesBtn {
  background: #fff url("../../Assets/Images/pin-grey.svg") no-repeat center
    center;
  width: 26px;
  height: 26px;
  border: 1.2px solid #b4b6c3;
  border-radius: 8px;

  &.active {
    background: url("../../Assets/Images/pin-blue.svg") no-repeat center center;
  }
}

.cancelIcon {
  display: inline-block;
  background: url("../../Assets/Images/close-circle.svg") no-repeat center
    center;
  width: 18px;
  height: 18px;
}

.jetCardIcon {
  display: inline-block;
  background: url("../../Assets/Images/jet-blue-icon.svg") no-repeat center
    center;
  width: 10px;
  height: 10px;
}

.charterIcon {
  display: inline-block;
  background: url("../../Assets/Images/credit-card.svg") no-repeat center center;
  width: 10px;
  height: 10px;
}

// .uilFileIconBtn {
//     display: inline-block;
//     background: url('../../Assets/Images/credit-card.svg') no-repeat center center;
//     width: 20px;
//     height: 20px;
// }

// .associatedTripIcon {
//     display: inline-block;
//     background: url('../../Assets/Images/credit-card.svg') no-repeat center center;
//     width: 28px;
//     height: 24px;
// }

.unStyledBtn {
  background-color: transparent;
  border: none;
  padding: 0;

  .k-button-text {
    line-height: normal;
  }
}

.commonDivider {
  background: rgba(217, 217, 217, 0.65);
  padding: 0;
  height: 1px;
}

.flexRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.flexCenterGap {
  display: flex;
  align-items: center;
  gap: 16px;
}

.fullflex {
  flex: 1;
}

.spaceBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.selfCenter {
  align-self: center;
}

.selfEnd {
  align-self: flex-end;
}

.gap8 {
  gap: 8px;
}

.noFlexWrap {
  flex-wrap: nowrap;
}

.textWrap {
  text-wrap: nowrap;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center !important;
}

.inlineBlock {
  display: inline-block !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt10 {
  margin-top: 10px;
}

.mt16 {
    margin-top: 16px;
}

.mb-20 {
    margin-bottom: -20px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr4 {
  margin-right: 4px;
}

.ml4 {
  margin-left: 4px;
}

.ml8 {
  margin-left: 8px;
}

.relative {
  position: relative;
}

.width23 {
  width: 23%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.width27 {
  width: 27%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.width100 {
  width: 100% !important;
}

.custom-link {
  color: $primary-dark-blue;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  cursor: pointer;

  &:focus,
  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color: $link-hover;
  }
}

.commonTable {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    tr {
      position: sticky;
      top: 0;
      z-index: 9;
    }
  }
}

.commonCheckBox {
  .k-checkbox {
    vertical-align: sub;
  }
}

.commonChip {
  background: #e6e9f7;
  border-radius: 40px;
  padding: 5px 7px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;

  .text1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .text2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
}

.statusIndicator {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;

  &.Accepted {
    background-color: $success-color;

    & + .statusText {
      font-size: 14px;
      color: $success-color;
    }
  }

  &.AssignmentMade {
    background-color: $primary-light-blue;

    & + .statusText {
      font-size: 14px;
      color: $primary-light-blue;
    }
  }

  &.Finalized {
    background-color: $deep-blue;

    & + .statusText {
      font-size: 14px;
      color: $deep-blue;
    }
  }

  &.Cancelled {
    background-color: $error-color;

    & + .statusText {
      font-size: 14px;
      color: $error-color;
    }
  }

  &.Draft {
    background-color: #b4b6c3;

    & + .statusText {
      font-size: 14px;
      color: #b4b6c3;
    }
  }

  &.SchedulingPricingProposal {
    background-color: $warning-color;

    & + .statusText {
      font-size: 14px;
      color: $warning-color;
    }
  }

  &.Scheduling {
    background-color: #ffa722;

    & + .statusText {
      font-size: 14px;
      color: #ffa722;
    }
  }

  &.QuoteReview {
    background-color: #fde50e;

    & + .statusText {
      font-size: 14px;
      color: #fde50e;
    }
  }

  &.ClientReview {
    background-color: $info-color;

    & + .statusText {
      font-size: 14px;
      color: $info-color;
    }
  }

  &.PartiallyAssigned {
    background-color: #ffffff;
    border: 1px solid $primary-light-blue;

    & + .statusText {
      font-size: 14px;
      color: $primary-light-blue;
    }
  }

  &.OngoingTrip {
    background-color: #9bcbeb;

    & + .statusText {
      font-size: 14px;
      color: #9bcbeb;
    }
  }

  &.FinanceReview {
    background-color: #949dd7;

    & + .statusText {
      font-size: 14px;
      color: #949dd7;
    }
  }

  &.TripReview {
    background-color: #fccf8c;

    & + .statusText {
      font-size: 14px;
      color: #fccf8c;
    }
  }
}

.commonBox {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e6e9f7;
  border-radius: 6px;

  .sectionheadlineLight {
    font-size: 12px;
    line-height: 11px;
    font-weight: 400;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #00041b;
    margin-bottom: 20px;
  }

  .sectionheadline {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #00041b;
  }
}

// COMMON ACCORDIAN CSS
.panelbar-wrapper {
  .k-panelbar {
    border: none;
    background: transparent;

    .k-panelbar-header {
      margin-top: 20px;
      background: rgba(216, 219, 234, 0.15);
      border-radius: 4px;
      border: none;
      overflow: hidden;

      .k-link {
        padding: 0;
        color: $dark-grey;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        margin-bottom: 0;
        padding: 10px 15px;
        background: rgba(238, 240, 248, 0.85);
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        color: #00041b;
        padding: 26px 24px;
        cursor: pointer;

        .k-icon {
          color: $deep-blue;
        }

        &:focus,
        &.k-focus {
          box-shadow: none;
        }

        &.k-selected:hover {
          background: rgba(238, 240, 248, 0.85);
        }

        .k-panelbar-item-text {
          width: 100%;
          font-weight: 500;
          font-size: 18px;
          color: #00041b;
          margin-left: 10px;

          .tripProposalTitle {
            span {
              .k-checkbox {
                margin-top: -2px;
              }

              .k-checkbox-label {
                font-weight: 500;
                font-size: 18px;
                color: $dark-grey;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .k-animation-container {
        background: rgba(216, 219, 234, 0.15);
        border-radius: 4px;
      }
    }

    .k-panelbar-content {
      margin-bottom: -20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .tripDetailsCard {
      background: #ffffff;
      border: 2px solid rgba(238, 240, 248, 0.85);
      border-radius: 8px;
      padding: 24px;

      @media screen and (max-width: 767px) {
        padding: 12px;
      }

      .detailsTitle {
        background: rgba(238, 240, 248, 0.85);
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        color: $dark-grey;
        margin: -24px -24px 20px -24px;
        padding: 24px;
      }
    }

    .tripCardGrey {
      background: rgba(216, 219, 234, 0.15);
    }

    .tripShortInfoCards {
      border: 1px solid #e6e9f7;
      border-radius: 6px;
      background: #ffffff;
      padding: 26px;
      display: flex;
      gap: 30px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 12px;
      }

      .jetImgLeft {
        width: 280px;
        border: 1px dashed #cdd2e1;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .jetInfoRightSec {
        display: flex;
        width: calc(100% - 280px);
        justify-content: space-between;
        gap: 30px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          width: 100%;
        }

        .externalDisplay {
          flex: 1;
          margin-top: 14px;

          .form-group {
            display: flex;
            height: 96%;

            .k-floating-label-container {
              width: 100%;
            }

            .k-textarea {
              height: 100%;

              textarea {
                resize: none;
              }
            }
          }
        }

        ul {
          flex: 1;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            padding: 15px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            label {
              font-weight: 400;
              font-size: 16px;
              line-height: 15px;
              color: $dark-grey;

              &:last-child {
                font-weight: 500;
              }
            }
          }

          .allInCash {
            label {
              color: $primary-dark-blue;
              font-weight: 500;
              font-size: 20px;
              line-height: 18px;
            }
          }

          .divider {
            background: rgba(217, 217, 217, 0.65);
            padding: 0;
            height: 1px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .journeyDate {
        display: flex;
        flex-direction: column;

        .jDate {
          background-image: url("../../Assets/Images/calendar-blank.svg");
        }

        .jLegacy {
          background-image: url("../../Assets/Images/jet-black-icon.svg");
        }

        label {
          color: $dark-grey;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          background-size: 16px;
          background-position: center left;
          background-repeat: no-repeat;
          padding: 4px 0 0 28px;
          margin: 5px 0;
        }
      }

      .location {
        display: flex;
        align-items: center;

        h3 {
          font-weight: 100;
          font-size: 64px;
          line-height: 50px;
          color: $deep-blue;
          padding-right: 15px;
          margin: 0;
          padding-top: 9px;
        }

        div {
          display: flex;
          flex-direction: column;
        }

        label {
          font-weight: 300;
          font-size: 20px;
          line-height: 18px;
          color: $dark-grey;
          padding: 5px 0;
        }
      }

      .journeyTime {
        background-size: 32px;
        color: $deep-blue;
        background-position: top center;
        background-repeat: no-repeat;
        background-image: url("../../Assets/Images/lrg-blue-arrow.svg");
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        padding-top: 25px;
        text-align: center;
      }
    }
  }
}

.documentText {
  background-color: #fff;
  border: 1px solid #e6e9f7;
  border-radius: 6px;
  padding: 14px 20px;
}

.tripShortInfoCardsMultiple {
  display: flex;
  gap: 20px;
  background-color: #fff;
  border: 1px solid #e6e9f7;
  border-radius: 6px;
  padding: 14px 20px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .jetImgLeft {
    display: flex;

    // justify-content: center;
    // align-items: center;
    img {
      width: 160px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .rightPanelInfo {
    width: 100%;

    .panelHeader {
      display: flex;
      justify-content: space-between;
      padding-bottom: 13px;
      margin-bottom: 18px;
      border-bottom: 1px solid #e6e9f7;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
      }

      .jetDetail {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #00041b;
      }

      .journeyDate {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #00041b;
        text-align: right;
      }
    }

    .transitDetail {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 24px;
      }

      .airportDetailBox {
        background-color: rgba(216, 219, 234, 0.15);
        border-radius: 4px;
        text-align: center;
        padding: 16px;
        flex: 1;

        .airportName {
          font-weight: 300;
          font-size: 20px;
          line-height: 18px;
          text-align: center;
          color: #000c52;
        }

        .departTime {
          font-weight: 300;
          font-size: 15px;
          line-height: 14px;
          color: #00041b;
          margin-top: 10px;

          strong {
            font-weight: 400;
          }
        }
      }

      .journeyTime {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $deep-blue;
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        padding: 0 26px;
      }
    }

    .flightHoursCost {
      .textAlignRight {
        @media screen and (max-width: 767px) {
          text-align: left;
          margin-top: 8px;
        }
      }
    }
  }
}

.planeAccordian {
  border: none;

  .k-panelbar-header {
    margin-top: 0 !important;
    background-color: transparent !important;

    .k-link {
      padding: 0 !important;
      background-color: #fff !important;
      margin-bottom: 20px;
      cursor: pointer;

      &:focus,
      &.k-focus {
        box-shadow: none;
      }

      &.k-selected .k-icon {
        color: #212529;
      }

      h5,
      h6 {
        margin-bottom: 0;
      }

      .flexCenter {
        justify-content: space-between;
      }
    }

    .k-animation-container {
      margin-top: 20px;
    }
  }
}

.ofSelected {
  padding: 8px 10px;
  background: #e6e9f7;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #000c52;
}

.exTemplateFooter {
  background: #f4f7f8;
  padding: 20px 0;

  .footerContainer {
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    img {
      max-width: 146px;
    }

    p {
      color: $dark-grey;
      font-weight: 300;
      font-size: 16px;
      line-height: 15px;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
}

.feedbackForm {
  max-width: 680px;
  margin: 0 auto;

  h2 {
    font-weight: 400;
    font-size: 34px;
    line-height: 31px;
    color: #00041b;
    margin-bottom: 40px;
  }

  h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #00041b;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #00041b;
  }

  label {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #00041b;
    margin-bottom: 8px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .pointScaleWrap {
    position: relative;
    max-width: 500px;
    margin: 12px auto 16px auto;

    .scalePoint {
      display: flex;
      justify-content: space-between;
      padding: 4px 57px;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      color: #00041b;
    }

    .scale {
      display: flex;
      justify-content: space-between;
      padding: 7px 24px;
      font-size: 12px;
      line-height: 11px;
      text-align: center;
      color: #00041b;
    }

    .feedback-radio-group {
      display: flex;
      justify-content: center;
      gap: 26px;
    }
  }

  .inlineFeebackRadio {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-block: 12px;

    label {
      display: block;
      width: 50%;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #00041b;

      &.end {
        align-self: flex-end;
      }
    }

    .inlinePointScaleWrap {
      margin-left: 12px;

      .scale {
        display: flex;
        justify-content: space-between;
        padding: 4px 2px;
        font-size: 12px;
        line-height: 11px;
        text-align: center;
        color: #00041b;
      }

      .feedback-radio-group {
        display: flex;
        justify-content: center;
        gap: 26px;
      }
    }
  }

  .k-textarea {
    max-width: 450px;
    height: 90px;
  }
}

.wrapItems {
  flex-wrap: wrap;
}

.customPills {
  .k-chip-solid-base {
    border: none;
    color: #000c52;
    background-color: #e6e9f7;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    border-radius: 100px;
    letter-spacing: 0;
    padding: 7px 9px;

    .k-chip-content {
      padding: 0;
      margin-left: 0;

      svg {
        margin: 0 2px;
      }

      .editBlueIcon {
        width: 8px;
        height: 8px;
        margin-left: 2px;
      }
    }
  }
}

.linkwithTextHeading {
  font-size: 16px;

  a {
    color: #001489;
    font-size: 16px;
    font-weight: 500;
  }
}

.airportCodeLarge {
  display: flex;
  align-items: center;
  gap: 24px;

  .code {
    font-size: 36px;
    line-height: 38px;
    color: #00106e;
    font-weight: 300;
  }

  .blueArrowLarge {
    width: 26px;
    height: 26px;
    background-size: 26px;
  }
}

.leadBtnUpdated {
  span.tripLeg {
    border-radius: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    padding: 7px 12px 6px 12px;
    position: relative;

    &::before {
      width: 6px;
      height: 6px;
      content: "";
      display: inline-block;
      margin-right: 6px;
      border-radius: 100%;
    }
  }

  // Trip Leg Status
  .draft {
    background: #edeef2;
    color: #00041b;

    &::before {
      background-color: #b4b6c3;
    }
  }

  .inQueue {
    background: #edefff;
    color: #00041b;

    &::before {
      background-color: #949dd7;
    }
  }

  .schedulerAssigned {
    background: #fff1dc;
    color: #00041b;

    &::before {
      background-color: #ffa722;
    }
  }

  .quoteReview {
    background: #fcf8d1;
    color: #00041b;

    &::before {
      background-color: #f5de0d;
    }
  }

  .clientReview {
    background: #1e85ff2b;
    color: #00041b;

    &::before {
      background-color: #0656ce;
    }
  }

  .accepted {
    background: #e7fced;
    color: #00041b;

    &::before {
      background-color: #1c9240;
    }
  }

  .operatorAssigned {
    background: #0066ff1f;
    color: #00041b;

    &::before {
      background-color: #001489;
    }
  }

  .inTransit {
    background: #ebf7ff;
    color: #00041b;

    &::before {
      background-color: #9bcbeb;
    }
  }

  .completed {
    background: #02106126;
    color: #00041b;

    &::before {
      background-color: #000c52;
    }
  }

  .cancelled {
    background: #fdebeb;
    color: #00041b;

    &::before {
      background-color: #d14141;
    }
  }

  .upcoming {
    background: #e6e9f7;
    color: $primary-dark-blue;
  }

  .dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: #7aa5c1;
    border-radius: 50%;
  }
}

.defaultLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: $label-color;
}

.defaultValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: $text-dark-black-color;
  margin: 5px 0 20px 0;
  word-wrap: break-word;

  small {
    font-size: 10px;
    line-height: 9px;
    color: rgba(111, 116, 142, 0.8);
    padding-left: 2px;
  }

  a {
    font-weight: 600;
    color: $primary-light-blue;
  }
}

.commonTabs {
  .k-tabstrip > .k-content {
    border: none;
    margin-top: 16px !important;
    background-color: transparent !important;
    padding: 0;

    .k-animation-container {
      width: 100%;
    }
  }

  .k-tabstrip-items-wrapper {
    border-bottom: 0.5px solid #e6e9f6;
    // background: #F8F8F8;
    box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
    width: 70%;

    .k-tabstrip-items {
      gap: 24px;
    }
  }

  .k-tabstrip-items-wrapper .k-item {
    color: #82869b;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 44px;
    background-color: transparent;
  }

  .k-tabstrip-items-wrapper .k-item:active,
  .k-tabstrip-items-wrapper .k-item.k-active,
  .k-tabstrip-items-wrapper .k-item.k-selected {
    border-color: transparent;
    border-bottom-width: 2px;
    border-bottom-color: #001489;
    color: #001489;
    font-weight: 700;
  }
}

.wFullHackstack {
  .k-tabstrip-items-wrapper {
    width: 100%;
  }
}

.noDataAddedWrap {
  text-align: center;

  img {
    margin: 80px 0 35px 0;
    max-height: 78px;
    max-width: 73px;
  }

  h2 {
    font-weight: 300;
    margin-bottom: 20px;
  }

  .infoMessage {
    font-size: 18px;
    line-height: 17px;
    font-weight: 400;
    text-align: center;
    color: #00041b;
    margin-bottom: 30px;
  }

  .btn-link {
    justify-content: center;
    margin-top: 21px;
  }
}

.static {
  position: static !important;
}

.break-word {
  word-break: break-word;
}

.combinedWeather {
  display: flex;
  align-items: flex-start;
  border-radius: 3px;

  .weatherOrgin {
    flex: 1;
    width: calc(100% - 90px);
    .customInput {
      border-right: none;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .weatherNow {
    width: 90px;
    border: 1px solid #e4e7eb;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
    margin-top: calc(1.5 * 1rem);
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 50px;
      width: 100%;
      background: rgba(238, 240, 248, 0.85);

      .weather img {
        width: 20px;
        height: 20px;
      }

      .temp {
        color: #000837;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        padding-top: 4px;
      }

      .arrowRight img {
        width: 12px;
        height: 14px;
      }
    }
  }
}

.optionalNumberField {
  position: relative;
  &::after {
    display: inline-block;
    content: "Optional";
    font-family: League Spartan, sans-serif;
    font-size: 10px;
    color: rgba(111, 116, 142, 0.8);
    font-weight: 400;
    line-height: 9px;
    position: absolute;
    right: 24px;
    bottom: 20px;
    transition: bottom 0.15s ease-in-out;
  }
  &.animated {
    &::after {
      transition: bottom 0.15s ease-in-out;
      bottom: 32px;
    }
  }
}

.linkIconWrap {
  display: flex;
  gap: 8px;
  align-items: baseline;
  > div {
    max-width: calc(100% - 24px);
  }
  @media screen and (min-width: 1100px) {
    // right: -32px;
  }
}

.proposal-note {
  font-size: larger;
  font-weight: 300;
  line-height: 1.2;
}

